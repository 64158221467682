import { Loader } from './loader.routes';
import { lazy } from 'react';
import SidebarLayout from 'src/layouts/SidebarLayout';
import ProtectedRoute from './Components/ProtectedRoute';
import { DashboardRoute } from './Components/DashboardRoute';
import { VisitDashboardProvider } from 'src/contexts/VisitDashboardContext';
import { FaultsDashboardsProvider } from '../../contexts/FaultsDashboardContext';

const ClientDashboard = Loader(lazy(() => import('src/content/pages/Dashboards/ClientDashboard')));
const FaultsDashboard = Loader(lazy(() => import('src/content/pages/Dashboards/FaultsDashboard')));
//const ProCleanDashboard = Loader(lazy(() => import('src/content/pages/Dashboards/ProCleanDashboard')));
//const ProCleanPlusDashboard = Loader(lazy(() => import('src/content/pages/Dashboards/ProCleanPLUSDashboard')));

/* const SOS1Dashboard = Loader(lazy(() => import('src/content/pages/Dashboards/SOS1Dashboard')));
const SOS2Dashboard = Loader(lazy(() => import('src/content/pages/Dashboards/SOS2Dashboard')));
const SOS3Dashboard = Loader(lazy(() => import('src/content/pages/Dashboards/SOS3Dashboard')));

const WinterisationDashboard = Loader(lazy(() => import('src/content/pages/Dashboards/WinterisationDashboard')));
const DewinterisationDashboard = Loader(lazy(() => import('src/content/pages/Dashboards/DewinterisationDashboard')));

const ChillPourDashboard = Loader(lazy(() => import('src/content/pages/Dashboards/ChillPourDashboard')));
const ProSpecDashboard = Loader(lazy(() => import('src/content/pages/Dashboards/ProSpecDashboard')));
 */
export const createDashboardRoutes = (groupRequired?: null | string, roleRequired?: null | string[]) => ({
  path: 'dashboard',
  element: <SidebarLayout />,
  children: [
    {
      path: 'proclean-plus/:visitId',
      element: (
        <ProtectedRoute groupRequired={['avani', 'clients']} roleRequired={['*']}>
          <VisitDashboardProvider>
            <DashboardRoute />
          </VisitDashboardProvider>
        </ProtectedRoute>
      )
    },
    {
      path: 'proclean/:visitId',
      element: (
        <ProtectedRoute groupRequired={['avani', 'clients']} roleRequired={['*']}>
          <VisitDashboardProvider>
            <DashboardRoute />
          </VisitDashboardProvider>
        </ProtectedRoute>
      )
    },
    {
      path: 'client',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <ClientDashboard />
        </ProtectedRoute>
      )
    },
    {
      path: 'faults/:visitFaultId',
      element: (
        <ProtectedRoute groupRequired={['avani', 'clients']} roleRequired={['*']}>
          <FaultsDashboardsProvider>
            <FaultsDashboard />
          </FaultsDashboardsProvider>
        </ProtectedRoute>
      )
    }
    /* {
      path: 'pro-clean',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <ProCleanPlusDashboard />
        </ProtectedRoute>
      )
    },
    {
      path: 'pro-clean-plus',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <ProCleanPlusDashboard />
        </ProtectedRoute>
      )
    },
    {
      path: 'sos1',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <SOS1Dashboard />
        </ProtectedRoute>
      )
    },
    {
      path: 'sos2',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <SOS2Dashboard />
        </ProtectedRoute>
      )
    },
    {
      path: 'sos3',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <SOS3Dashboard />
        </ProtectedRoute>
      )
    },
    {
      path: 'winterisation',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <WinterisationDashboard />
        </ProtectedRoute>
      )
    },
    {
      path: 'dewinterisation',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <DewinterisationDashboard />
        </ProtectedRoute>
      )
    },
    {
      path: 'chillpour',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <ChillPourDashboard />
        </ProtectedRoute>
      )
    },
    {
      path: 'pro-spec',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <ProSpecDashboard />
        </ProtectedRoute>
      )
    }, */
  ]
});
