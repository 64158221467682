import * as React from 'react';

import {
  alpha,
  Box,
  Card,
  darken,
  Dialog,
  Divider,
  FormGroup,
  Grid,
  Paper,
  styled,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { Person, PhotoLibrary, Report, Warning, WavingHand } from '@mui/icons-material';

import { useAuthContext } from 'src/contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { updateVisitComments } from 'src/models/api-calls/visits.api';
import { UpdateVisitCommentsParams } from 'src/models/types/visits.types';
import { CommentType } from 'src/models/types/comments.types';
import { AvaniButton } from 'src/components/Buttons/AvaniButton';

import { AvaniDropdown } from 'src/components/Dropdowns/AvaniDropdown/AvaniDropdown';
import { OpenAvaniShop } from 'src/components/Functions/openAvaniShop';

import { FaultData } from 'src/models/types/DashboardBlock/faults.types';

const AvaniStyledCard = styled(Card)(
  ({ theme }) => `
  border-radius: 16px;
  padding: 16px;

  .MuiTypography-root.MuiTypography-h3 {
    margin: 0;
    line-height: 1;
    padding-bottom: 12px;
  }
`
);

const FormGroupShared = styled(FormGroup)(
  ({ theme }) => `
  margin: 24px 12px;
`
);

const OverflowingTextContent = styled(Box)(
  ({ theme }) => `
  &.long {
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 60px;
    position: relative;
  }

  &.long:after {
    content: '...  read more';
    top: 40px;
    right: 0;
    padding-left: 6px;
    position: absolute;
    font-size: 14px;
    line-height: 1.43;
    font-weight: normal;
    color: ${theme.colors.avani.turc}
  }
`
);

const FaultButton = styled(Paper)(
  ({ theme }) => `
  padding: 12px;
  border-radius: 16px;
  background-color: ${theme.colors.avani.turc};
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 0;
  cursor: pointer;
`
);

type ActionDialogContentsProps = {
  faultId: string;
  resolvedStatus: boolean;
  onCancel: () => void;
};

const ActionDialogContents: React.FC<ActionDialogContentsProps> = ({ resolvedStatus, faultId, onCancel }) => {
  const theme = useTheme();
  const actionName = resolvedStatus ? 'Add comment' : 'Mark Resolved';
  const [comment, setComment] = React.useState<string>('');
  const [message, setMessage] = React.useState<string | null>(null);
  const { visitId } = useParams<{ visitId: string }>();
  const { auth } = useAuthContext();

  const addFaultComment = () => {
    if (!visitId || !comment.length || !auth) return;

    const commentParams: UpdateVisitCommentsParams = {
      visitId,
      comments: [
        {
          authorId: auth.id,
          faultId: faultId,
          content: comment,
          type: CommentType.Faults,
          id: visitId
        }
      ]
    };

    updateVisitComments(commentParams)
      .then(() => {
        setMessage('Comment added!');
      })
      .catch((e) => {
        console.error(e);
        setMessage(e);
      });
  };

  return (
    <>
      {!!message && (
        <Box>
          <Typography variant={'body1'}>{message}</Typography>
          <AvaniButton loading={false} label={'Close'} onClick={() => onCancel()} />
        </Box>
      )}
      {!message && (
        <Box
          sx={{
            padding: 2
          }}
        >
          <Typography variant="h5" fontSize={32} paddingLeft={'12px'}>
            {actionName}
          </Typography>
          <FormGroupShared>
            <TextField
              id="comment"
              multiline
              minRows={3}
              label="Add Comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </FormGroupShared>
          {/* {!resolvedStatus &&
        <>
          <FormGroupShared>
            <AvaniDatePicker/>
          </FormGroupShared>
          <FormGroupShared>
            <TextField
              id="note"
              label="Note"
              multiline
              minRows={4}
            />
          </FormGroupShared>
        </>
      } */}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '0 12px'
            }}
          >
            <AvaniButton
              label={actionName}
              loading={false}
              disabled={!resolvedStatus}
              sx={{
                border: `2px solid ${theme.colors.avani.magenta}`,
                [`:hover`]: {
                  borderWidth: '2px'
                }
              }}
              onClick={() => addFaultComment()}
            />
            <AvaniButton
              label={'Cancel'}
              loading={false}
              onClick={() => onCancel()}
              sx={{
                backgroundColor: 'transparent',
                color: theme.colors.avani.magenta,
                border: '2px solid ' + theme.colors.avani.magenta,
                margin: 0,
                [`:hover`]: {
                  borderWidth: '2px',
                  backgroundColor: theme.colors.avani.magenta,
                  color: 'white'
                }
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export const AvaniFaultCard: React.FC<FaultData> = ({
  id,
  faultName,
  isCritical,
  isResolved,
  faultType,
  technicianName,
  description,
  unresolvedDays,
  actionRecommendation,
  visitStartedAtDates
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [descriptionOpen, setDescriptionOpen] = React.useState(false);
  const [recommendationOpen, setRecommendationOpen] = React.useState(false);
  const [actionOpen, setActionOpen] = React.useState(false);

  const [longDescContent, setLongDescContent] = React.useState(false);
  const [longRecommContent, setLongRecommContent] = React.useState(false);

  const descriptionEl = React.useRef<HTMLElement>(null);
  const descriptionFrameEl = React.useRef<HTMLElement>(null);
  const recommendationEl = React.useRef<HTMLElement>(null);
  const recommendationFrameEl = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (descriptionEl.current && descriptionEl.current.clientHeight > 60) {
      setLongDescContent(true);
      descriptionFrameEl.current?.classList.add('long');
    }

    if (recommendationEl.current && recommendationEl.current.clientHeight > 60) {
      setLongRecommContent(true);
      recommendationFrameEl.current?.classList.add('long');
    }
  }, [descriptionEl, descriptionFrameEl, recommendationEl, recommendationFrameEl]);

  const onDescHover = () => {
    if (longDescContent) {
      setDescriptionOpen(true);
    }
  };

  const onRecommHover = () => {
    if (longRecommContent) {
      setRecommendationOpen(true);
    }
  };

  const goToFaults = (faultId: string) => {
    //navigate to faults dashboard for the given fault
  };

  return (
    <AvaniStyledCard
      raised={true}
      sx={{
        height: '100%',
        backgroundColor: !isResolved ? theme.colors.avani.lightMagenta : theme.colors.avani.lightTurc
      }}
    >
      <Grid container columns={6} spacing={0} alignItems={'center'}>
        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            mb: 2
          }}
        >
          <Typography
            noWrap
            variant={'h5'}
            width={'100%'}
            component={'h5'}
            fontSize={24}
            fontWeight={'bold'}
            sx={{
              color: theme.colors.avani.teal,
              flexGrow: 1,
              cursor: 'pointer',

              [`:hover`]: {
                opacity: 0.6
              }
            }}
            title={faultName}
            onClick={() => goToFaults('TESTFAULTID')}
          >
            {faultName}
          </Typography>
          <div
            style={{
              paddingLeft: 1
            }}
            title={!!isCritical ? 'Critical' : 'Regular'}
          >
            {!isCritical ? (
              <svg width="25" height="32" viewBox="0 0 25 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.5 0C6.11875 0 5 1.11875 5 2.5V15V15.4375V20C5 20.55 4.55 21 4 21C3.45 21 3 20.55 3 20V16.5187C2.875 16.6062 2.75625 16.7062 2.6375 16.8L1.4375 17.8C0.525 18.5625 0 19.6875 0 20.875V23.25C0 25.625 1.05625 27.875 2.88125 29.3937L3.21875 29.675C5.01875 31.175 7.28125 31.9937 9.61875 31.9937H17C21.4188 31.9937 25 28.4125 25 23.9937V20V18C25 16.3438 23.6562 15 22 15C21.225 15 20.525 15.2937 19.9937 15.7688C19.875 14.2188 18.5812 13 17 13C16.225 13 15.525 13.2937 14.9937 13.7688C14.875 12.2188 13.5813 11 12 11C11.2312 11 10.5312 11.2875 10 11.7625V2.5C10 1.11875 8.88125 0 7.5 0Z"
                  fill="#7FAAB2"
                />
              </svg>
            ) : (
              <svg width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.05 2C7.28125 0.85625 8.2875 0 9.5 0C10.7125 0 11.7188 0.85625 11.95 2H7.05ZM7 15V10H12V11.7625C12.5312 11.2875 13.2312 11 14 11C15.5813 11 16.875 12.2188 16.9937 13.7688C17.525 13.2875 18.2313 13 19 13C20.5812 13 21.875 14.2188 21.9937 15.7688C22.525 15.2875 23.2313 15 24 15C25.6562 15 27 16.3438 27 18V20V24C27 28.4188 23.4188 32 19 32H11.6187C9.28125 32 7.0125 31.1812 5.21875 29.6812L4.88125 29.4C3.05625 27.8813 2 25.625 2 23.25V20.875C2 19.6875 2.525 18.5625 3.4375 17.8L4.6375 16.8C4.75625 16.7 4.875 16.6062 5 16.5187V20C5 20.55 5.45 21 6 21C6.55 21 7 20.55 7 20V15.4375V15Z"
                  fill="#F27FBE"
                />
                <path
                  d="M14 7V3C14 2.45 13.55 2 13 2H2.7375C2.33125 2 2 2.33125 2 2.7375C2 2.98125 2.11875 3.20625 2.31875 3.34375L4.725 5L2.31875 6.65625C2.11875 6.79375 2 7.01875 2 7.2625C2 7.66875 2.33125 8 2.7375 8H13C13.55 8 14 7.55 14 7Z"
                  fill="#E6007E"
                />
              </svg>
            )}
          </div>
        </Grid>
        <Grid item xs={2.3}>
          <AvaniDropdown dates dateStrings={visitStartedAtDates} />
        </Grid>
        <Grid
          item
          xs={1.7}
          sx={{
            padding: '0 8px 8px 8px'
          }}
        >
          <div
            style={{
              height: '24px',
              textAlign: 'center'
            }}
          >
            {faultType === 'Operations Fault' ? (
              <Warning
                sx={{
                  color: theme.colors.avani.turc
                }}
              />
            ) : (
              <Report
                sx={{
                  color: theme.colors.avani.turc
                }}
              />
            )}
          </div>
          <Typography
            fontSize={16}
            lineHeight={1}
            variant={'body1'}
            sx={{
              color: theme.colors.avani.turc,
              textAlign: 'center'
            }}
          >
            {faultType}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Paper
            elevation={0}
            sx={{
              height: '100%',
              padding: '12px',
              borderRadius: '16px',
              backgroundColor: !!isResolved ? '#7FD3D3' : '#F27FBE'
            }}
          >
            <Typography
              variant={'body1'}
              lineHeight={1}
              sx={{
                textAlign: 'center',
                mt: !unresolvedDays ? '12px' : '5px',
                mb: !unresolvedDays ? '12px' : 0
              }}
            >
              {!!isResolved ? 'Resolved' : 'Unresolved'}
            </Typography>
            {!!unresolvedDays && (
              <Typography
                variant={'body1'}
                sx={{
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                {unresolvedDays}
              </Typography>
            )}
          </Paper>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            py: 2
          }}
        >
          <Divider
            sx={{
              borderBottom: '1px dashed ' + theme.colors.avani.turc,
              backgroundColor: 'transparent'
            }}
          />
        </Grid>
        {!!description && (
          <Grid item xs={6}>
            <Typography
              fontWeight={'bold'}
              fontSize={18}
              sx={{
                color: theme.colors.avani.teal
              }}
            >
              Description
            </Typography>
            <OverflowingTextContent
              ref={descriptionFrameEl}
              onTouchEnd={onDescHover}
              onMouseOver={onDescHover}
              sx={{
                [`:after`]: {
                  fontFamily: theme.typography.body2,
                  backgroundColor: !isResolved ? theme.colors.avani.lightMagenta : theme.colors.avani.lightTurc
                }
              }}
            >
              <Typography
                ref={descriptionEl}
                fontSize={14}
                variant={'body2'}
                minHeight={'60px'}
                dangerouslySetInnerHTML={{ __html: description }}
              ></Typography>
              <Dialog
                open={descriptionOpen}
                keepMounted
                slotProps={{
                  backdrop: {
                    style: {
                      backgroundColor: alpha(darken('#000C57', 0.4), 0.2),
                      backdropFilter: 'blur(2px)'
                    }
                  }
                }}
                onClose={() => setDescriptionOpen(false)}
              >
                <Typography
                  fontSize={18}
                  variant={'body2'}
                  sx={{
                    padding: 2
                  }}
                  dangerouslySetInnerHTML={{ __html: description }}
                ></Typography>
              </Dialog>
            </OverflowingTextContent>
          </Grid>
        )}
        {!!actionRecommendation && (
          <Grid item xs={6}>
            <Typography
              fontWeight={'bold'}
              fontSize={18}
              sx={{
                color: theme.colors.avani.teal,
                mt: 2
              }}
            >
              Recommendation
            </Typography>
            <OverflowingTextContent
              ref={recommendationFrameEl}
              onTouchEnd={onRecommHover}
              onMouseOver={onRecommHover}
              sx={{
                [`:after`]: {
                  fontFamily: theme.typography.body2,
                  backgroundColor: !isResolved ? theme.colors.avani.lightMagenta : theme.colors.avani.lightTurc
                }
              }}
            >
              <Typography
                ref={recommendationEl}
                fontSize={14}
                variant={'body2'}
                sx={{
                  minHeight: '60px'
                }}
                dangerouslySetInnerHTML={{ __html: actionRecommendation }}
              ></Typography>
              <Dialog
                open={recommendationOpen}
                keepMounted
                slotProps={{
                  backdrop: {
                    style: {
                      backgroundColor: alpha(darken('#000C57', 0.4), 0.2),
                      backdropFilter: 'blur(2px)'
                    }
                  }
                }}
                onClose={() => setRecommendationOpen(false)}
              >
                <Typography
                  fontSize={18}
                  variant={'body2'}
                  sx={{
                    padding: 2
                  }}
                  dangerouslySetInnerHTML={{ __html: actionRecommendation }}
                ></Typography>
              </Dialog>
            </OverflowingTextContent>
          </Grid>
        )}

        <Grid
          item
          xs={6}
          sx={{
            py: 2
          }}
        >
          <Divider
            sx={{
              borderBottom: '1px dashed ' + theme.colors.avani.turc,
              backgroundColor: 'transparent'
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            fontWeight={'bold'}
            fontSize={18}
            sx={{
              color: theme.colors.avani.teal
            }}
          >
            Technician
          </Typography>
          <Typography fontWeight={'bold'} fontSize={16}>
            <Person
              sx={{
                display: 'inline',
                float: 'left',
                color: theme.colors.avani.turc
              }}
            />
            {technicianName}
          </Typography>
        </Grid>
        {/* <Grid item xs={3}>
          <Typography
            fontWeight={'bold'}
            fontSize={18}
            sx={{
              color: theme.colors.avani.teal,
            }}
          >
            Reported to:
          </Typography>
          <Typography
            fontWeight={'bold'}
            fontSize={16}
          >
            <Person sx={{
              display: 'inline',
              float: 'left',
              color: theme.colors.avani.turc,
            }} />
            {asignee}
          </Typography>
        </Grid> */}
        <Grid
          item
          xs={6}
          sx={{
            py: 2
          }}
        >
          <Divider
            sx={{
              borderBottom: '1px dashed ' + theme.colors.avani.turc,
              backgroundColor: 'transparent'
            }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            p: 1,
            paddingLeft: 0
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '12px'
            }}
          >
            <FaultButton elevation={0} onClick={() => navigate(`/dashboard/faults/${id}`)}>
              <Typography
                variant={'body1'}
                lineHeight={1}
                sx={{
                  textAlign: 'center',
                  mt: '5px'
                }}
              >
                <PhotoLibrary
                  sx={{
                    color: 'white'
                  }}
                />
              </Typography>
              <Typography
                variant={'body1'}
                sx={{
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                View
              </Typography>
            </FaultButton>
            <FaultButton elevation={0} onClick={() => OpenAvaniShop()}>
              <Box sx={{ textAlign: 'center', paddingTop: 1 }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3.5 2C2.67157 2 2 2.67157 2 3.5C2 4.32843 2.67157 5 3.5 5C4.32843 5 5 4.32843 5 3.5C5 2.67157 4.32843 2 3.5 2ZM20.3125 2.3125L16.9688 9C15.8656 8.50508 14.43 8.73407 13.5312 10.0312L13.4062 9.96875C12.6276 11.6489 10.7462 12.8687 8.875 13.5938C7.93939 13.9563 7.00358 14.201 6.28125 14.3438C5.55892 14.4865 4.89169 14.488 5 14.5L3.90625 14.375V15.5V15.5938V15.9688L4.15625 16.25C8.98374 21.8284 16.375 22 16.375 22H16.7812L17.0938 21.7188C17.0938 21.7188 20.1903 18.7591 20.875 14.1875H20.9062C21.4062 11.9875 20.7938 10.6875 19.5938 10.1875C19.5766 10.1704 19.4815 10.1202 19.4375 10.0938L22 3.09375L20.3125 2.3125ZM8 3C7.44772 3 7 3.44772 7 4C7 4.55228 7.44772 5 8 5C8.55228 5 9 4.55228 9 4C9 3.44772 8.55228 3 8 3ZM6 7C4.89543 7 4 7.89543 4 9C4 10.1046 4.89543 11 6 11C7.10457 11 8 10.1046 8 9C8 7.89543 7.10457 7 6 7ZM14.8125 11.5625L18.9375 13.3438C18.6037 17.0513 16.4011 19.4516 15.9688 19.9062C15.7499 19.8936 15.0719 19.8306 14.1562 19.625C14.9021 18.7878 16.0812 17.2086 16.6875 15.1875C14.8915 17.2709 12.9875 18.4548 12.0312 18.9688C11.4469 18.7551 10.8555 18.5097 10.2188 18.1875C10.8532 17.6437 11.8262 16.7188 12.4062 15.6875C11.6618 16.4996 10.0911 17.2128 9.125 17.5938C8.46445 17.1943 7.80611 16.7347 7.15625 16.1875C7.89966 16.0209 8.69995 15.8151 9.59375 15.4688C11.4939 14.7324 13.5867 13.5334 14.8125 11.5625Z"
                    fill="white"
                  />
                </svg>
              </Box>
              <Typography
                variant={'body1'}
                sx={{
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                Products & Services
              </Typography>
            </FaultButton>
            <FaultButton elevation={0} onClick={() => setActionOpen(true)}>
              <Typography
                variant={'body1'}
                lineHeight={1}
                sx={{
                  textAlign: 'center',
                  mt: '5px'
                }}
              >
                <WavingHand
                  sx={{
                    color: 'white'
                  }}
                />
              </Typography>
              <Typography
                variant={'body1'}
                sx={{
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                {isResolved ? 'Add Comment' : 'Mark Resolved'}
              </Typography>
            </FaultButton>
          </Box>
          <Dialog open={actionOpen}>
            <ActionDialogContents faultId={id} resolvedStatus={isResolved} onCancel={() => setActionOpen(false)} />
          </Dialog>
        </Grid>
      </Grid>
    </AvaniStyledCard>
  );
};
