import { Loader } from './loader.routes';
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';
import SidebarLayout from 'src/layouts/SidebarLayout';
import { BarsProvider } from 'src/contexts/BarsContext';
import ProtectedRoute from './Components/ProtectedRoute';

const AmsPartsList = Loader(lazy(() => import('src/content/pages/AmsParts/AmsPartsList')));

export const createAmsPartsRoutes = () => ({
  path: 'ams',
  element: <SidebarLayout />,
  children: [
    {
      path: '',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <Navigate to="/ams/list" replace />
        </ProtectedRoute>
      )
    },
    {
      path: 'list',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <BarsProvider>
            <AmsPartsList />
          </BarsProvider>
        </ProtectedRoute>
      )
    }
  ]
});
