import * as React from 'react';

import {
  Dialog,
  Typography,
  Slide,
  Paper,
  List,
  ListItem,
  ListItemText,
  useTheme,
} from '@mui/material';
import { Info } from '@mui/icons-material';
import { TransitionProps } from '@mui/material/transitions';
import { AvaniLineTitle } from 'src/components/DataDisplay/AvaniLineTitle';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AvaniLineConditionDialog: React.FC = ({}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (e:any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e:any) => {
    e.stopPropagation();
    setOpen(false);
  };

  const theme = useTheme();

  return (
    <span style={{
      marginLeft: 16
    }}>
      <Info
        fontSize={'large'}
        color={'aYellow'}
        onClick={(e) => handleClickOpen(e)}
        sx={{
          cursor: 'pointer'
        }}
      />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(e) => handleClose(e)}
        aria-describedby="alert-dialog-slide-description"
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: theme.colors.avani.turc,
              opacity: .3
            }
          }
        }}
      >
        <Paper
          elevation={0}
          sx={{
            padding: 2,
            display: 'block',
          }}
          component={'div'}
        >
          <Typography variant='h2' sx={{
            color: theme.colors.avani.teal
          }}>
            Line Condition
          </Typography>

          <Typography
            variant='body2'
            fontSize={18}
          >
            Colors A through D describe the pre-clean condition of the lines and give an indication of how the beers might have been performing prior to the ProClean.
          </Typography>

          <List sx={{ p: 0, py: 2 }}>
            <ListItem sx={{ p: 0, pb: 1 }}>
              <ListItemText
                primary={<AvaniLineTitle
                  color={theme.colors.avani.aDarkPink}
                  title={'Very Clean'}
                  type={'A'}
                />}
                secondary={
                  <Typography
                    sx={{
                      display: 'block',
                      marginTop: '8px'
                    }}
                    variant='body2'
                    fontSize={16}
                  >
                    No organic material found in lines
                  </Typography>
                }
              />
            </ListItem>
            <ListItem  sx={{ p: 0, pb: 1 }}>
              <ListItemText
                primary={<AvaniLineTitle color={theme.colors.avani.aPurple} title={'Good level of hygiene'} type={'B'} />
                  }
                secondary={
                  <Typography
                    sx={{
                      display: 'block',
                      marginTop: '8px'
                    }}
                    variant='body2'
                    fontSize={16}
                  >
                    A small amount of organic material but nothing that will affect dispense.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem  sx={{ p: 0, pb: 1 }}>
              <ListItemText
                primary={<AvaniLineTitle color={theme.colors.avani.aGreen} title={'Some cleaning required'} type={'C'} />
                  }
                secondary={
                  <Typography
                    sx={{
                      display: 'block',
                      marginTop: '8px'
                    }}
                    variant='body2'
                    fontSize={16}
                  >
                    Organic material found. Depending on the product, this could indicate teh start of a quality issue. Some products which are particularly complex/yeasty will normally be a "C".
                  </Typography>
                }
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                primary={<AvaniLineTitle color={theme.colors.avani.aYellow} title={'Contaminated line, Cleaning required'} type={'D'} />
                  }
                secondary={
                  <Typography
                    sx={{
                      display: 'block',
                      marginTop: '8px'
                    }}
                    variant='body2'
                    fontSize={16}
                  >
                    A lot of organic material found. Regardless of the product, this result is undesirable. Lines that show a "D" are likely to have beer quality issues.
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Paper>
      </Dialog>
    </span>
  );
};
