import { Loader } from './loader.routes';
import { Navigate, RouteObject } from 'react-router-dom';
import { lazy } from 'react';
import SidebarLayout from 'src/layouts/SidebarLayout';
import ProtectedRoute from './Components/ProtectedRoute';
import { VisitsGeneralProvider } from '../../contexts/Reports/VisitsGeneralContext';
import { ClientEstateProvider } from '../../contexts/Reports/ClientEstateContext';
import { VisitsClientProvider } from '../../contexts/Reports/VisitsClientContext';
import { AmsPartsUsedProvider } from '../../contexts/Reports/AmsPartsUsedContext';

const VisitsGeneral = Loader(lazy(() => import('src/content/pages/Reports/VisitsGeneral')));
const ClientEstate = Loader(lazy(() => import('src/content/pages/Reports/ClientEstate')));
const VisitsClient = Loader(lazy(() => import('src/content/pages/Reports/VisitsClient')));
const AmsPartsUsed = Loader(lazy(() => import('src/content/pages/Reports/AmsPartsUsed')));

export type CustomRouteObject = RouteObject & {
  children?: CustomRouteObject[];
};

export const createReportRoutes = (): CustomRouteObject => ({
  path: 'reports',
  element: <SidebarLayout />,
  children: [
    {
      path: '',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <Navigate to="/reports/visits-general" replace />
        </ProtectedRoute>
      )
    },
    {
      path: 'visits-general',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <VisitsGeneralProvider>
            <VisitsGeneral />
          </VisitsGeneralProvider>
        </ProtectedRoute>
      )
    },
    {
      path: 'client-estate',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <ClientEstateProvider>
            <ClientEstate />
          </ClientEstateProvider>
        </ProtectedRoute>
      )
    },
    {
      path: 'visits-client',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <VisitsClientProvider>
            <VisitsClient />
          </VisitsClientProvider>
        </ProtectedRoute>
      )
    },
    {
      path: `ams-parts`,
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <AmsPartsUsedProvider>
            <AmsPartsUsed />
          </AmsPartsUsedProvider>
        </ProtectedRoute>
      )
    }
  ]
});
