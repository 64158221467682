import { Loader } from './loader.routes';
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';
import SidebarLayout from 'src/layouts/SidebarLayout';
import { ClientsProvider } from 'src/contexts/ClientsContext';
import ProtectedRoute from './Components/ProtectedRoute';
import { ClientDashboardsProvider } from '../../contexts/ClientDashboardContext';

const ClientsList = Loader(lazy(() => import('src/content/pages/Clients/ClientsList')));

const ClientCreate = Loader(lazy(() => import('src/content/pages/Clients/ClientCreate')));

const ClientUpdate = Loader(lazy(() => import('src/content/pages/Clients/ClientUpdate')));

const ClientDashboard = Loader(lazy(() => import('src/content/pages/Dashboards/ClientDashboard')));

export const createClientsRoutes = () => ({
  path: 'clients',
  element: <SidebarLayout />,
  children: [
    {
      path: '',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <Navigate to="/clients/list" replace />
        </ProtectedRoute>
      )
    },
    {
      path: 'list',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <ClientsProvider>
            <ClientsList />
          </ClientsProvider>
        </ProtectedRoute>
      )
    },
    {
      path: `:clientId/update`,
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <ClientsProvider>
            <ClientUpdate />
          </ClientsProvider>
        </ProtectedRoute>
      )
    },
    {
      path: `:clientId/dashboard`,
      element: (
        <ProtectedRoute groupRequired={['avani', 'clients']} roleRequired={['*']}>
          <ClientsProvider>
            <ClientDashboardsProvider>
              <ClientDashboard />
            </ClientDashboardsProvider>
          </ClientsProvider>
        </ProtectedRoute>
      )
    },
    {
      path: 'create',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <ClientsProvider>
            <ClientCreate />
          </ClientsProvider>
        </ProtectedRoute>
      )
    }
  ]
});
