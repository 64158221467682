import * as React from 'react';

import {
  Avatar,
  Typography,
} from '@mui/material';

export interface AvaniLineTitleProps {
  type: 'A' | 'B' | 'C' | 'D',
  title: string,
  color: string,
}

function bgColor(hex: string):string {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result ? 'rgba(' + parseInt(result[1], 16) + ',' +
    parseInt(result[2], 16) + ',' +
    parseInt(result[3], 16) + ', .5)' : '' 
}

export const AvaniLineTitle: React.FC<AvaniLineTitleProps> = ({
  type,
  title,
  color,
}) => {
  return (
    <div style={{
      borderRadius: '4px',
      backgroundColor: bgColor(color),
      display: 'flex'
    }}>
      <Avatar
        sx={{
          bgcolor: color,
          width: 24,
          height: 24,
          borderRadius: '4px',
          paddingTop: '3px',
          marginRight: '8px',
        }}
      >
        {type}
      </Avatar>
      <Typography
        variant='h3'
        lineHeight={1.1}
        color={'primary'}
        fontSize={18}
        sx={{
          paddingTop: .5,
          flexGrow: 1,
        }}
      >
        {title}
      </Typography>
    </div>
  );
};
