import { Order } from '../types/common.types';

export default class PaginationParams {
  limit?: number = 10;

  offset?: number = 0;

  sortBy?: string;

  order?: Order = 'desc nulls last';
}

export class BasicPaginationParams {
  limit?: number = 40;

  offset?: number = 0;
}
