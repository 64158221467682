import * as React from 'react';

import {
  Box,
} from '@mui/material';

import { AvaniHygieneCard } from 'src/components/Cards/AvaniHygieneCard';

import { BarElementData } from 'src/models/types/DashboardBlock/hygiene.types';

interface AvaniHygieneProps {
  nozzles?: BarElementData,
  glassWare?: BarElementData,
  glassWashers?: BarElementData,
  fobs?: BarElementData,
  couplers?: BarElementData,
  sockets?: BarElementData,
  flushWaterClean?: BarElementData,
  pythonCondition?: BarElementData,
  remoteGrille?: BarElementData,
  tapSeals?: BarElementData,
}

export const AvaniHygiene: React.FC<AvaniHygieneProps> = ({
  nozzles,
  glassWare,
  glassWashers,
  fobs,
  sockets,
  couplers,
  flushWaterClean,
  pythonCondition,
  remoteGrille,
  tapSeals,
}) => {
  const cards = [
    nozzles && nozzles,
    glassWare && glassWare,
    glassWashers && glassWashers,
    fobs && fobs,
    sockets && sockets,
    couplers && couplers,
    flushWaterClean && flushWaterClean,
    tapSeals && tapSeals,
    pythonCondition && pythonCondition,
    remoteGrille && remoteGrille,
  ].filter((v) => !!v);
  const cardWidth = cards.length * (cards.length > 3 ? 409 : 432);

  return (
    <Box
      sx={{
        width: cardWidth + 'px',
        display: 'flex',
        alignItems: 'stretch',
      }}
    >
      {nozzles &&
        <AvaniHygieneCard
          data={nozzles}
          category={'nozzles'}
          title={'Nozzles'}
          key={'nozzles'}
        />
      }
      {glassWare &&
        <AvaniHygieneCard
          data={glassWare}
          title={'Glassware'}
          category={'glassWare'}
          key={'glassWare'}
        />
      }
      {glassWashers &&
        <AvaniHygieneCard
          title={'Glass-washer'}
          data={glassWashers}
          category={'glassWashers'}
          key={'glassWashers'}
        />
      }
      {fobs &&
        <AvaniHygieneCard
          title={'FOB'}
          data={fobs}
          category={'fobs'}
          key={'fobs'}
        />
      }
      {flushWaterClean &&
        <AvaniHygieneCard
          title={'Flush Water Clean'}
          data={flushWaterClean}
          category={'flushWaterClean'}
          key={'flushWaterClean'}
        />
      }
      {sockets &&
        <AvaniHygieneCard
          title={'Socket'}
          data={sockets}
          category={'sockets'}
          key={'sockets'}
        />
      }
      {couplers &&
        <AvaniHygieneCard
          title={'Coupler'}
          data={couplers}
          category={'couplers'}
          key={'couplers'}
        />
      }
      {tapSeals &&
        <AvaniHygieneCard
          title={'Tap Seal'}
          data={tapSeals}
          category={'tapSeals'}
          key={'tapSeals'}
        />
      }
      {pythonCondition &&
        <AvaniHygieneCard
          title={'Python Condition'}
          data={pythonCondition}
          category={'pythonCondition'}
          key={'pythonCondition'}
        />
      },
      {remoteGrille &&
        <AvaniHygieneCard
          title={'Remote Grille'}
          data={remoteGrille}
          category={'remoteGrille'}
          key={'remoteGrille'}
        />
      },
    </Box>
  );
};
