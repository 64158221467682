import KeyboardArrowLeftTwoToneIcon from '@mui/icons-material/KeyboardArrowLeftTwoTone';
import KeyboardArrowRightTwoToneIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';
import { alpha, Box, IconButton, lighten, styled, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { FC, ReactNode, useContext } from 'react';
import { Outlet } from 'react-router-dom';

import { SidebarContext } from 'src/contexts/SidebarContext';

import Header from './Header';
import Sidebar from './Sidebar';
import CollapsedBrewersTSPsSubMenu from './Sidebar/CollapsedBrewersTSPsSubMenu';
import CollapsedUsersSubMenu from './Sidebar/CollapsedUsersSubMenu';
import SidebarLogo from './Sidebar/SidebarLogo';
import CollapsedReportsSubMenu from './Sidebar/CollapsedReportsSubMenu';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const StyledCollapseButton = styled(IconButton)(
  ({ theme }) => `
  position: absolute;
  bottom: 18px;
  left: 18px;
  border-radius: 20px;
  z-index: 7;
  border: 1px solid ${theme.colors.alpha.white[100]};
  color: ${theme.colors.alpha.white[100]};
  background-color: ${theme.colors.secondary.main};
  transition-property: left;
  transition-duration: 300ms;
  &:hover {
    background-color: ${theme.colors.secondary.light};
  }
`
);

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { toggleSubMenu, toggleBrewersTSPsSubMenu, toggleReportsSubMenu, collapsed, setCollapsed } =
    useContext(SidebarContext);

  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: '100%',
          '.MuiPageTitle-wrapper': {
            background: theme.palette.mode === 'dark' ? theme.colors.alpha.trueWhite[5] : theme.colors.alpha.white[50],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                    lighten(theme.colors.primary.main, 0.7),
                    0.15
                  )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(theme.colors.alpha.black[100], 0.1)}, 0px 5px 12px -4px ${alpha(
                    theme.colors.alpha.black[100],
                    0.05
                  )}`
          }
        }}
      >
        <Box style={{ display: 'flex', height: '100%', overflow: 'hidden' }}>
          {isDesktop && (
            <Tooltip arrow placement="top" title={collapsed ? 'Expand' : 'Collapse'}>
              <StyledCollapseButton onClick={() => setCollapsed(!collapsed)} color="primary">
                {collapsed ? <KeyboardArrowRightTwoToneIcon /> : <KeyboardArrowLeftTwoToneIcon />}
              </StyledCollapseButton>
            </Tooltip>
          )}
          <Sidebar collapsedWidth={isDesktop ? '80px' : '0px'}>
            <Box>
              <SidebarLogo full={!collapsed} />
            </Box>
          </Sidebar>
          {collapsed && toggleSubMenu && <CollapsedUsersSubMenu />}
          {collapsed && toggleBrewersTSPsSubMenu && <CollapsedBrewersTSPsSubMenu />}
          {collapsed && toggleReportsSubMenu && <CollapsedReportsSubMenu />}
          <Box
            sx={{
              zIndex: 6,
              flex: 1,
              height: '100%',
              position: 'relative',
              overflow: 'hidden'
            }}
          >
            <Header />
            <Box
              sx={{
                overflow: 'auto',
                display: !isMobile || (isMobile && collapsed) ? 'block' : 'none',
                height: `calc(100% - ${theme.header.height})`
              }}
            >
              <Outlet />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SidebarLayout;
