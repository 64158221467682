import * as React from 'react';

import {
  Avatar,
  Box,
  Typography,

  useTheme,
} from '@mui/material';

import { AvaniCard } from 'src/components/Cards/AvaniCard';

type AvaniActionsRequiredCardProps = {
  numberOfActions?: number,
};

export const AvaniActionsRequiredCard: React.FC<AvaniActionsRequiredCardProps> = ({
  numberOfActions,
}) => {
  const theme = useTheme();

  return (
    <AvaniCard padded
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        ['&.AvaniCardPadded .MuiTypography-root.MuiTypography-h3']: {
          paddingBottom: 0,
        }, 
      }}
    >
      {numberOfActions &&
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '100%',
        }}
      >
        <Typography
          variant={'h3'}
          component={'div'}
          fontSize={24}
          color={theme.colors.avani.teal}
        >
          Actions Required By Venue
        </Typography>
        <Box sx={{
          flexGrow: 1,
          justifyContent: 'space-around',
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Avatar
            sx={{
              bgcolor: theme.colors.avani.magenta,
              width: 64,
              height: 64,
              paddingTop: '3px',
              fontSize: '24px'
            }}
          >
            {numberOfActions}
          </Avatar>
        </Box>
      </Box>
      }
      </AvaniCard>
  );
};
