import React, { FC } from 'react';
import { Button, CircularProgress, ButtonProps } from '@mui/material';

interface LoadingButtonProps extends ButtonProps {
  loading: boolean;
  children: React.ReactNode;
}

const LoadingButton: FC<LoadingButtonProps> = ({ loading, children, ...props }) => {
  return (
    <Button {...props} startIcon={loading ? <CircularProgress size={20} /> : null} disabled={loading || props.disabled}>
      {children}
    </Button>
  );
};

export default LoadingButton;
