import * as React from 'react';
import { DateTime } from 'luxon';

import {
  Avatar,
  Box,
  Card,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,

  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import {
  Check,
  Clear,
} from '@mui/icons-material';

import { GlassWasherData } from 'src/models/types/DashboardBlock/glass-washers.types';
import { ImageViewer } from 'src/components/Dialogs/ImageViewer/ImageViewer';
import { AvaniScrollable } from 'src/components/Utils/AvaniScrollable';

const AvaniStyledCard = styled(Card)(
  ({ theme }) => `
  border-radius: 16px;
  padding: 16px;

  .MuiTypography-root.MuiTypography-h3 {
    margin: 0;
    line-height: 1;
    padding-bottom: 12px;
  }
`);

export const AvaniGlassWasherCard: React.FC<GlassWasherData> = ({
  glassWasherName,
  detergentNew,
  detergentCurrent,
  rinseCurrent,
  rinseNew,
  media,
  hygieneStatusVisitDatesTrend,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const readableDate = (dateObject: Date) => {
    return DateTime.fromISO(new Date(dateObject).toISOString()).toFormat('dd/MM');
  };

  return (
    <AvaniStyledCard
      raised={true}
      sx={{
        height: '100%',
        textAlign: 'center',
        backgroundColor: !hygieneStatusVisitDatesTrend[0] ?
          theme.colors.avani.lightMagenta : theme.colors.avani.lightTurc,
      }}
    >
      <Typography
        variant="h3"
        color={theme.colors.avani.teal}
      >
        {glassWasherName}
      </Typography>
      {/* {!!media.map((item) => !!item.contentUrl).length &&
      <Box
        sx={{
          display: 'flex',
          pt: '24px',
          minHeight: '125px',
          justifyContent: 'space-evenly',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {media.map((mediaItem) => {
            return (
              <ImageViewer
                key={mediaItem.id}
                avatarWidth={60}
                avatarHeight={60}
                imageSrc={mediaItem.contentUrl ? mediaItem.contentUrl : ''}
              />
            );
          })
          }
        </Box>
      </Box>
      } */}
      <Typography 
        variant='h4'
        color={theme.colors.avani.teal}
        sx={{
          py: '12px',
          textAlign: 'left',
        }}
      >
        Trend
      </Typography>
      {hygieneStatusVisitDatesTrend.length &&
      <AvaniScrollable
        height={isMobile? 78: 92}
        horizontal
      >
        <List
          sx={{
            width: (isMobile ? 88 : 98) * hygieneStatusVisitDatesTrend.length 
          }}
        >
          {hygieneStatusVisitDatesTrend.map((item, ind) => {
            return (
              <ListItem
                disablePadding
                key={ind}
                sx={{
                  display: 'inline-block',
                  width: 'auto',
                  minWidth: '80px',
                  float: 'left',
                  padding: '0 12px 0 0',
                }}
              >
                <Avatar
                  sx={{
                    margin: '0 auto',
                    backgroundColor: !item.hygieneStatus ?
                      'rgba(230,0,126, 0.4)' : 'rgba(0,167,168, 0.4)'
                  }}
                >
                  {item.hygieneStatus ?
                    <Check sx={{
                      fill: theme.colors.avani.turc
                    }} />
                    :
                    <Clear sx={{
                      fill: theme.colors.avani.magenta
                    }} />
                  }
                </Avatar>
                <Typography
                  variant={'body2'}
                  color={!item.hygieneStatus ? theme.colors.avani.magenta : theme.colors.avani.turc}
                  sx={{
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  {readableDate(item.date)}
                </Typography>
              </ListItem>
            );
          })}
        </List>
      </AvaniScrollable>
      }
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Typography
          variant='h4'
          color={theme.colors.avani.teal}
          sx={{
            display: 'inline-block',
            py: '12px',
            pt: '24px',
            width: '100%',
            textAlign: 'left',
            flexGrow: 1,
          }}
        >
          Chemical consumption
        </Typography>
        {/* <Avatar
          sx={{
            mt: '12px',
            pt: '3px',
            backgroundColor: theme.colors.avani.turc
          }}
        >{chem.consumption}L</Avatar> */}
      </Box>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: 'transparent'
            }}
          >
            <TableCell></TableCell>
            <TableCell
              align="center"
              sx={{
                textTransform: 'none',
                color: theme.colors.avani.teal
              }}
            >
              Detergent
            </TableCell>
            <TableCell
              align="center"
              sx={{
                textTransform: 'none',
                color: theme.colors.avani.teal
              }}
            >
              Rinse
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              Found
            </TableCell>
            <TableCell align="center">{detergentCurrent}</TableCell>
            <TableCell align="center">{rinseCurrent}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Left
            </TableCell>
            <TableCell align="center">{detergentNew}</TableCell>
            <TableCell align="center">{rinseNew}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </AvaniStyledCard>
  );
};
