import * as React from 'react';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router';

import { List, ListItem, Typography, useTheme } from '@mui/material';

import { ActualWastageByProduct } from 'src/models/types/DashboardBlock/wastage.types';

interface AvaniWastageProductListProps {
  products: ActualWastageByProduct[];
  units?: string;
  dense?: boolean;
  listTypeIcon?: React.ReactElement;
  truncateValue?: boolean;
  date?: 'prop' | 'val';
  link?: boolean;
}

export const AvaniWastageProductList: React.FC<AvaniWastageProductListProps> = ({
  products,
  truncateValue,
}) => {
  const theme = useTheme();
  const productNameSx: any = truncateValue
    ? { paddingTop: '3px', paddingLeft: '4px', float: 'left' }
    : {
        display: 'inline-block',
        paddingTop: '3px',
        paddingLeft: '4px',
        flexGrow: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      };
  const productValueSx: any = truncateValue
    ? {
        display: 'inline-block',
        paddingTop: '3px',
        paddingLeft: '4px',
        flexGrow: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    : { float: 'right' };

  return (
    <List
      disablePadding
      sx={{
        display: 'block',
        minWidth: '178px'
      }}
    >
      {products.map((product, i) => {
        return (
          <ListItem
            disablePadding
            key={i}
            sx={{
              display: 'block',
              borderBottom: '1px dashed ' + theme.colors.avani.teal,
              paddingBottom: 1.5,
              pt: i > 0 ? (1.5) : 0
            }}
          >
              <Typography
                variant={'body2'}
                component={'div'}
                fontWeight={'bold'}
                fontSize={16}
                color={theme.colors.primary}
                sx={{
                  display: 'flex',
                }}
              >
                <span style={productNameSx}>{product.productName}</span>
                {product.actual !== null && <span style={productValueSx}>{product.actual}</span>}
              </Typography>
          </ListItem>
        );
      })}
    </List>
  );
};
