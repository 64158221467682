import * as React from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, useTheme } from '@mui/material';

import { ExpandMore, Lock } from '@mui/icons-material';

import { AvaniCard } from 'src/components/Cards/AvaniCard';
import { AvaniPaperCard } from 'src/components/Cards/AvaniPaperCard';
import { AvaniSavedPintsCard } from 'src/components/Cards/AvaniSavedPintsCard';

import { AvaniTotalWastageChart } from 'src/components/Charts/AvaniTotalWastageChart';
import { AvaniScrollable } from 'src/components/Utils/AvaniScrollable';
import { VenueWastageEfficiencyScore } from 'src/components/DataDisplay/VenueWastageEfficiencyScore';

import { VisitDashboardCommentModel } from 'src/models/types/comments.types';

import {
  ActualWastageByProduct,
  SavedData,
  TotalSavedPerVisitData,
  WastageEfficiencyData
} from 'src/models/types/DashboardBlock/wastage.types';
import { AvaniWastageProductList } from 'src/components/DataDisplay/AvaniWastageProductList';

type WastageBlockProps = {
  byProduct?: ActualWastageByProduct[];
  perVisit?: TotalSavedPerVisitData[];
  saved?: SavedData;
  efficiencyScore?: WastageEfficiencyData;
  comment?: VisitDashboardCommentModel;
  productOnly?: boolean;
};

const midLevelBackgroundColor = '#d1eded';

export const WastageBlock: React.FC<WastageBlockProps> = ({
  efficiencyScore,
  perVisit,
  saved,
  byProduct,
  comment,
  productOnly
}) => {
  const theme = useTheme();

  return (
    <div style={{ marginTop: '36px' }}>
      <Accordion
        defaultExpanded
        sx={{
          background: 'transparent'
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore fontSize={'large'} />}
          sx={{
            padding: 0,
            borderRadius: 0
          }}
        >
          <Typography
            variant={'h2'}
            sx={{
              color: theme.colors.avani.teal
            }}
          >
            Wastage
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: 0
          }}
        >
          <Grid container columns={10} spacing={1}>
            {!!comment && (
              <Grid item xs={10}>
                <AvaniPaperCard {...comment} />
              </Grid>
            )}

            {!productOnly && (
              <>
                <Grid item xs={10} md={6}>
                  <Grid container columns={3} spacing={1}>
                    {efficiencyScore && (
                      <Grid item xs={3} sm={2}>
                        <AvaniCard title={'Venue Wastage Efficiency Score'}>
                          <Typography
                            variant={'body2'}
                            fontSize={12}
                            sx={{
                              px: 2,
                              mt: '-14px',
                              minHeight: '35px'
                            }}
                          >
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.
                          </Typography>
                          <VenueWastageEfficiencyScore
                            esLines={efficiencyScore.lines}
                            esTotal={efficiencyScore.total}
                            esSaved={efficiencyScore.saved}
                            esPercent={Math.round((efficiencyScore.saved / efficiencyScore.total) * 100)}
                          />
                        </AvaniCard>
                      </Grid>
                    )}
                    {saved && saved.totalSaved !== null && (
                      <Grid item xs={3} sm={1}>
                        <AvaniSavedPintsCard
                          title={'Total Pints Saved'}
                          savedPintsValue={saved?.totalSaved ?? 0}
                          text={'Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla.'}
                          sx={{
                            backgroundColor: midLevelBackgroundColor
                          }}
                        />
                      </Grid>
                    )}

                    {perVisit && (
                      <Grid item xs={3}>
                        <AvaniCard
                          title={'Total Saved per Visit'}
                          padded
                          green
                          icon={
                            <Lock
                              fontSize="large"
                              sx={{
                                color: 'white'
                              }}
                            />
                          }
                        >
                          <AvaniTotalWastageChart data={perVisit} sortBy={'date'} white />
                        </AvaniCard>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={10} md={4}>
                  <Grid container columns={2} spacing={1}>
                    {saved && saved.proCleanSaved !== null && (
                      <Grid item xs={1}>
                        <AvaniSavedPintsCard
                          small
                          title={'Pints Saved by ProClean'}
                          savedPintsValue={saved.proCleanSaved}
                        />
                      </Grid>
                    )}
                    {saved && saved.beersSaved !== null && (
                      <Grid item xs={1}>
                        <AvaniSavedPintsCard
                          small
                          title={'Pints Saved by Alea Glasgow'}
                          savedPintsValue={saved.beersSaved}
                        />
                      </Grid>
                    )}
                    {byProduct && (
                      <Grid item xs={2}>
                        <AvaniCard
                          title={'Total Wastage By Product'}
                          padded
                          icon={
                            <svg
                              width="24"
                              height="32"
                              viewBox="0 0 24 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.99984 0C1.44355 0 0.91226 0.23125 0.537232 0.6375C0.162203 1.04375 -0.0378128 1.5875 0.00594054 2.14375L1.80608 27.3562C1.99359 29.975 4.16876 32 6.79396 32H17.2073C19.8325 32 22.0076 29.975 22.1952 27.3562L23.9953 2.14375C24.0328 1.5875 23.8453 1.04375 23.464 0.6375C23.0827 0.23125 22.5577 0 22.0014 0H1.99984ZM4.43128 8L4.15001 4H19.8512L19.5637 8H4.43128Z"
                                fill="#005665"
                              />
                            </svg>
                          }
                          sx={{
                            backgroundColor: midLevelBackgroundColor
                          }}
                        >
                          <AvaniScrollable height={300} width={200} key={'totalWastage'}>
                            <AvaniWastageProductList products={byProduct} />
                          </AvaniScrollable>
                        </AvaniCard>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
            {/* {!!productOnly && byProduct &&
            <Grid item xs={10}>
              <AvaniCard
                title={'Total Wastage By Product'}
                padded
                icon={<svg width="24" height="32" viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.99984 0C1.44355 0 0.91226 0.23125 0.537232 0.6375C0.162203 1.04375 -0.0378128 1.5875 0.00594054 2.14375L1.80608 27.3562C1.99359 29.975 4.16876 32 6.79396 32H17.2073C19.8325 32 22.0076 29.975 22.1952 27.3562L23.9953 2.14375C24.0328 1.5875 23.8453 1.04375 23.464 0.6375C23.0827 0.23125 22.5577 0 22.0014 0H1.99984ZM4.43128 8L4.15001 4H19.8512L19.5637 8H4.43128Z" fill="#005665"/>
                  </svg>}
                sx={{
                  backgroundColor: midLevelBackgroundColor,
                }}
              >
                <AvaniScrollable
                  height={300}
                  width={200}
                  key={'totalWastage'}
                >
                  <AvaniWastageProductList
                    products={byProduct}
                  />
                </AvaniScrollable>
              </AvaniCard>
            </Grid>
            } */}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
