import * as React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';

import {
  Thermostat,
  ExpandMore
} from '@mui/icons-material';

import { AvaniCard } from 'src/components/Cards/AvaniCard';
import { AvaniSavedBeerCard } from 'src/components/Cards/AvaniSavedBeerCard';
import { AvaniFaultsCard } from 'src/components/Cards/AvaniFaultsCard';
import { AvaniRankingCard } from 'src/components/Cards/AvaniRankingCard';
import { AvaniCurrentVisitCard } from 'src/components/Cards/AvaniCurrentVisitCard';
import { AvaniPerfectPintCard } from 'src/components/Cards/AvaniPerfectPintCard';
import { AvaniPaperCard } from 'src/components/Cards/AvaniPaperCard';
import { AvaniTotalServicedCard } from 'src/components/Cards/AvaniTotalServicedCard';
import { AvaniTempPieChart } from 'src/components/Charts/AvaniTempPieChart';
import { AvaniActionsRequiredCard } from 'src/components/Cards/AvaniActionsRequiredCard';

import type {
  TotalServicedData,
  RankingData,
} from 'src/types/Dashboard';

import { VisitDashboardCommentModel } from 'src/models/types/comments.types';
import { VisitData, PreCleanResults, FaultTypesData } from 'src/models/types/DashboardBlock/general.types';
import { MediaModel } from 'src/models/types/medias.types';

type VisitInformationBlockProps = {
  columns: number,
  currentVisit?: VisitData,
  preCleanResults?: PreCleanResults,
  temperature?: boolean,
  ranking?: RankingData,
  faults?: FaultTypesData,
  savedBeer?: number,
  perfectPintImages?: MediaModel[],
  safetyCheckImages?: MediaModel[],
  totals?: TotalServicedData,
  actionsRequired?: number,
  comment?: VisitDashboardCommentModel,
  chillPour?: boolean,
};

export const VisitInformationBlock:React.FC<VisitInformationBlockProps> = ({
  columns,
  currentVisit,
  preCleanResults,
  temperature,
  savedBeer,
  perfectPintImages,
  safetyCheckImages,
  ranking,
  faults,
  totals,
  actionsRequired,
  comment,
  chillPour,
}) => {
  const theme = useTheme();

  return (
    <div style={{marginTop: '36px'}}>
    <Accordion
      defaultExpanded
      sx={{
        background: 'transparent',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore fontSize={'large'} />}
        sx={{
          padding: 0,
          borderRadius: 0,
        }}
      >
        <Typography variant={'h2'} sx={{
          color: theme.colors.avani.teal,
        }}>
          Visit Information
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: 0
        }}
      >
        <Grid
          container
          mt={0}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={1}
          columns={columns}>

          {comment && 
          <Grid item
            xs={columns}
          >
            <AvaniPaperCard
              {...comment}
            />
          </Grid>}

          {currentVisit &&
          <Grid item xs={columns} sm={columns/2} md={columns/3} lg={1}>
            <AvaniCurrentVisitCard
              data={currentVisit}
            />
          </Grid>}

          {preCleanResults &&
          <Grid item xs={columns} sm={columns/2} md={columns/3} lg={1}>
            <AvaniCard
              title="Pre-clean Results"
            >
              <AvaniTempPieChart
                data={preCleanResults.data}
                total={preCleanResults.total}
                units={'lines'}
              ></AvaniTempPieChart>
            </AvaniCard>
          </Grid>}

          {temperature !== null &&
          <Grid item xs={columns} sm={columns} md={columns/3} lg={1}>
            <AvaniCard
              short={!!ranking}
              temp={!ranking}
              isHighTemp={temperature}
              title="Temperatures"
              icon={<Thermostat
                fontSize="large"
                color="aTeal"
              />}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >

              <Typography
                variant="body2"
                sx={{
                  padding: 2,
                  paddingY: 0,
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'end',
                }}
              >
                {temperature ?
                  'Some temperatures are out of range' : 'All temperatures match the requirements.'}
              </Typography>
            </AvaniCard>
          </Grid>}

          {!chillPour && faults &&
          <Grid item xs={columns} sm={columns} md={columns/2} lg={1}>
            <AvaniFaultsCard
              faults={faults}
            />
          </Grid>
          }
          {(chillPour && faults) &&
          <Grid item xs={columns} sm={columns} lg={1}>
            <AvaniFaultsCard
              faults={faults}
            />
          </Grid>
          }

          {perfectPintImages &&
          <Grid item xs={columns} sm={columns/2} md={columns/3} lg={1}>
            <AvaniPerfectPintCard
              images={perfectPintImages}
            />
          </Grid>
          }

          {safetyCheckImages &&
          <Grid item xs={columns} sm={columns/2} md={columns/3} lg={1}>
              <AvaniPerfectPintCard
                images={safetyCheckImages}
                safetyCheck
              />
          </Grid>
          }

          {savedBeer !== undefined &&
          <Grid item xs={columns} sm={columns/2} lg={1}>
            <AvaniSavedBeerCard
              beerSaved={savedBeer}
              title={'Beer Saved'}
              text={'Total pints of beer saved by ProClean for this Venue'}
            />
          </Grid>
          }

          {ranking &&
          <Grid item xs={columns} sm={columns/2} md={columns/3} lg={1}>
            <AvaniRankingCard
              title={'Ranking'}
              rankingValue={ranking.ranking}
              changeValue={ranking.change}
              lastVisitValue={ranking.lastVisit}
            />
          </Grid>}

          {actionsRequired &&
          <Grid item xs={columns} sm={columns/2} md={columns/3} lg={1}>
            <AvaniActionsRequiredCard
              numberOfActions={actionsRequired}
            />
          </Grid>}

          {totals &&
          <Grid item xs={columns} sm={columns/2} md={columns/3} lg={1}>
            <AvaniTotalServicedCard
              data={totals}
            />
          </Grid>}
        </Grid>
      </AccordionDetails>
    </Accordion>
  </div>
  )
};
