import { ReactNode, ReactElement, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

interface RequireAuthProps {
  children: ReactNode;
}

export default function RequireAuth({ children }: RequireAuthProps): ReactElement | null {
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState<boolean | null>(null);
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => setIsAuth(true))
      .catch(() => {
        navigate('/signin');
      });
  }, [navigate]);
  if (isAuth === null) {
    return null;
  }

  return isAuth ? <>{children}</> : null;
}
