import { createContext, FC, useState } from 'react';
import { RefMaterialsDto } from '../models/types/ref-materials.types';

interface IRefMaterialsContext {
  shouldUpdate: boolean;
  toggleShouldUpdate: () => void;
  data: RefMaterialsDto[];
  setData: (val: RefMaterialsDto[]) => void;
  page: number;
  setPage: (val: number) => void;
  limit: number;
  setLimit: (val: number) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
  nextToken?: string;
  setNextToken: (val?: string) => void;
}

export const RefMaterialsContext = createContext<IRefMaterialsContext>({} as IRefMaterialsContext);

export const RefMaterialsProvider: FC = ({ children }) => {
  const [nextToken, setNextToken] = useState<string | undefined>(undefined);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [data, setData] = useState<RefMaterialsDto[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [loading, setLoading] = useState(false);
  const toggleShouldUpdate = (): void => setShouldUpdate(!shouldUpdate);

  return (
    <RefMaterialsContext.Provider
      value={{
        shouldUpdate,
        toggleShouldUpdate,
        data,
        setData,
        page,
        setPage,
        limit,
        setLimit,
        loading,
        setLoading,
        nextToken,
        setNextToken
      }}
    >
      {children}
    </RefMaterialsContext.Provider>
  );
};
