import * as React from 'react';
import { DateTime } from 'luxon';

import { styled } from '@mui/material/styles';
import {
  Button,
  Popover,
  List,
  ListItem,
  Typography,
  useTheme,
} from '@mui/material';

import { DateRange } from '@mui/icons-material';
import { optionClasses } from '@mui/base';

type AvaniDropdownProps = {
  dateStrings?: Date[] | null,
  onSelectDate?: (date: string | null) => void,
  onSelectOption?: (value: string | number | null) => void,
  buttonSx?: any,
  dates?: boolean,
  options?: {name: string, value: string | number}[],
  togglerLabel?: string,
};

const StyledButton = styled(Button)(
  ({ theme })=> `
    background-color: ${theme.colors.avani.turc};
    border-radius: 8px;
    border: none;
    color: white;
    padding: 8px 12px;

    &:hover {
      border: none;
      color: ${theme.colors.avani.darkTitleText};

      .MuiSvgIcon-root {
        color: ${theme.colors.avani.darkTitleText};
      }
    }
`);

const StyledListItem = styled(ListItem)(
  ({ theme })=> `
  cursor: pointer;
  display: flex;

  svg {
    margin-right: 12px;
  }
`);

export const AvaniDropdown: React.FC<AvaniDropdownProps> = ({
  dateStrings,
  onSelectDate,
  onSelectOption,
  buttonSx,
  dates,
  options,
  togglerLabel,
}) => {
  const [anchorEl, setAnchorEl ] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const [ buttonLabel, setButtonLabel ] = React.useState(dates ? 'Select Date': togglerLabel);
  /* const [ item, setItem ] = React.useState('');
  const handleChange = (e:any) => {
    const refDate = e.target.value;

    setItem(refDate);

    onSelectDate && onSelectDate(refDate);
  }; */

  const selectDate = (dateString: string) => {
    const isISO = dateString.includes('-');
    const dateDisplay = isISO ? DateTime.fromISO(dateString).toFormat('dd/MM/yyyy') : dateString;

    setButtonLabel(dateDisplay);
    setAnchorEl(null);
  };

  const selectOption = (optionName: string, optionValue: string | number) => {
    setButtonLabel(optionName);
    setAnchorEl(null);
    /* onSelectOption && onSelectOption(optionValue); */
  };

  return (
    <>
      <StyledButton
        variant="outlined"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={buttonSx}
      >
        {dates &&
          <DateRange sx={{mr: 1}} />
        }
        {buttonLabel}
      </StyledButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <List>
          {dates && dateStrings && dateStrings?.map((dateString, ind) => {
            const readableDate = DateTime.fromISO(new Date(dateString).toISOString()).toFormat('dd/MM/yyyy');

            return (
              <StyledListItem
                key={dateString.toString()}
                value={new Date(dateString).toISOString()}
                onClick={() => selectDate(new Date(dateString).toISOString())}
              >
                <DateRange sx={{mr: 1}} />
                <Typography sx={{
                  flexGrow: 1,
                  pt: '3px'
                }}>
                  {readableDate}
                </Typography>
              </StyledListItem>);
            })
          }
          {dates && !dateStrings &&
          <StyledListItem
            onClick={() => selectDate(DateTime.now().toFormat('dd/MM/yyyy'))}
            value={DateTime.now().toFormat('yyyy-mm-dd')}>
            <DateRange sx={{mr: 1}} />
            <Typography sx={{
              flexGrow: 1,
              pt: '3px'
            }}>
              {DateTime.now().toFormat('dd/MM/yyyy')}
            </Typography>
          </StyledListItem>
          }
          {!dates && options && options.map((option, opIndex) => {
            return (
              <StyledListItem
                key={option.name+opIndex}
                onClick={() => selectOption(option.name, option.value)}
                value={option.value}>
                <Typography sx={{
                  flexGrow: 1,
                  pt: '3px'
                }}>
                  {option.name}
                </Typography>
              </StyledListItem>
            );
          })
          }
        </List>
      </Popover>
    </>
  );
};
