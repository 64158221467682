import * as React from 'react';
import { Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { BarChart, BarItem } from '@mui/x-charts';

interface AvaniWastageBarChartProps {
  data: {
    empty: number,
    full: number,
    partial: number,
  }
}

const StyledDiv = styled('div')(
  ({ theme }) => `
    height: 160px;
  `);

export const AvaniWastageBarChart: React.FC<AvaniWastageBarChartProps> = ({
  data,
}) => {
  const theme = useTheme();

  const chartColors = [
    theme.palette.aTeal.main,
    theme.palette.aDarkTitleText.main,
    theme.palette.aTurc.main,
  ];

  const barLabel = ( item: BarItem ):string | null => {
    return item.value + ' ' + item.seriesId;
  };

  return (
    <StyledDiv>
      <Typography
        variant={'body2'}
        fontSize={14}
        sx={{
          margin: '0 auto',
          width: '80px',
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '1px dashed '+ theme.colors.avani.turc
        }}
      >
        <span>
          Lines
        </span>
        <span>
          {data.full + data.partial + data.empty}
        </span>
      </Typography>
      <div
        style={{
          margin: '0 auto',
          width: '80px',
          height: '140px',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            overflow: 'hidden',
            marginLeft: '-60px',
            marginTop: '-4px',
          }}
        >
          <BarChart
            series={[
              { data: [data.full], label: 'A', id: 'Full', stack: 'total' },
              { data: [data.partial], label: 'A', id: 'Partial', stack: 'total' },
              { data: [data.empty], label: 'A', id: 'Empty', stack: 'total' },
            ]}
            colors={chartColors}
            xAxis={[{ data: ['A'], scaleType: 'band' }]}
            width={200}
            height={220}
            slotProps={{
              legend: { hidden: true }
            }}
            disableAxisListener
            barLabel={barLabel}
            tooltip={{
              trigger: 'none'
            }}
            sx={{
              marginLeft: 0,
              marginTop: '-70px',
              ' .MuiChartsAxis-root, .MuiChartsAxis-tickContainer' : {
                display: 'none'
              },
              ' .MuiBarLabel-root': {
                fill: 'white'
              },
              ' .MuiBarElement-series-Partial': {
                fill: 'url(#diagonalLine)'
              },
              ' .MuiBarLabel-series-Partial': {
                backgroundColor: 'white',
                p: 1,
                fill: theme.colors.avani.teal,
                stroke: 'white',
                strokeWidth: 5,
                paintOrder: 'stroke',
                strokeLinejoin: 'round'
              }
            }}
          >
            <pattern
              id="diagonalLine"
              patternUnits="userSpaceOnUse"
              width="4"
              height="4"
            >
              <path
                d="M-1,1 l2,-2
                  M0,4 l4,-4
                  M3,5 l2,-2" 
                style={{stroke: theme.colors.avani.turc, strokeWidth: 1}}
              />
            </pattern>
          </BarChart>
        </div>
      </div>
    </StyledDiv>
  );
};
