import * as React from 'react';

import {
  Avatar,
  Box,
  Card,
  Dialog,
  Divider,
  List,
  ListItem,
  Typography,

  alpha,
  darken,
  styled,
  useTheme,
} from '@mui/material';

import {
  Check,
  Clear,
  LocalDrink,
  LocalLaundryService,
  Shower,
  AltRoute,
  WaterDrop,
  CoffeeMaker,
  HeatPump,
  DataSaverOff,
  LocationOff,
  GridOn,
  Cable,
} from '@mui/icons-material';

import { AvaniButton } from 'src/components/Buttons/AvaniButton';

import type { HygieneItem, HygieneItemType } from 'src/types/Dashboard';
import { ImageViewer } from 'src/components/Dialogs/ImageViewer/ImageViewer';
import { AvaniHygienePieChart } from 'src/components/Charts/AvaniHygienePieChart';

import { BarElementData } from 'src/models/types/DashboardBlock/hygiene.types';

interface AvaniHygieneCardProps {
  category: HygieneItemType,
  data: BarElementData,
  title?: string,
}

const AvaniStyledCard = styled(Card)(
  ({ theme }) => `
  border-radius: 16px;
  padding: 16px;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 9px 9px 0;

  .MuiTypography-root.MuiTypography-h3 {
    margin: 0;
    line-height: 1;
    padding-bottom: 12px;
  }
`);

const OverflowingTextContent = styled(Box)(
  ({ theme }) => `
  &.long {
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 60px;
    position: relative;
  }

  &.long:after {
    content: '...  read more';
    top: 40px;
    right: 0;
    padding-left: 6px;
    position: absolute;
    font-size: 14px;
    line-height: 1.43;
    font-weight: normal;
    color: ${theme.colors.avani.turc}
  }
`);

export const AvaniHygieneCard: React.FC<AvaniHygieneCardProps> = ({
  category,
  title,
  data,
}) => {
  const theme = useTheme();
  const [ descriptionOpen, setDescriptionOpen ] = React.useState(false);
  
  const [ longDescContent, setLongDescContent ] = React.useState(false);
  const descriptionEl = React.useRef<HTMLElement>(null);
  const descriptionFrameEl = React.useRef<HTMLElement>(null);

  const iconSx = {
    fontSize: '32px',
  };

  /* const chartData = !!data.percent ? [
    {
      value: data.percent,
      label: 'Fail',
    },
    {
      value: (100 - data.percent),
      label: 'Pass',
    }
  ] : [ {value: 0, label: 'No data'} ]; */

  const headingIcon = category === 'nozzles' ?
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6_11)">
      <path opacity="0.4" d="M16 21C16.8313 21 17.5 21.6687 17.5 22.5C17.5 23.3313 16.8313 24 16 24C15.1687 24 14.5 23.3313 14.5 22.5C14.5 21.6687 15.1687 21 16 21ZM19.5 25C20.3313 25 21 25.6687 21 26.5C21 27.3313 20.3313 28 19.5 28C18.6687 28 18 27.3313 18 26.5C18 25.6687 18.6687 25 19.5 25ZM12.5 25C13.3313 25 14 25.6687 14 26.5C14 27.3313 13.3313 28 12.5 28C11.6687 28 11 27.3313 11 26.5C11 25.6687 11.6687 25 12.5 25ZM22.5 29C23.3313 29 24 29.6687 24 30.5C24 31.3313 23.3313 32 22.5 32C21.6687 32 21 31.3313 21 30.5C21 29.6687 21.6687 29 22.5 29ZM16 29C16.8313 29 17.5 29.6687 17.5 30.5C17.5 31.3313 16.8313 32 16 32C15.1687 32 14.5 31.3313 14.5 30.5C14.5 29.6687 15.1687 29 16 29ZM9.5 29C8.66875 29 8 29.6687 8 30.5C8 31.3313 8.66875 32 9.5 32C10.3313 32 11 31.3313 11 30.5C11 29.6687 10.3313 29 9.5 29Z" fill="#005665"/>
      <path opacity="0.4" d="M23.0125 14.7062C23.0125 14.9687 23.1875 15.2062 23.4437 15.2812L26.1562 16.0562L26.9312 18.7687C27.0062 19.025 27.2375 19.2 27.5062 19.2C27.775 19.2 28.0062 19.025 28.0812 18.7687L28.8562 16.0562L31.5687 15.2812C31.825 15.2062 32 14.975 32 14.7062C32 14.4375 31.825 14.2062 31.5687 14.1312L28.8562 13.3562L28.0812 10.6437C28.0062 10.3875 27.775 10.2125 27.5062 10.2125C27.2375 10.2125 27.0062 10.3875 26.9312 10.6437L26.1562 13.3562L23.4437 14.1312C23.1875 14.2062 23.0125 14.4375 23.0125 14.7062Z" fill="#005665"/>
      <path opacity="0.4" d="M0 18.675C0 18.8875 0.1375 19.075 0.35 19.1375L2.51875 19.7562L3.1375 21.925C3.19375 22.125 3.3875 22.275 3.6 22.275C3.8125 22.275 4 22.1375 4.0625 21.925L4.68125 19.7562L6.85 19.1375C7.05 19.0812 7.2 18.8875 7.2 18.675C7.2 18.4625 7.0625 18.275 6.85 18.2125L4.68125 17.5937L4.0625 15.425C4.00625 15.225 3.8125 15.075 3.6 15.075C3.3875 15.075 3.2 15.2125 3.1375 15.425L2.51875 17.5937L0.35 18.2125C0.15 18.2687 0 18.4625 0 18.675Z" fill="#005665"/>
      <path d="M13.6187 22.875C13.9812 23.2375 14.4687 23.4562 15 23.4625H17C17.75 23.45 18.425 23.025 18.7563 22.3563L24.0187 9.2C24.6 8.0375 24.575 6.6625 23.9625 5.51875C23.9625 5.51875 24.0688 3.03125 24.0125 0.85625C24.0125 0.45 23.675 0.125 23.2687 0.125H8.7375C8.33125 0.125 8 0.45 7.9875 0.85625C7.93125 3.03125 8.0375 5.5125 8.0375 5.5125C7.425 6.65625 7.39375 8.025 7.98125 9.19375L13.2437 22.35C13.3437 22.5438 13.4687 22.725 13.6187 22.875Z" fill="#005665"/>
    </g>
    <defs>
      <clipPath id="clip0_6_11">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>
   : category === 'glassWare' ?
      <LocalDrink color={'aTeal'} fontSize='large' sx={{iconSx}} /> : category === 'glassWashers' ?
        <LocalLaundryService color={'aTeal'} fontSize='large' sx={{iconSx}} /> : category === 'couplers' ?
        <AltRoute color={'aTeal'} fontSize='large' sx={{iconSx}} /> : category === 'flushWaterClean' ?
        <WaterDrop color={'aTeal'} fontSize='large' sx={{iconSx}} /> : category === 'fobs' ?
        <CoffeeMaker color={'aTeal'} fontSize='large' sx={{iconSx}} /> : category === 'sockets' ?
        <HeatPump color={'aTeal'} fontSize='large' sx={{iconSx}} /> : category === 'tapSeals' ?
        <DataSaverOff color={'aTeal'} fontSize='large' sx={{iconSx}} /> : category === 'remoteGrille' ?
        <GridOn color={'aTeal'} fontSize='large' sx={{iconSx}} /> : category === 'pythonCondition' ?
        <Cable color={'aTeal'} fontSize='large' sx={{iconSx}} /> : <Shower fontSize='large' sx={{iconSx}} />; 

  const subtitle = category === 'glassWashers' ?
    'Results by Glass-washer' : 'Results by Bar';

  /* if (data && !data.isOor && (data.percent && data.percent > 99.99)) {
    chartData[0] = {
      value: 0,
      label: 'Fail'
    };

    chartData[1] = {
      value: 100,
      label: 'Pass'
    };
  } */

  React.useEffect(() => {
    if (
      descriptionEl.current &&
      descriptionEl.current.clientHeight > 60
    ) {
      setLongDescContent(true);
      descriptionFrameEl.current?.classList.add('long');
    }
  }, [ descriptionEl, descriptionFrameEl ]);

  const onDescHover = () => {
    if (longDescContent) {
      setDescriptionOpen(true);
    }
  };

  const isOutOfRange = !!data && !!data.bars.map((barHygiene) => !barHygiene.hygieneStatus).length;

  return (
    <AvaniStyledCard
      raised={true}
      sx={{
        backgroundColor: isOutOfRange ?
          theme.colors.avani.lightMagenta : theme.colors.avani.lightTurc,
      }}
    >
      <div style={{
        display: 'flex',
      }}>
        
        <Typography
          variant={'h3'}
          color={theme.colors.avani.teal}
          sx={{
            flexGrow: 1,
          }}
        >
          {title}
        </Typography>
        {isOutOfRange && !!data?.media.length &&
          <AvaniButton
            loading={false}
            label={'View'}
            sx={{
              py: 0,
              px: 2,
              mr: '12px',
              lineHeight: 1,
              backgroundColor: 'transparent',
              color: isOutOfRange ? theme.colors.avani.magenta : theme.colors.avani.teal,
              border: '2px solid ' + (isOutOfRange ? theme.colors.avani.magenta : theme.colors.avani.teal),
              '&:hover' : {
                color: 'white',
                backgroundColor: isOutOfRange ? theme.colors.avani.magenta : theme.colors.avani.teal,
                border: '2px solid ' + (isOutOfRange ? theme.colors.avani.magenta : theme.colors.avani.teal),
              }
            }}
          />
        }
        <span>
          {headingIcon}
        </span>
      </div>
      {(['glassWare', 'glassWashers', 'nozzles', 'fobs', 'sockets', 'pythonCondition', 'remoteGrille' ].indexOf(category) > -1) && 
      <div
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end'
        }}
      >
        <div>
          <Typography
            variant={'h5'}
            fontSize={18}
            color={'primary'} sx={{
              py: '12px'
            }}
          >
            {subtitle}
          </Typography>
          <div
            style={{
              position: 'relative',
              width: '100%',
              minHeight: '80px',
              overflowX: 'auto'
            }}
          >
            {data && data.bars &&
            <List
              disablePadding
              sx={{
                width: (data.bars.length * 100) + 'px',
                display: 'block',
                minWidth: '100%',
                position: 'relative',
                right: 0,
              }}
            >
              {data.bars.map((item, ind) => {
                return (
                  <ListItem
                    disablePadding
                    key={ind}
                    sx={{
                      display: 'inline-block',
                      width: '100px',
                      minWidth: '80px',
                      float: 'left',
                      padding: '0 12px 0 0',
                    }}
                  >
                    <Avatar
                      sx={{
                        margin: '0 auto',
                        backgroundColor: !item.hygieneStatus ?
                          'rgba(230,0,126, 0.4)' : 'rgba(0,167,168, 0.4)'
                      }}
                    >
                      {(!!item.barName && !!item.hygieneStatus) ?
                        <Check sx={{
                          fill: theme.colors.avani.turc
                        }} />
                        :
                        !!item.barName &&
                          <Clear sx={{
                          fill: theme.colors.avani.magenta
                        }} />
                      }
                      {(!item.barName || !item.hasOwnProperty('hygieneStatus')) &&
                        <LocationOff sx={{
                          fill: 'white'
                        }} />
                      }
                    </Avatar>
                    <Typography
                      variant={'body2'}
                      color={!item.hygieneStatus ? theme.colors.avani.magenta : theme.colors.avani.turc}
                      sx={{
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      {!!item.barName ? item.barName : 'N/a'}
                    </Typography>
                  </ListItem>
                );
              })}
            </List>}

            {data &&
            <List
              disablePadding
              sx={{
                width: (data.bars.length * 80) + 'px',
                minWidth: '100%',
                display: 'block',
                position: 'relative',
                right: 0,
              }}
            >
              {data.bars.map((item, ind) => {
                return (
                  <ListItem
                    disablePadding
                    key={ind}
                    sx={{
                      display: 'inline-block',
                      width: 'auto',
                      minWidth: '80px',
                      float: 'left',
                      padding: '0 12px 0 0',
                    }}
                  >
                    <Avatar
                      sx={{
                        margin: '0 auto',
                        backgroundColor: !item.hygieneStatus ?
                          'rgba(230,0,126, 0.4)' : 'rgba(0,167,168, 0.4)'
                      }}
                    >
                      {!!item.hygieneStatus ?
                        <Check sx={{
                          fill: theme.colors.avani.turc
                        }} />
                        :
                        <Clear sx={{
                          fill: theme.colors.avani.magenta
                        }} />
                      }
                    </Avatar>
                    <Typography
                      variant={'body2'}
                      color={!item.hygieneStatus ? theme.colors.avani.magenta : theme.colors.avani.turc}
                      sx={{
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      {item.barName}
                    </Typography>
                  </ListItem>
                );
              })}
            </List>}
          </div>
        </div>
        {data && data.hygieneStatusVisitDatesTrend &&
        <div>
          <Divider
              sx={{
                borderBottom: '1px dashed ' + theme.colors.avani.turc,
                backgroundColor: 'transparent',
                display: 'block',
                width: '100%',
                paddingTop: '12px',
              }}
            />
          <Typography
            variant={'h5'}
            fontSize={18}
            color={'primary'} sx={{
              py: '12px'
            }}
          >
            {title} Hygiene Trend
          </Typography>
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '80px',
              overflow: 'auto'
            }}
          >
            
            <List
              disablePadding
              sx={{
                width: (data.hygieneStatusVisitDatesTrend.length * 80) + 'px',
                minWidth: '100%',
                display: 'block',
                position: 'relative',
              }}
            >
              {data.hygieneStatusVisitDatesTrend.map((item, ind) => {
                return (
                  <ListItem
                    disablePadding
                    key={ind}
                    sx={{
                      display: 'inline-block',
                      width: '80px',
                      float: 'left',
                    }}
                  >
                    <Avatar
                      sx={{
                        margin: '0 auto',
                        backgroundColor: !item.hygieneStatus ?
                          'rgba(230,0,126, 0.4)' : 'rgba(0,167,168, 0.4)'
                      }}
                    >
                      {!!item.hygieneStatus ?
                        <Check sx={{
                          fill: theme.colors.avani.turc
                        }} />
                        :
                        <Clear sx={{
                          fill: theme.colors.avani.magenta
                        }} />
                      }
                    </Avatar>
                    <Typography
                      variant={'body2'}
                      color={!item.hygieneStatus ? theme.colors.avani.magenta : theme.colors.avani.turc}
                      sx={{
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      {new Date(item.date).getDate() + '/' + new Date(item.date).getMonth()}
                    </Typography>
                  </ListItem>
                );
              })}
            </List>
          </div>
        </div>
        }
      </div>
      }
      {(['tapSeals', 'flushWaterClean', 'couplers'].indexOf(category) > -1) &&
        <Box
          sx={{
            flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end',
          }}
        >
          {/* {(data?.isOor !== null) && data?.percent &&
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <AvaniHygienePieChart
              key={category}
              data={chartData}
              pass={!data.isOor}
              total={data.percent}
            />
          </Box>
          } */}

          {/* {data?.description &&
          <Box>
            <Typography
              variant={'h5'}
              fontSize={18}
              color={'primary'} sx={{
                py: '12px'
              }}
            >
              Description
            </Typography>
            <OverflowingTextContent
              ref={descriptionFrameEl}
              onTouchEnd={onDescHover}
              onMouseOver={onDescHover}
              sx={{
                [`:after`]: {
                  fontFamily: theme.typography.body2,
                  backgroundColor: !!data.isOor ?
            theme.colors.avani.lightMagenta : theme.colors.avani.lightTurc
                }
              }}
            >
              <Typography
                ref={descriptionEl}
                fontSize={14}
                variant={'body2'}
                minHeight={'60px'}
                dangerouslySetInnerHTML={{ __html: data.description }}
              ></Typography>
              <Dialog open={descriptionOpen} keepMounted
                slotProps={{backdrop: {
                  style: {
                    backgroundColor: alpha(darken('#000C57', 0.4), 0.2),
                    backdropFilter: 'blur(2px)',
                  }
                },}}
                onClose={() => setDescriptionOpen(false)}
              >
                <Typography
                  fontSize={18}
                  variant={'body2'}
                  sx={{
                    padding: 2
                  }}
                  dangerouslySetInnerHTML={{ __html: data.description }}
                >
                </Typography>
              </Dialog>
            </OverflowingTextContent>
          </Box>
          } */}
          {!!data?.media.length &&
          <Box>
            <Typography
              variant={'h5'}
              fontSize={18}
              color={'primary'} sx={{
                py: '12px'
              }}
            >
              Multimedia
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-evenly'
              }}
            >
              {data.media.map((media, ind) => {
                return (
                  <ImageViewer
                    key={media.fileName+ind}
                    index={ind}
                    imageSrc={data.media.map((m) => m.contentUrl ? m.contentUrl : '')}
                    avatarWidth={80}
                    avatarHeight={80}

                  />
                );
              })
              }
            </Box>
          </Box>
          }  
        </Box>
      }
    </AvaniStyledCard>
  );
};
