import { Loader } from './loader.routes';
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';
import SidebarLayout from 'src/layouts/SidebarLayout';
import ProtectedRoute from './Components/ProtectedRoute';
import { LegalsProvider } from '../../contexts/LegalContext';

const LegalList = Loader(lazy(() => import('src/content/pages/Legals/LegalsList')));

const LegalsCreate = Loader(lazy(() => import('src/content/pages/Legals/LegalCreate')));

const LegalUpdate = Loader(lazy(() => import('src/content/pages/Legals/LegalUpdate')));

export const createLegalsRoutes = () => ({
  path: 'settings/legals',
  element: <SidebarLayout />,
  children: [
    {
      path: '',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <Navigate to="/settings/legals/list" replace />
        </ProtectedRoute>
      )
    },
    {
      path: 'create',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['systemSuperAdmin', 'avaniAdmin']}>
          <LegalsProvider>
            <LegalsCreate />
          </LegalsProvider>
        </ProtectedRoute>
      )
    },
    {
      path: 'list',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <LegalsProvider>
            <LegalList />
          </LegalsProvider>
        </ProtectedRoute>
      )
    },
    {
      path: `:legalId/update`,
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['systemSuperAdmin', 'avaniAdmin']}>
          <LegalsProvider>
            <LegalUpdate />
          </LegalsProvider>
        </ProtectedRoute>
      )
    }
  ]
});
