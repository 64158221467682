import { Backdrop } from '@mui/material';
import SimpleLogo from 'src/layouts/SidebarLayout/Header/Logo/SimpleLogo';

function SuspenseLoader() {
  return (
    <Backdrop open={true}>
      <SimpleLogo infinite />
    </Backdrop>
  );
}

export default SuspenseLoader;
