import { DateTime } from 'luxon';

export const handleTemperatureIsRange = (
  temperature?: number | null,
  minTemperature?: number | null,
  maxTemperature?: number | null
) => {
  if (temperature == null) return null;
  if (minTemperature != null && temperature < minTemperature) return -1;
  if (maxTemperature != null && temperature > maxTemperature) return 1;
  return 0;
};
export const readableDate = (date: Date | string, long = false) => {
  if (typeof date === 'string') {
    return long ? DateTime.fromISO(date).toFormat('dd/MM/yyyy') : DateTime.fromISO(date).toFormat('dd/MM');
  } else {
    return long ? DateTime.fromJSDate(date).toFormat('dd/MM/yyyy') : DateTime.fromJSDate(date).toFormat('dd/MM');
  }
};
