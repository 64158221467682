import * as React from 'react';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';

import {
  ChartsClipPath,
  ChartsGrid,
  ChartsLegend,
  ChartsReferenceLine,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  LineSeriesType,
  MarkPlot,
  ResponsiveChartContainer,
  ScatterPlot,
  ScatterSeriesType,
  useDrawingArea,
  useYScale,
} from '@mui/x-charts';

import type { TemperatureTrendData } from 'src/types/Dashboard';
import { TemperatureDetailsType } from 'src/models/types/DashboardBlock/temperatures.types';

interface AvaniTemperatureTrendChartProps {
  data: {name: string, data: number | null}[],
  dates: Date[],
  range?: TemperatureDetailsType,
  additionalData?: TemperatureTrendData[],
};

type ChartsSeries = ScatterSeriesType | LineSeriesType;

const Range:React.FC<{range: TemperatureDetailsType}> = ({
  range,
}) => {
  const theme = useTheme();
  const degScale = useYScale('degAxis');
  const { left, width } = useDrawingArea();

  const rangeTop = range.maxTemperature ? range.maxTemperature : 100;
  const rangeBottom = range.minTemperature ? range.minTemperature : -100;

  return (
    <React.Fragment>
      {rangeTop !== 100 && rangeBottom !== -100 &&
      <path
        fill={'transparent'}
        d={`M ${left} ${degScale(rangeTop)} l ${width} 0 l 0 ${degScale(rangeBottom) - degScale(rangeTop)} l -${width} 0 Z`}
        style={{
          fill: theme.colors.avani.teal,
          fillOpacity: .2
        }}
      />
      }

      {range.minTemperature &&
      <ChartsReferenceLine
        key={range.name + range.type}
        y={range.minTemperature}
        lineStyle={{ stroke: theme.colors.avani.turc }}
        labelStyle={{ fontSize: '10' }}
        label={range.name}
        labelAlign="end"
        spacing={{x: 10, y: 10}}
      />
      }
      {range.maxTemperature &&
      <ChartsReferenceLine
        key={range.name + range.type + '_max'}
        y={range.maxTemperature}
        lineStyle={{ stroke: theme.colors.avani.turc }}
      />
      }
    </React.Fragment>
  ); 
};

const chartsReadyData = (incomingData: any[]):ChartsSeries[] => {
  const output : ChartsSeries[] = incomingData.map((serie, index) => ({
      label: serie.name,
      data: [!!serie.data ? serie.data : null],
      type: 'line',
      id: serie.name.split(' ').join() + index,
      valueFormatter: (value:any, context:any) => {
        if (!!value)
          return value + ' °C';
        else return 'No data';
      }
  }));

  return output;
};

export const AvaniTemperatureTrendChart: React.FC<AvaniTemperatureTrendChartProps> = ({
  data,
  dates,
  range,
  additionalData,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const mainData = chartsReadyData(data);
  /* const AdditionalMarksScatterData = (incomingData: TemperatureTrendData[], mainData: any[]):ChartsSeries[] => {
    const output:any[] = incomingData;
    const base:any = {
      label: incomingData[0].name || 'Dispense temp',
      data: incomingData[0].data,
      type: 'scatter',
      markerSize: 8,
      color: theme.colors.avani.teal,
      id: incomingData[0].name+'dispenseTemp',
      xAxisId: 'datesAxis',
      yAxisId: 'dispenseAxis',
      valueFormatter: (v: any, c: any) => {
        return v.y + ' °C';
      }
    };

    output[0].yAxisId = 'dispenseAxis';
    const updatedData = incomingData[0] && incomingData[0].data?.map((data: any, ind:number) => {
      const refX = dates[ind];

      return {
        id: (ind + (data || 0)).toString(),
        x: refX,
        y: data.y? data.y : data,
        color: data.color ? data.color : theme.colors.avani.teal,
        yAxisId: 'dispenseAxis',
        yAxisKey: 'dispenseAxis',
      };
    });
  
    base.data = updatedData;
  
    output[0] = base;
  
    return output;
  }; */

  const chartSeriesData = /* additionalData ? AdditionalMarksScatterData(additionalData, mainData) : */ mainData;

  const chartColors = [
    theme.palette.aTeal.main,
    theme.palette.aTeal.main,
    theme.palette.aTeal.main,
    theme.palette.aTeal.main,
  ];

  const chartRangeColors = () => {
    return [
      theme.colors.avani.magenta,
      theme.colors.avani.teal,
      theme.colors.avani.magenta,
    ]
  };

  const rangesToThresholdData = () => {
    return range && range.minTemperature && range.maxTemperature ? [range.minTemperature, range.maxTemperature] : [];
  };

  return (
    <ResponsiveChartContainer
      xAxis={[
        {
          id: 'datesAxis',
          scaleType: 'band',
          data: dates,
          valueFormatter(v, c) {
            const d = new Date(v);
            return d.getDate() + '/' + d.getMonth() + '/' + d.getFullYear();
          }
        }
      ]}
      yAxis={[
        {
          id: 'degAxis',
          scaleType: 'linear',
          data: mainData,
          colorMap: {
            type: 'piecewise',
            thresholds: rangesToThresholdData(),
            colors: chartRangeColors(),
          },
          valueFormatter(value, context) {
            return value + '°C';
          },
        }/* ,
        {
          id: 'dispenseAxis',
          scaleType: 'point',
          colorMap: undefined,
          data: AdditionalMarksScatterData(additionalData || [], mainData),
          valueFormatter(value, context) {
            return value + '°C';
          },
        } */
      ]}
      colors={chartColors}
      series={[...mainData/* , ...AdditionalMarksScatterData(additionalData || [], mainData) */]}
      margin={{
        top: isSmallScreen ? data.length * 36 : 64,
      }}
      sx={{
        '& .MuiLineElement-root': {
          strokeWidth: 2,
        },
        '& .MuiChartsGrid-horizontalLine': {
          strokeWidth: 1,
        },
        ['.MuiChartsReferenceLine-label']: {
          width: 40,
        }
      }}
    >
      <g clipPath={`url(#tempChartFrame`}>
        <LinePlot />
        <MarkPlot />
        <ScatterPlot
          axisHighlight={{
            x: 'band',
            y: 'none'
          }}
        />
      </g>
      {range && <Range range={range} />}
      <ChartsClipPath id={'tempChartFrame'} />
      <ChartsGrid horizontal />
      <ChartsXAxis />
      <ChartsYAxis />
      <ChartsTooltip />
      <ChartsLegend
        position={{
          horizontal: 'middle',
          vertical: 'top',
        }}
        direction={isSmallScreen ? 'column': 'row'}
        padding={0}
      />
    </ResponsiveChartContainer>
  );
};
