import { createContext, FC, useState } from 'react';
import { VisitDashboardCommentModel } from 'src/models/types/comments.types';
import { GeneralBlock } from 'src/models/types/DashboardBlock/general.types';
import { PreCleanBlock } from 'src/models/types/DashboardBlock/pre-clean.types';
import { TemperaturesBlock } from 'src/models/types/DashboardBlock/temperatures.types';
import { GlassWashersBlock } from 'src/models/types/DashboardBlock/glass-washers.types';
import { HygieneBlock } from 'src/models/types/DashboardBlock/hygiene.types';
import { WastageBlock } from 'src/models/types/DashboardBlock/wastage.types';
import { FaultsBlock } from 'src/models/types/DashboardBlock/faults.types';

interface IVisitDashboardContext {
  dashboardsUpdate: boolean;
  toggleDashboardsUpdate: () => void;
  comments: VisitDashboardCommentModel[] | null,
  setComments: (v: VisitDashboardCommentModel[]) => void;
  generalBlock: GeneralBlock | null,
  setGeneralBlock: (v: GeneralBlock) => void;
  preCleanBlock: PreCleanBlock | null;
  setPreCleanBlock: (v: PreCleanBlock) => void;
  temperaturesWithTrendsBlock: TemperaturesBlock | null;
  setTemperaturesWithTrendsBlock: (v: TemperaturesBlock) => void;
  glassWashersBlock: GlassWashersBlock | null;
  setGlassWashersBlock: (v: GlassWashersBlock) => void;
  hygieneBlock: HygieneBlock | null;
  setHygieneBlock: (v: HygieneBlock) => void;
  wastageBlockWithGraph: WastageBlock | null;
  setWastageBlockWithGraph: (v: WastageBlock) => void;
  faultsBlock: FaultsBlock | null;
  setFaultsBlock: (v: FaultsBlock) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
}

export const VisitDashboardContext = createContext<IVisitDashboardContext>({} as IVisitDashboardContext);

export const VisitDashboardProvider: FC = ({ children }) => {
  const [dashboardsUpdate, setDashboardsUpdate] = useState(false);
  const [ comments, setComments ] = useState<VisitDashboardCommentModel[] | null>(null);
  const [ generalBlock, setGeneralBlock ] = useState<GeneralBlock | null>(null);
  const [ preCleanBlock, setPreCleanBlock ]= useState<PreCleanBlock | null>(null);
  const [ temperaturesWithTrendsBlock, setTemperaturesWithTrendsBlock ] = useState<TemperaturesBlock | null>(null);
  const [ glassWashersBlock, setGlassWashersBlock ] = useState<GlassWashersBlock | null>(null);
  const [ hygieneBlock, setHygieneBlock ] = useState<HygieneBlock | null>(null);
  const [ wastageBlockWithGraph, setWastageBlockWithGraph ] = useState<WastageBlock | null>(null);
  const [ faultsBlock, setFaultsBlock ] = useState<FaultsBlock | null>(null);
  const [ loading, setLoading ] = useState(false);

  const toggleUpdate = (): void => setDashboardsUpdate(!dashboardsUpdate);

  return (
    <VisitDashboardContext.Provider
      value={{
        dashboardsUpdate,
        toggleDashboardsUpdate: toggleUpdate,
        comments,
        setComments,
        generalBlock,
        setGeneralBlock,
        preCleanBlock,
        setPreCleanBlock,
        temperaturesWithTrendsBlock,
        setTemperaturesWithTrendsBlock,
        glassWashersBlock,
        setGlassWashersBlock,
        hygieneBlock,
        setHygieneBlock,
        wastageBlockWithGraph,
        setWastageBlockWithGraph,
        faultsBlock,
        setFaultsBlock,
        loading,
        setLoading
      }}
    >
      {children}
    </VisitDashboardContext.Provider>
  );
};
