import { createContext, FC, useContext, useState } from 'react';
import { ResponseAWSUserParams, UserModel } from '../models/types/users.types';
import { getUserInfo } from 'src/models/api-calls/users.api';
import { MediaModel } from 'src/models/types/medias.types';
import { Storage } from 'aws-amplify';

interface IAuthContext {
  authUpdate: boolean;
  toggleAuthUpdate: () => void;
  awsAuth: ResponseAWSUserParams | undefined;
  setAwsAuth: (val: ResponseAWSUserParams | undefined) => void;
  auth: UserModel | undefined;
  setProfilePicture: (val: MediaModel | null) => void;
  profilePicture: MediaModel | null;
  setAuth: (val: UserModel | undefined) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
  refreshUserInfo: () => void;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: FC = ({ children }) => {
  const [authUpdate, setAuthUpdate] = useState(false);
  const [auth, setAuth] = useState<UserModel | undefined>(undefined);
  const [awsAuth, setAwsAuth] = useState<ResponseAWSUserParams | undefined>(undefined);
  const [profilePicture, setProfilePicture] = useState<MediaModel | null>(null);
  const [loading, setLoading] = useState(false);
  const toggleUpdate = (): void => setAuthUpdate(!authUpdate);
  const refreshUserInfo = async (): Promise<void> => {
    const userInfo = await getUserInfo(auth?.id as string);
    setAuth(userInfo);
    if (auth && userInfo.avatar !== null && profilePicture === null) {
      const customPrefix = { public: 'public/media/' };
      const imageLink = await Storage.get(`avatars/${userInfo.id}/${userInfo.avatar.id}.${userInfo.avatar.extension}`, {
        contentType: `image/${userInfo.avatar.fileType}`,
        customPrefix
      });
      setProfilePicture({
        fileType: userInfo.avatar.fileType,
        fileName: userInfo.avatar.fileName,
        extension: userInfo.avatar.extension,
        id: userInfo.avatar.id,
        contentUrl: imageLink,
        user: userInfo
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authUpdate,
        profilePicture,
        setProfilePicture,
        toggleAuthUpdate: toggleUpdate,
        refreshUserInfo,
        auth,
        setAuth,
        loading,
        awsAuth,
        setAwsAuth,
        setLoading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuthContext() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext muse be used within a AuthContextProvider');
  }
  return context;
}
