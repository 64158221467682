import { styled } from '@mui/material';
import { forwardRef, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  props?: {};
  infinite?: boolean;
  ref: ReactNode;
}

const LogoWrapper = styled(Link)(({ theme }) => ({
  [`& `]: {
    color: theme.palette.text.primary,
    width: '120px',
    height: '167px'
  },
  [`&.infinite .rotate`]: {
    animation: 'logoAnimation 1.75s linear infinite'
  },
  [`& .masked`]: {
    maskImage: 'url("/static/images/logo/logo.svg")',
    maskRepeat: 'no-repeat',
    position: 'relative'
  },
  [`& .rotate`]: {
    background: 'linear-gradient(#005665 , #00a7a8)',
    backgroundPosition: 'center',
    animation: 'logoAnimation 1.75s linear 1'
  }
}));

const SimpleLogo = forwardRef<HTMLAnchorElement, Props>(({ infinite, ...props }, ref) => {
  return (
    <LogoWrapper
      to="/"
      {...props}
      ref={ref}
      className={infinite ? 'infinite' : ''}
      style={{
        width: '120px',
        height: '167px',
        display: 'block',
        margin: '0 auto'
      }}
    >
      <div
        className="masked"
        style={{
          width: '120px',
          height: '167px'
        }}
      >
        <div
          className="rotate"
          style={{
            width: '334px',
            height: '334px',
            position: 'absolute',
            top: 0,
            left: '-114px'
          }}
        ></div>
      </div>
    </LogoWrapper>
  );
});

export default SimpleLogo;
