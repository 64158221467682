type SidebarLogoProps = {
  full: boolean;
};

import { styled, Box, useTheme } from '@mui/material';

const SidebarLogoWrapper = styled(Box)(() => ({
  [`& `]: {
    padding: '24px 26px 19px 26px',
    background: 'linear-gradient( #00a7a8, #005665 )',
    transitionProperty: 'padding',
    transitionDuration: '300ms',
    minHeight: '160px',
    display: 'flex',
    justifyContent: 'center'
  },
  [`& .masked`]: {
    backgroundImage: 'url("/static/images/logo/logo_small.svg")',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    width: '25px',
    height: '36px',
    transitionProperty: 'width, height, background',
    transitionDuration: '300ms'
  },
  [`& .masked.full`]: {
    width: '125px',
    height: '52.5px',
    backgroundImage: 'url("/static/images/logo/full_logo_small.svg")',
    transitionProperty: 'width, height, background',
    transitionDuration: '300ms'
  },
  [`&.wide`]: {
    padding: '50px 63px 0 63px',
    transitionProperty: 'padding',
    transitionDuration: '300ms'
  }
}));

function SidebarLogo<SidebarLogoProps>({ full = false }) {
  return (
    <>
      <SidebarLogoWrapper className={full ? 'wide' : ''}>
        <div className={'masked' + (full ? ' full' : '')}></div>
      </SidebarLogoWrapper>
    </>
  );
}
export default SidebarLogo;
