import { createContext, FC, useState } from 'react';
import { BrewerModel } from '../models/types/brewers.types';
import { BrewerSortingFields, Order } from '../models/types/common.types';

interface IBrewersContext {
  brewersUpdate: boolean;
  toggleBrewersUpdate: () => void;
  brewers: BrewerModel[];
  setBrewers: (val: BrewerModel[]) => void;
  page: number;
  setPage: (val: number) => void;
  limit: number;
  setLimit: (val: number) => void;
  count: number;
  setCount: (val: number) => void;
  order: Order;
  setOrder: (val: Order) => void;
  orderBy: BrewerSortingFields;
  setOrderBy: (val: BrewerSortingFields) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
}

export const BrewersContext = createContext<IBrewersContext>({} as IBrewersContext);

export const BrewersProvider: FC = ({ children }) => {
  const [brewersUpdate, setBrewersUpdate] = useState(false);
  const [brewers, setBrewers] = useState<BrewerModel[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<BrewerSortingFields>('name');
  const [loading, setLoading] = useState(false);
  const toggleBarUpdate = (): void => setBrewersUpdate(!brewersUpdate);

  return (
    <BrewersContext.Provider
      value={{
        brewersUpdate,
        toggleBrewersUpdate: toggleBarUpdate,
        brewers,
        setBrewers,
        page,
        setPage,
        limit,
        setLimit,
        count,
        setCount,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        loading,
        setLoading
      }}
    >
      {children}
    </BrewersContext.Provider>
  );
};
