import * as React from 'react';

import {
  Avatar,
  Box,
  Typography,
  useTheme,
} from '@mui/material';

import { AvaniCard } from 'src/components/Cards/AvaniCard';

import type { TotalServicedData } from 'src/types/Dashboard';

type AvaniTotalServicedCardProps = {
  data?: TotalServicedData,
}

export const AvaniTotalServicedCard: React.FC<AvaniTotalServicedCardProps> = ({
  data,
}) => {
  const theme = useTheme();

  return (
    <AvaniCard padded sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      }}
    >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}>
          {data && data.taps &&
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Typography
              variant={'h3'}
              component={'div'}
              fontSize={20}
              textAlign={'center'}
              color={theme.colors.avani.teal}
            >
              Total Taps Serviced
            </Typography>
            <Avatar
              sx={{
                bgcolor: theme.colors.avani.magenta,
                width: 64,
                height: 64,
                paddingTop: '3px',
                fontSize: '24px'
              }}
            >
              {data.taps}
            </Avatar>
          </Box>}
          {data && data.fobs &&
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 2,
            }}
          >
            <Typography
              variant={'h3'}
              component={'div'}
              fontSize={20}
              textAlign={'center'}
              color={theme.colors.avani.teal}
            >
              Total FOBs Serviced
            </Typography>
            <Avatar
              sx={{
                bgcolor: theme.colors.avani.aYellow,
                width: 64,
                height: 64,
                paddingTop: '3px',
                fontSize: '24px',
              }}
            >
              {data.fobs}
            </Avatar>
          </Box>}
        </div>
      </AvaniCard>
  );
};
