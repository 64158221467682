import * as React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';

import {
  ExpandMore,
} from '@mui/icons-material';

import { AvaniCard } from 'src/components/Cards/AvaniCard';
import { AvaniPaperCard } from 'src/components/Cards/AvaniPaperCard';
import { AvaniTempCardDisplay } from 'src/components/Cards/AvaniTempCardDisplay';

import { TemperatureTrends } from 'src/components/Tabs/TemperatureTrends';
import { AvaniScrollable } from '../Utils/AvaniScrollable';

import { TemperatureDetailsType } from 'src/models/types/DashboardBlock/temperatures.types';
import { VisitDashboardCommentModel } from 'src/models/types/comments.types';

type TemperatureBlockProps = {
  entities?: TemperatureDetailsType[],
  comment?: VisitDashboardCommentModel,
  dispenseTempsData?: any[],
};

export const TemperatureBlock:React.FC<TemperatureBlockProps> = ({
  entities,
  comment,
  dispenseTempsData,
}) => {
  const theme = useTheme();

  const tempDescForEntity = (entity: TemperatureDetailsType): string => {
    const outputNormal = 'Normal';
    const outputOutOfRange = 'Out of Range';

    if (!!entity.temperature) {
      if (!!entity.minTemperature && !!entity.maxTemperature) {
        return (entity.temperature > entity.maxTemperature || entity.temperature < entity.minTemperature) ?
          outputOutOfRange : outputNormal;
      } else {
        return outputNormal;
      }
    } else {
      return '';
    }
  }

  const isTempOutOfRange = (entity: TemperatureDetailsType): boolean => {
    if (!!entity.temperature) {
      if (!!entity.minTemperature && !!entity.maxTemperature) {
        return (entity.temperature > entity.maxTemperature || entity.temperature < entity.minTemperature);
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <div style={{marginTop: '36px'}}>
    <Accordion
      defaultExpanded
      sx={{
        background: 'transparent',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore fontSize={'large'} />}
        sx={{
          padding: 0,
          borderRadius: 0,
        }}
      >
        <Typography variant={'h2'} sx={{
          color: theme.colors.avani.teal,
        }}>
          Temperature
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: 0
        }}
      >
        <Grid container columns={10} spacing={1} >
          {!!comment && 
          <Grid item
            xs={10}
          >
            <AvaniPaperCard
              {...comment}
            />
          </Grid>}

          {entities &&
          <>
          <Grid item xs={10}>
            <Box
              sx={{
                paddingBottom: 1
              }}
            >
              <AvaniScrollable
                horizontal
                height={146}
                width={entities.length * 249}
              >
              {entities.map((vb, ind) => {
                return (
                  <AvaniTempCardDisplay
                    key={ind}
                    id={ind}
                    temp={vb.temperature}
                    title={vb.name}
                    desc={tempDescForEntity(vb)}
                    isOutOfRange={isTempOutOfRange(vb)}
                    sx={{
                      width: '240px',
                      float: 'left',
                      display: 'block',
                      marginRight: 1,
                      marginBottom: 1,
                      cursor: 'pointer',

                      ['span:last-child &']: {
                        marginRight: 0,
                      },
                      ['span:first-of-type &']: {
                        marginLeft: 1,
                      }
                    }}
                  />
                )}
              )}
              </AvaniScrollable>
            </Box>
          </Grid>
          <Grid
            item
            xs={10}
            sx={{mt: 1, height: '450px'}}
          >
            <AvaniCard grey title={'Temperature Trends'}>
              <TemperatureTrends
                devices={ entities }
                dispenseData={ dispenseTempsData && dispenseTempsData }
              />
            </AvaniCard>
          </Grid>
          </>
          }
        </Grid>
      </AccordionDetails>
    </Accordion>
    </div>
  )
};
