import * as React from 'react';

import { List, ListItem } from '@mui/material';

import { AvaniFaultCard } from 'src/components/Cards/AvaniFaultCard';

import { FaultData } from 'src/models/types/DashboardBlock/faults.types';

interface AvaniFaultsProps {
  data: FaultData[],
  faultWidth: number,
}

export const AvaniFaults: React.FC<AvaniFaultsProps> = ({
  data,
  faultWidth,
}) => {
  return (
    <List
      disablePadding
      sx={{
        display: 'block',
        width: (data.length * (faultWidth + 12)) + 'px'
      }}
    >
      {data.map((fault, i) => {
        return (
          <ListItem
            disablePadding
            key={i}
            sx={{
              display: 'inline-block',
              width: `${faultWidth}px`,
              marginRight: '12px',
            }}
          >
            <AvaniFaultCard
              {...fault}
            />
          </ListItem>
        );
      })}
    </List>
  );
};
