import * as React from 'react';

import { Grid, Typography, useTheme } from '@mui/material';

import { AvaniWastagePieChart } from '../Charts/AvaniWastagePieChart';
import { AvaniWastageBarChart } from '../Charts/AvaniWastageBarChart';

interface VenueWastageEfficiencyScoreProps {
  esLines: {
    empty: number,
    partial: number,
    full: number, 
  },
  esTotal: number,
  esSaved: number,
  esPercent: number,
}

export const VenueWastageEfficiencyScore: React.FC<VenueWastageEfficiencyScoreProps> = ({
  esLines,
  esTotal,
  esSaved,
  esPercent,
}) => {
  const theme = useTheme();

  const chartData = [
    {
      value: esPercent,
      label: 'A',
    },
    {
      value: 100 - esPercent,
      label: 'B',
    }
  ];

  return (
    <Grid container columns={2}
      sx={{
        marginTop: 2
      }}
    >
      <Grid item
        xs={1}
        sx={{
          overflow: 'hidden',
          borderRight: '1px dashed ' + theme.colors.avani.teal,
          paddingTop: 1.3,
        }}
      >
        <AvaniWastagePieChart
          data={chartData}
          total={esPercent}
        />
        <Typography
          variant={'body2'}
          color={'aTeal'}
          fontSize={16}
          fontWeight={'bold'}
          align={'center'}
        >
          {esSaved} / {esTotal} pints saved
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <AvaniWastageBarChart
          data={esLines}
        />
      </Grid>
    </Grid>
  );
}

