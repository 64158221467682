import { Loader } from './loader.routes';
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';
import SidebarLayout from 'src/layouts/SidebarLayout';
import { BrewersProvider } from 'src/contexts/BrewersContext';
import ProtectedRoute from './Components/ProtectedRoute';

const BrewersList = Loader(lazy(() => import('src/content/pages/Brewers/BrewersList')));

export const createBrewersRoutes = () => ({
  path: 'brewers',
  element: <SidebarLayout />,
  children: [
    {
      path: '',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <Navigate to="/brewers/list" replace />
        </ProtectedRoute>
      )
    },
    {
      path: 'list',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <BrewersProvider>
            <BrewersList />
          </BrewersProvider>
        </ProtectedRoute>
      )
    }
  ]
});
