import * as React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import {
  ExpandMore,
} from '@mui/icons-material';

import { AvaniPaperCard } from 'src/components/Cards/AvaniPaperCard';
import { AvaniFaults } from 'src/components/DataDisplay/AvaniFaults';
import { AvaniScrollable } from 'src/components/Utils/AvaniScrollable';

import type {
  LastUpdated,
} from 'src/types/Dashboard';

import { FaultData } from 'src/models/types/DashboardBlock/faults.types';
import { VisitDashboardCommentModel } from 'src/models/types/comments.types';

type FaultsBlockProps = {
  faults: FaultData[],
  comment?: VisitDashboardCommentModel,
};

export const FaultsBlock:React.FC<FaultsBlockProps> = ({
  faults,
  comment,
}) => {
  const theme = useTheme();
  const frame = React.useRef<HTMLElement | null>(null);
  const [ availableWidth, setAvailableWidth ] = React.useState<number | null>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    if (frame && frame.current) {
      setAvailableWidth(frame.current.clientWidth);
    }
  }, [frame]);

  return (
    <div style={{marginTop: '36px'}}>
      <Accordion
        defaultExpanded
        sx={{
          background: 'transparent'
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore fontSize={'large'} />}
          sx={{
            padding: 0,
            borderRadius: 0,
          }}
        >
          <Typography variant={'h2'} sx={{
            color: theme.colors.avani.teal,
          }}>
            Faults
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: 0,
          }}
        >
          <Grid container columns={1} spacing={1}>
            {!!comment &&
            <Grid item
              xs={10}
            >
              <AvaniPaperCard
                {...comment}
              />
            </Grid>
            }
            <Grid item xs={10}>
              <Box ref={frame} sx={{
                margin: 0,
                padding: 0,
              }}>
                {!!availableWidth &&
                <AvaniScrollable
                  width={availableWidth}
                  height={isMobile ? 742 : 695}
                  key={'faults'}
                  horizontal
                >
                  <AvaniFaults
                    data={faults}
                    faultWidth={isMobile ? availableWidth : 390}
                  />
                </AvaniScrollable>
                }
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  )
};
