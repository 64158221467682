import { createContext, FC, useState } from 'react';
import { RecentVisitDto, UnresolvedFaultsDto } from '../models/types/dashboard.types';

interface IClientDashboardsContext {
  dashboardsUpdate: boolean;
  toggleDashboardsUpdate: () => void;
  totalBeersSaved: number;
  setTotalBeersSaved: (val: number) => void;
  clientBeersSaved: number;
  setClientBeersSaved: (val: number) => void;
  recentVisits: RecentVisitDto[];
  setRecentVisits: (val: RecentVisitDto[]) => void;
  unresolvedFaults: UnresolvedFaultsDto[];
  setUnresolvedFaults: (val: UnresolvedFaultsDto[]) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
}

export const ClientDashboardsContext = createContext<IClientDashboardsContext>({} as IClientDashboardsContext);

export const ClientDashboardsProvider: FC = ({ children }) => {
  const [dashboardsUpdate, setDashboardsUpdate] = useState(false);
  const [totalBeersSaved, setTotalBeersSaved] = useState(0);
  const [clientBeersSaved, setClientBeersSaved] = useState(0);
  const [recentVisits, setRecentVisits] = useState<RecentVisitDto[]>([]);
  const [unresolvedFaults, setUnresolvedFaults] = useState<UnresolvedFaultsDto[]>([]);
  const [loading, setLoading] = useState(false);

  const toggleUpdate = (): void => setDashboardsUpdate(!dashboardsUpdate);

  return (
    <ClientDashboardsContext.Provider
      value={{
        dashboardsUpdate,
        toggleDashboardsUpdate: toggleUpdate,
        totalBeersSaved,
        setTotalBeersSaved,
        clientBeersSaved,
        setClientBeersSaved,
        recentVisits,
        setRecentVisits,
        unresolvedFaults,
        setUnresolvedFaults,
        loading,
        setLoading
      }}
    >
      {children}
    </ClientDashboardsContext.Provider>
  );
};
