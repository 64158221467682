import { createContext, FC, useState } from 'react';
import { FaultsDashboardData, LastVisitFaultsType } from '../models/types/faults-dashboard.types';

interface IFaultsDashboardsContext {
  dashboardsUpdate: boolean;
  toggleDashboardsUpdate: () => void;
  totalUnresolved: number;
  setTotalUnresolved: (val: number) => void;
  lastVisitFaults: LastVisitFaultsType;
  setLastVisitFaults: (val: LastVisitFaultsType) => void;
  faults: FaultsDashboardData[];
  setFaults: (val: FaultsDashboardData[]) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
}

export const FaultsDashboardsContext = createContext<IFaultsDashboardsContext>({} as IFaultsDashboardsContext);

export const FaultsDashboardsProvider: FC = ({ children }) => {
  const [dashboardsUpdate, setDashboardsUpdate] = useState(false);
  const [totalUnresolved, setTotalUnresolved] = useState(0);
  const [lastVisitFaults, setLastVisitFaults] = useState<LastVisitFaultsType>({ critical: 0, regular: 0 });
  const [faults, setFaults] = useState<FaultsDashboardData[]>([]);
  const [loading, setLoading] = useState(false);

  const toggleUpdate = (): void => setDashboardsUpdate(!dashboardsUpdate);

  return (
    <FaultsDashboardsContext.Provider
      value={{
        dashboardsUpdate,
        toggleDashboardsUpdate: toggleUpdate,
        totalUnresolved,
        setTotalUnresolved,
        lastVisitFaults,
        setLastVisitFaults,
        faults,
        setFaults,
        loading,
        setLoading
      }}
    >
      {children}
    </FaultsDashboardsContext.Provider>
  );
};
