import * as React from 'react';
import {
  Box,
  Tab,
  Tabs,

  styled,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import { AvaniTemperatureTrendChart } from 'src/components/Charts/AvaniTemperatureTrendChart';

import type { TemperatureTrendData } from 'src/types/Dashboard';
import { TemperatureDetailsType } from 'src/models/types/DashboardBlock/temperatures.types';

interface AvaniTemperatureTrendsProps {
  devices: TemperatureDetailsType[],
  dispenseData?: TemperatureTrendData[],
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const AvaniTab = styled(Tab)(({ theme }) => `
  padding: 16px;
  background-color: white;
  color: ${theme.colors.avani.magenta};
  border: 2px solid ${theme.colors.avani.magenta};
  border-radius: 16px;

  &:hover {
    color: ${theme.colors.avani.magenta};
  }

  &.Mui-selected {
    color: white;
    background-color: ${theme.colors.avani.magenta};
  }
`);

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ height: '300px' }}>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const sortData:any = (devicesData: TemperatureDetailsType[]) => {
  const deviceTypes: string[] = [];
  const sortedDevices: any = {};

  devicesData.forEach((device, ind) => {
    if (deviceTypes.indexOf(device.type) < 0) {
      deviceTypes.push(device.type);
      sortedDevices[device.type] = [device];
    } else {
      sortedDevices[device.type].push(device);
    }
  });

  return {
    deviceTypes: deviceTypes,
    devices: sortedDevices,
  };
};

export const TemperatureTrends: React.FC<AvaniTemperatureTrendsProps> = ({
  devices,
  dispenseData,
}) => {
  const theme = useTheme();

  const [ activeTab, setActiveTab ] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  const currentDeviceData:any = sortData(devices);

  return (
    <>
      <Tabs value={activeTab} onChange={handleChange}
        sx={{
          margin: 2,

          [`.MuiTabs-indicator`]: {
            backgroundColor: 'transparent',
            border: 'none',
            boxShadow: 'none',
          }
        }}
      >
        {devices.filter((device) => device.type !== 'Bar').map((device, ind) => {
          return (
            <AvaniTab key={ind + device.name} label={device.name} {...a11yProps(ind)} />
          );
        })}
      </Tabs>

      {devices.map((device, ind) => {
        return (
          <CustomTabPanel key={ind + device.name} value={activeTab} index={ind}>
            {device.temperatureTrend &&
            <AvaniTemperatureTrendChart
              data={ device.temperatureTrend.map((trendData) => ({name: device.name, data: trendData.temperature})) }
              dates={ device.temperatureTrend.map((trendData) => trendData.date) }
              range={ device.minTemperature && device.maxTemperature ? device : undefined }
              additionalData={ dispenseData }
            />
            }
          </CustomTabPanel>
        );
      })}
    </>
  );
};
