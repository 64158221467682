import * as React from 'react';

import { styled } from '@mui/material/styles';
import { Paper, Typography } from '@mui/material';

import { CalendarToday, Person } from '@mui/icons-material';

import { VisitDashboardCommentModel } from 'src/models/types/comments.types';

const AvaniStyledPaperCard = styled(Paper)(
  ({ theme }) => `
  background-color: ${theme.colors.avani.aGrey};
  border-radius: 16px;
  margin-top: 16px;
  padding: 16px;
`);

export const AvaniPaperCard: React.FC<VisitDashboardCommentModel> = ({
  content,
  authorName,
}) => {
  return (
    <AvaniStyledPaperCard
      elevation={0}
    >
      <div style={{
        float: 'left',
        marginRight: 16,
      }}>
        <svg width="60" height="49" viewBox="0 0 60 49" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M24 33.5015V36.5015C24 39.8108 26.6906 42.5015 30 42.5015H40.9969L48.6 48.2015C49.05 48.539 49.6594 48.5952 50.175 48.3421C50.6906 48.089 51 47.5733 51 47.0015V42.5015H54C57.3094 42.5015 60 39.8108 60 36.5015V18.5015C60 15.1921 57.3094 12.5015 54 12.5015H42V24.5015C42 29.4702 37.9688 33.5015 33 33.5015H24Z" fill="#00A7A8"/>
          <path d="M0 6.50049C0 3.19111 2.69063 0.500488 6 0.500488H33C36.3094 0.500488 39 3.19111 39 6.50049V24.5005C39 27.8099 36.3094 30.5005 33 30.5005H19.0031L11.4 36.2005C10.95 36.538 10.3406 36.5942 9.825 36.3411C9.30938 36.088 9 35.5724 9 35.0005V30.5005H6C2.69063 30.5005 0 27.8099 0 24.5005V6.50049Z" fill="#005665"/>
        </svg>
      </div>
      <div style={{
        display: 'inline',
        width: 'auto',
      }}>
        {!!authorName &&
        <Typography variant='body2' lineHeight={1} fontWeight={'bold'} sx={{display: 'inline-block', marginRight: 1, marginBottom: 1}}>
          <Person color={'aTurc'} style={{float: 'left', marginTop: -5}} fontSize={'small'} /> {authorName}
        </Typography>
        }
        {/* {date &&
        <Typography variant='body2' fontWeight={'bold'} sx={{display: 'inline-block'}}>
          <CalendarToday color={'aTurc'} fontSize={'small'} style={{float: 'left', marginTop: -3, marginRight: 3}} /> {date}
        </Typography>
        } */}

        {!!content &&
        <Typography variant='body2'>
          {content}
        </Typography>
        }
      </div>
    </AvaniStyledPaperCard>
  );
}
