import * as React from 'react';

import { List, ListItem } from '@mui/material';

import { AvaniGlassWasherCard } from 'src/components/Cards/AvaniGlassWasherCard';

import { GlassWasherData } from 'src/models/types/DashboardBlock/glass-washers.types';

interface GlassWashersProps {
  data: GlassWasherData[],
  gwWidth: number,
}

export const AvaniGlassWashers: React.FC<GlassWashersProps> = ({
  data,
  gwWidth,
}) => {
  return (
    <List
      disablePadding
      sx={{
        display: 'block',
        width: (data.length * (gwWidth + 12)) + 'px'
      }}
    >
      {data.map((gw, i) => {
        return (
          <ListItem
            disablePadding
            key={i}
            sx={{
              display: 'inline-block',
              width: `${gwWidth}px`,
              marginRight: '12px',
            }}
          >
            <AvaniGlassWasherCard
              {...gw}
            />
          </ListItem>
        );
      })}
    </List>
  );
};
