export const BP_CONTENTS = [
  {
    name: 'Cellar Management',
    content: 'An effectively managed cellar is a safe and hygienic place to work. Careful monitoring of stock dates and quantities ensure stock is sold in a timely manner and at it`s best quality.',
  },
  {
    name: 'Backboard Hygiene',
    content: 'Keeping the external surfaces of dispense equipment clean and sanitary is just as important as cleaning the inside. Clean surfaces reduce the risk of contamination and promotes better hygiene practices overall.',
  },
  {
    name: 'Coupler Hygiene',
    content: 'The connection between the coupler and the keg is the primary point of contamination in a beer system. Cleaning and sanitising the connection surfaces minimises the chance of spoilage and reduces the risk of gas leaks from a poor seal.',
  },
  {
    name: 'Keg Change',
    content: 'Turning off the gas during keg change minimises leaks and turbulence caused by connecting a live supply to the keg. Proper sanitation of the coupler reduces the risk of contamination in the beer system.',
  },
  {
    name: 'Cask Process',
    content: 'When dealing with "live" cask products, having a well documented conditioning process and hygiene practices are essential for delivering the best possible product quality. Contamination, disruption, or delay can all seriously impact quality and lead to significant losses.',
  },
  {
    name: 'Airflow Maintenance',
    content: 'Maintaining ventilation is important for many parts of the dispense system; for the dissipation of heat, to reduce the risk of mould growth, and control pests. Blocking airflow with stock or other items should always be avoided.',
  },
  {
    name: 'Bar Management',
    content: 'Keeping a clean and tidy bar promotes a safe working environment where product quality can thrive. Customers respond positively to a well organised bar, which will lead to increased satisfaction and retention.',
  },
  {
    name: 'Glassware',
    content: 'Every pint needs a glass, so it is vitally important that it is clean, dry, and at ambient temperature to promote product quality. Correct branding promotes customer retention and ensures the beer is served as the brewer intended. Correct nucleation helps with head retention and mouth feel.',
  },
  {
    name: 'Glass Washer Maintenance',
    content: 'Daily maintenance to clean, restock, and inspect is vitally important to promoting good glassware hygiene and product quality. Running out of detergent, blocked spray arm nozzles, and reservoir contamination are common issues which quickly impact the hygiene of glassware.',
  },
  {
    name: 'Nozzle Hygiene',
    content: 'Nozzles are exposed to both beer and air, which makes them perfect breeding grounds for microbes. Biofilm formation will cause turbulence and fobbing, as well as the potential health implications. Haze in the product is also another common issue of poorly maintained nozzles. Removing nozzles daily, washing under hot running water, and allowing to air dry will keep any contamination at bay.',
  },
  {
    name: 'Pour Technique',
    content: 'Correct pour technique will reduce wastage and promote product quality. Common bad habits include dipping the nozzle in the beer, pouring away the first liquid out of the tap, swirling a flat looking pour, holding the glass straight, and pouring out excess head.',
  },
  {
    name: 'Gas Management',
    content: 'Running the system on empty cylinders can cause pressure to drop in the kegs, leading to fobbing and flat beer. If prolonged, this can permanently spoil a keg. Daily inspection of gas levels, turning off at night to check for leaks and prevent overgassing, and safely storing cylinders are all important to maintaining product quality.',
  },
  {
    name: 'Eco Efficiency',
    content: 'Customers commonly cite environmentally conscientious practices as a reason for visiting a business, so doing what you can to reduce your impact can save money on lost resources, increase customer footfall, and benefit everyone in the process.',
  },
  {
    name: 'Other',
    content: 'Other',
  },
];
