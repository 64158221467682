import { createContext, FC, useState } from 'react';
import { LegalsModel } from '../models/types/legals.types';
import { LegalSortingFields, Order } from '../models/types/common.types';

interface ILegalsContext {
  legalsUpdate: boolean;
  toggleLegalsUpdate: () => void;
  legals: LegalsModel[];
  setLegals: (val: LegalsModel[]) => void;
  page: number;
  setPage: (val: number) => void;
  limit: number;
  setLimit: (val: number) => void;
  count: number;
  setCount: (val: number) => void;
  order: Order;
  setOrder: (val: Order) => void;
  orderBy: LegalSortingFields;
  setOrderBy: (val: LegalSortingFields) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
}

export const LegalsContext = createContext<ILegalsContext>({} as ILegalsContext);

export const LegalsProvider: FC = ({ children }) => {
  const [legalsUpdate, setLegalsUpdate] = useState(false);
  const [legals, setLegals] = useState<LegalsModel[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<LegalSortingFields>('name');
  const [loading, setLoading] = useState(false);
  const toggleLegalUpdate = (): void => setLegalsUpdate(!legalsUpdate);

  return (
    <LegalsContext.Provider
      value={{
        legalsUpdate,
        toggleLegalsUpdate: toggleLegalUpdate,
        legals,
        setLegals,
        page,
        setPage,
        limit,
        setLimit,
        count,
        setCount,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        loading,
        setLoading
      }}
    >
      {children}
    </LegalsContext.Provider>
  );
};
