import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@mui/material';

import Footer from 'src/layouts/LayoutComponents/Footer';

import * as ProCleanMock from 'src/mocks/ProCleanMock';
import * as ProCleanPlusMock from 'src/mocks/ProCleanPlusMock';
import * as BestPracticeContent from 'src/assets/best_practice/BestPracticeContent';

import { VisitInformationBlock } from 'src/components/Blocks/VisitInformationBlock';
import { PreCleanStatusBlock } from 'src/components/Blocks/PreCleanStatusBlock';
import { TemperatureBlock } from 'src/components/Blocks/TemperatureBlock';
import { WastageBlock } from 'src/components/Blocks/WastageBlock';
import { HygieneBlock } from 'src/components/Blocks/HygieneBlock';
import { BestPracticeBlock } from 'src/components/Blocks/BestPracticeBlock';
import { FaultsBlock } from 'src/components/Blocks/FaultsBlock';
import { GlassWashersBlock } from 'src/components/Blocks/GlassWashersBlock';

import { VisitDashboardContext } from 'src/contexts/VisitDashboardContext';
import { getProCleanVisitDashboardData } from 'src/models/api-calls/dashboard.api';
import SuspenseLoader from 'src/components/LoadingIndicators/SuspenseLoader';
import { VisitDashboardCommentModel } from 'src/models/types/comments.types';
import { MediaModel } from 'src/models/types/medias.types';

type PageParam = {
  visitType: string,
}

type BestPracticeComment = {
  type: string,
  subType?: string,
  customSubType?: string,
  staticContent: string | undefined,
  content: string,
  authorName: string,
  media: MediaModel[],
}

export const ProCleanPlusDashboard:React.FC<PageParam> = ({
  visitType,
}): JSX.Element => {
  const {
    setLoading,
    loading,
    comments,
    setComments,
    generalBlock,
    setGeneralBlock,
    preCleanBlock,
    setPreCleanBlock,
    temperaturesWithTrendsBlock,
    setTemperaturesWithTrendsBlock,
    glassWashersBlock,
    setGlassWashersBlock,
    hygieneBlock,
    setHygieneBlock,
    wastageBlockWithGraph,
    setWastageBlockWithGraph,
    faultsBlock,
    setFaultsBlock,
  } = React.useContext(VisitDashboardContext);

  const { visitId } = useParams();

  const [ bestPracticeComment, setBestPracticeComment ] = React.useState<BestPracticeComment | undefined>(undefined);

  const handleBestPracticeComment = (commentsData: VisitDashboardCommentModel[]) => {
    const bpCommentData = commentsData.find((comment) => comment.type === 'Best practice');

    if (!!bpCommentData) {
      const staticContent = BestPracticeContent.BP_CONTENTS.find((sItem) => sItem.name === (bpCommentData.subType !== 'Other' ? bpCommentData.subType : bpCommentData.customSubType ))?.content;
      setBestPracticeComment({
        ...bpCommentData,
        staticContent: staticContent
      });
    }
  };

  React.useEffect(() => {
    if (!visitId) return;
    (async () => {
      setLoading(true);
      const {
        comments,
        generalBlock,
        preCleanBlock,
        temperaturesWithTrendsBlock,
        glassWashersBlock,
        hygieneBlock,
        wastageBlockWithGraph,
        faultsBlock,
      } = await getProCleanVisitDashboardData(visitId, visitType);
      handleBestPracticeComment(comments);
      setComments(comments);
      setGeneralBlock(generalBlock);
      setPreCleanBlock(preCleanBlock);
      setTemperaturesWithTrendsBlock(temperaturesWithTrendsBlock);
      setGlassWashersBlock(glassWashersBlock);
      setHygieneBlock(hygieneBlock);
      setWastageBlockWithGraph(wastageBlockWithGraph);
      setFaultsBlock(faultsBlock);
      setLoading(false);
    })();
  }, []);

  return (
    <>
    {loading && <SuspenseLoader />}
    {!loading &&
    <div>
      <Container maxWidth={false}>
        {!!generalBlock &&
        <VisitInformationBlock
          columns={4}
          currentVisit={generalBlock.currentVisit}
          preCleanResults={generalBlock.preCleanResults}
          temperature={generalBlock.highTemperatureWarning}
          faults={generalBlock.faultTypes}
          ranking={ProCleanMock.GENERAL_BLOCK.ranking}
          savedBeer={generalBlock.beersSaved}
          perfectPintImages={generalBlock.perfectPintImages}
          safetyCheckImages={generalBlock.safetyCheckImages}
          comment={comments?.find((comment) => comment.type === 'Visit common')}
        />
        }

        {!!preCleanBlock &&
        <PreCleanStatusBlock
          visitId={!!visitId ? visitId : ''}
          outOfTrend={preCleanBlock.outOfTrendProducts}
          visits={preCleanBlock.venueVisitsResults}
          bars={preCleanBlock.venueBarsResults}
          comment={comments?.find((comment) => comment.type === 'Pre clean')}
        />
        }

        {!!glassWashersBlock &&
        <GlassWashersBlock
          images={ProCleanPlusMock.GLASSWASHERS_BLOCK.images}
          comment={ProCleanPlusMock.GLASSWASHERS_BLOCK.comment}
          glassWashers={glassWashersBlock.glassWashers}
        />
        }

        {!!temperaturesWithTrendsBlock &&
        <TemperatureBlock
          entities={temperaturesWithTrendsBlock.tempEntities}
          comment={comments?.find((comment) => comment.type === 'Temperature')}
        />
        }

        {!!wastageBlockWithGraph &&
        <WastageBlock
          perVisit={wastageBlockWithGraph.perVisitData}
          byProduct={wastageBlockWithGraph.actualWastageByProduct}
          efficiencyScore={wastageBlockWithGraph.wastageEfficiencyData}
          saved={wastageBlockWithGraph.savedData}
          comment={comments?.find((comment) => comment.type === 'Wastage')}
        />
        }

        {!!hygieneBlock &&
        <HygieneBlock
          {...hygieneBlock}
          comment={comments?.find((comment) => comment.type === 'Hygiene')}
        />
        }

        {!!bestPracticeComment &&
        <BestPracticeBlock
          data={bestPracticeComment}
        />
        }

        {!!faultsBlock &&
        <FaultsBlock
          faults={faultsBlock.faults}
          comment={comments?.find((comment) => comment.type === 'Fault')}
        />
        }
      </Container>
      <Footer />
      
    </div>
      }
    </>
  );
};

export default ProCleanPlusDashboard;
