import { UserModel } from './users.types';
import { VisitModel } from './visits.types';
import { MediaModel } from './medias.types';
import { VisitFaultModel } from './visits-faults.types';
import { BaseModel } from './common.types';

export enum CommentType {
  VisitCommon = 'Visit common',
  VisitFuture = 'Visit future',
  VisitLast = 'Visit last',
  ClientCommon = 'Client common',
  PreClean = 'Pre clean',
  Wastage = 'Wastage',
  Temperature = 'Temperature',
  Hygiene = 'Hygiene',
  BestPractice = 'Best practice',
  Faults = 'Fault',
}

export interface CommentModel extends BaseModel {
  content: string;
  type: CommentType;
  subType?: string;
  customSubType?: string;
  author: UserModel;
  visit: VisitModel;
  fault?: VisitFaultModel;
  media?: MediaModel[];
}

export type CommentParams = Omit<CommentModel, 'author' | 'visit'> & {
  authorId?: string;
  faultId?: string;
};

export type VisitDashboardCommentModel = Pick<CommentModel, 'type' | 'content' | 'subType' | 'customSubType'> & {
  authorName: string;
  media: MediaModel[];
};
