import * as React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';

import {
  ExpandMore,
} from '@mui/icons-material';

import { AvaniPaperCard } from 'src/components/Cards/AvaniPaperCard';
import { AvaniScrollable } from 'src/components/Utils/AvaniScrollable';
import { AvaniHygiene } from 'src/components/DataDisplay/AvaniHygiene';

import { BarElementData } from 'src/models/types/DashboardBlock/hygiene.types';
import { VisitDashboardCommentModel } from 'src/models/types/comments.types';

type HygieneBlockProps = {
  nozzles?: BarElementData,
  glassware?: BarElementData,
  glassWashers?: BarElementData,
  pythonCondition?: BarElementData,
  remoteGrille?: BarElementData,
  couplers?: BarElementData,
  tapSeals?: BarElementData,
  comment?: VisitDashboardCommentModel,
};

export const HygieneBlock:React.FC<HygieneBlockProps> = ({
  nozzles,
  glassware,
  glassWashers,
  tapSeals,
  couplers,
  remoteGrille,
  pythonCondition,
  comment,
}) => {
  const theme = useTheme();
  const frame = React.useRef<HTMLElement | null>(null);
  const [ availableWidth, setAvailableWidth ] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (frame && frame.current) {
      setAvailableWidth(frame.current.clientWidth);
    }
  }, [frame]);

  return (
    <div style={{marginTop: '36px'}}>
      <Accordion
        defaultExpanded
        sx={{
          background: 'transparent'
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore fontSize={'large'} />}
          sx={{
            padding: 0,
            borderRadius: 0,
          }}
        >
          <Typography
            variant={'h2'}
            sx={{
              color: theme.colors.avani.teal,
            }}
          >
            Hygiene checks
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: 0
          }}
        >
          <Grid container columns={10} spacing={1}>
            {!!comment &&
            <Grid item
              xs={10}
            >
              <AvaniPaperCard
                {...comment}
              />
            </Grid>
            }
            {(nozzles || glassware || glassWashers) &&
            <Grid
              item
              xs={10}
            >
              <Box ref={frame}>
              {!!availableWidth &&
              
              <AvaniScrollable
                width={availableWidth}
                height={396}
                key={'faults'}
                horizontal
              >
              <AvaniHygiene
                nozzles={nozzles}
                glassWare={glassware}
                glassWashers={glassWashers}
                tapSeals={tapSeals}
                couplers={couplers}
                pythonCondition={pythonCondition}
                remoteGrille={remoteGrille}
              />
              </AvaniScrollable>}
              </Box>
            </Grid>}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  )
};
