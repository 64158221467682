import * as React from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import { ExpandMore } from '@mui/icons-material';

import { AvaniCard } from 'src/components/Cards/AvaniCard';
import { AvaniPaperCard } from 'src/components/Cards/AvaniPaperCard';
import { AvaniExportCard } from 'src/components/Cards/AvaniExportCard';
import { AvaniLineConditionDialog } from 'src/components/Dialogs/LineCondition/AvaniLineConditionDialog';
import { AvaniBarChart } from 'src/components/Charts/AvaniBarChart';
import { AvaniProductList } from 'src/components/DataDisplay/AvaniProductList';
import { AvaniScrollable } from 'src/components/Utils/AvaniScrollable';

import type {
  BaseItemsList,
  ChartData,
  LastUpdated,
} from 'src/types/Dashboard';

import { ProductData, VenueVisitsResultsType, VenueBarsResultsType } from 'src/models/types/DashboardBlock/pre-clean.types';
import { VisitDashboardCommentModel } from 'src/models/types/comments.types';

type PreCleanStatusBlockProps = {
  visitId: string,
  outOfTrend?: ProductData[],
  visits?: VenueVisitsResultsType,
  bars?: VenueBarsResultsType,
  comment?: VisitDashboardCommentModel,
};

export const PreCleanStatusBlock:React.FC<PreCleanStatusBlockProps> = ({
  outOfTrend,
  visits,
  bars,
  comment,
  visitId,
}) => {
  const theme = useTheme();

  return (
    <Accordion
      defaultExpanded
      sx={{
        background: 'transparent'
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore fontSize={'large'} />}
        sx={{
          padding: 0,
          borderRadius: 0,
        }}
      >
        <Typography
          variant={'h2'}
          sx={{
            color: theme.colors.avani.teal,
            marginTop: 3,
          }}
        >
          Pre-clean Status
          <AvaniLineConditionDialog />
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: 0
        }}
      >
        
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={1}
          columns={10}
        >
          {!!comment && 
          <Grid item
            xs={10}
          >
            <AvaniPaperCard
              {...comment}
            />
          </Grid>}

          {visits &&
          <Grid item xs={10} md={5} lg={4}>
            <AvaniCard title={'Venue Results'}>
              <AvaniBarChart
                data={visits.data.map((item, i) => ({data : item.sumOfRatingByVisitDate, id: item.rating, label: item.rating }))}
                dates={visits.visitDates}
                timeData
              />
            </AvaniCard>
          </Grid>}
          {bars &&
          <Grid item xs={10} md={5} lg={4}>
            <AvaniCard title={'Results By Bar'}>
              <AvaniBarChart
                data={bars.data.map((item) => ({ data: item.sumOfRatingByBar, id: item.rating, stack: 'total', label: item.rating }))}
                dates={bars.barNames}
              />
            </AvaniCard>
          </Grid>}
          <Grid item xs={10} md={10} lg={2}>
            <div style={{
              display: 'flex',
              height: '100%',
              gap: '1px',
              flexDirection: useMediaQuery(theme.breakpoints.between('md', 'lg')) ? 'row' : 'column',
              alignItems: 'stretch',
            }}>
              {outOfTrend &&
              <div style={{
                flex: 1,
                display: 'flex',
              }}>
                <AvaniCard
                  padded
                  title={'Out of trend'}
                  icon={<Avatar
                    sx={{
                      backgroundColor: theme.colors.avani.magenta,
                      color: 'white',
                      fontSize: 18,
                      padding: '3px 0 0 0',
                      textAlign: 'center',
                    }}
                  >
                    {outOfTrend.length}
                  </Avatar>}
                  sx={{
                    flex: 1,
                  }}
                >
                  <AvaniScrollable
                    width={100}
                    height={162}
                    key={'outOfTrend'}
                    sx={{
                      marginTop: '24px'
                    }}
                  >
                    <AvaniProductList products={outOfTrend} />
                  </AvaniScrollable>
                </AvaniCard>
              </div>}
              <AvaniExportCard
                visitId={visitId}
                markedDates={visits?.visitDates}
                sx={{
                  mt: useMediaQuery(theme.breakpoints.between('md', 'lg')) ? 0 : 1,
                  ml: useMediaQuery(theme.breakpoints.between('md', 'lg')) ? 1 : 0,
                }}
              />
            </div>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
};
