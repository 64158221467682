import React from 'react';
import { Suspense } from 'react';
import SuspenseLoader from 'src/components/LoadingIndicators/SuspenseLoader';

interface LoaderProps {
  [key: string]: any;
}

export const Loader = (Component: React.FC<LoaderProps>) => (props: LoaderProps) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
