import * as React from 'react';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
  useTheme
} from '@mui/material';

import { ProductData } from 'src/models/types/DashboardBlock/pre-clean.types';

interface AvaniProductListProps {
  products: ProductData[];
  units?: string;
  dense?: boolean;
  listTypeIcon?: React.ReactElement;
  truncateValue?: boolean;
  date?: 'prop' | 'val';
  link?: boolean;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.avani.lightTurc,
    maxWidth: 320,
    borderRadius: '16px',
    border: `1px solid ${theme.colors.avani.magenta}`,
  },
}));

export const AvaniProductList: React.FC<AvaniProductListProps> = ({
  products,
  units,
  dense,
  listTypeIcon,
  truncateValue,
  date,
  link,
}) => {
  const theme = useTheme();
  const productNameSx: any = truncateValue
    ? { paddingTop: '3px', paddingLeft: '4px', float: 'left' }
    : {
        display: 'inline-block',
        paddingTop: '3px',
        paddingLeft: '4px',
        flexGrow: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      };
  const productValueSx: any = truncateValue
    ? {
        display: 'inline-block',
        paddingTop: '3px',
        paddingLeft: '4px',
        flexGrow: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    : { float: 'right' };

  const displayDate = (dateString: string) => DateTime.fromISO(dateString).toFormat('dd/MM');

  const navigate = useNavigate();

  return (
    <List
      disablePadding
      sx={{
        display: 'block',
        minWidth: '178px'
      }}
    >
      {products.map((product, i) => {
        return (
          <ListItem
            disablePadding
            key={i}
            sx={{
              display: 'block',
              borderBottom: '1px dashed ' + theme.colors.avani.teal,
              paddingBottom: dense ? 1 : 1.5,
              pt: i > 0 ? (dense ? 1 : 1.5) : 0
            }}
          >
              <Typography
                variant={'body2'}
                component={'div'}
                fontWeight={'bold'}
                fontSize={dense ? 14 : 16}
                color={theme.colors.primary}
                sx={{
                  display: 'flex',
                  cursor: link ? 'pointer': 'default'
                }}
              >
                {listTypeIcon && listTypeIcon}
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <List>
                        <ListItem
                          sx={{
                            display: 'flex',
                            [`.MuiListItemIcon-root`]: {
                              paddingRight: 3
                            },
                            [`.MuiListItemText-root`]: {
                              textAlign: 'right'
                            }
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              fontSize: '18px',
                            }}
                          >
                            Bar:
                          </ListItemIcon>
                          <ListItemText
                            sx={{
                              color: theme.colors.avani.turc
                            }}
                          >{product.barName}</ListItemText>
                        </ListItem>
                        <ListItem
                          sx={{
                            display: 'flex',
                            [`.MuiListItemIcon-root`]: {
                              paddingRight: 3
                            },
                            [`.MuiListItemText-root`]: {
                              textAlign: 'right'
                            }
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              fontSize: '18px',
                            }}
                          >Tap: </ListItemIcon>
                          <ListItemText
                            sx={{
                              color: theme.colors.avani.turc
                            }}
                          >{product.tapName}</ListItemText>
                        </ListItem>
                        <ListItem
                          sx={{
                            display: 'flex',
                            [`.MuiListItemIcon-root`]: {
                              paddingRight: 3
                            },
                            [`.MuiListItemText-root`]: {
                              textAlign: 'right'
                            }
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              fontSize: '18px',
                            }}
                          >Product: </ListItemIcon>
                          <ListItemText
                            sx={{
                              color: theme.colors.avani.turc
                            }}
                          >{product.productName}</ListItemText>
                        </ListItem>
                      </List>
                    </React.Fragment>
                  }
                >
                  <span style={productNameSx}>{product.productName}</span>
                </HtmlTooltip>
                
                {/* {product.value && <span style={productValueSx}>{product.value + (units ? ' ' + units : '')}</span>} */}
              </Typography>
          </ListItem>
        );
      })}
    </List>
  );
};
