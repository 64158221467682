import * as React from 'react';
import AnimatedNumber from 'react-animated-numbers';

import { useTheme } from '@mui/material';

interface AvaniNumberSpinProps {
  numberValue: number,

  fSize?: number,
  sx?: any,
}

export const AvaniNumberSpin: React.FC<AvaniNumberSpinProps> = ({
  numberValue,
  fSize,
  sx,
}) => {
  const theme = useTheme();
  const [ output, setOutput ] = React.useState(numberValue);

  return (
    <span
      onClick={() => {
        const currentValue = output;
        setOutput(0);
        setTimeout(() => {
          setOutput(currentValue);
        }, 1);
      }}
      style={{...sx, cursor: 'pointer'}}
    >
      <AnimatedNumber
        fontStyle={{
          fontFamily: theme.typography.fontFamily,
          fontWeight: 'bold',
          color: theme.colors.avani.turc,
          fontSize: fSize ? fSize : 16
        }}
        animateToNumber={output}
      />
    </span>
  );
};
