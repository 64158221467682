import { Grid, Button } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import LoadingButton from './LoadingButton';

interface AvaniButtonProps {
  onClick?: any;
  loading: boolean;
  label: string;
  grid?: boolean;
  disabled?: boolean;
  type?: 'submit' | 'button' | 'reset';
  variant?: 'contained' | 'outlined' | 'text' | undefined;
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  fullWidth?: boolean;
  sx?: any,
}

const StyledButton = styled(Button)(
  ({ theme }) => `
  .MuiButtonBase-root {
    border-radius: 50%;
    border: 2px solid #333333!important;
  }
  `
);

export const AvaniButton: React.FC<AvaniButtonProps> = ({
  onClick,
  label,
  disabled,
  loading,
  grid = false,
  variant = 'contained',
  type = 'button',
  size = 'large',
  color = 'primary',
  fullWidth = false,
  sx = {},
}) => {
  const controlElement = loading ? (
    <>
      <LoadingButton
        {...(onClick ? { onClick: onClick } : {})}
        loading={loading}
        sx={{ mx: 1, px: 3, ...sx }}
        type={type}
        variant={variant}
        size={size}
        disabled={disabled}
        color={color}
        fullWidth={fullWidth}
      >
        {label}
      </LoadingButton>
    </>
  ) : (
    <>
      <StyledButton
        {...(onClick ? { onClick: onClick } : {})}
        sx={{ mx: 1, px: 3, ...sx }}
        type={type}
        variant={variant}
        size={size}
        disabled={disabled}
        color={color}
        fullWidth={fullWidth}
      >
        {label}
      </StyledButton>
    </>
  );

  return grid ? (
    <Grid item xs={6}>
      {controlElement}
    </Grid>
  ) : (
    controlElement
  );
};
