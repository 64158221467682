import { IconButton, Tooltip } from '@mui/material';
import { useContext, useState } from 'react';
import DarkModeTwoToneIcon from '@mui/icons-material/DarkModeTwoTone';
import LightModeTwoToneIcon from '@mui/icons-material/LightModeTwoTone';
import { ThemeContext } from 'src/theme/ThemeProvider';

function HeaderThemes() {
  const themeContext = useContext(ThemeContext);
  const initialTheme = localStorage.getItem('appTheme');

  const [element, setElement] = useState<any>(
    initialTheme === 'AvaniLightTheme' || !initialTheme ? <LightModeTwoToneIcon /> : <DarkModeTwoToneIcon />
  );

  function getTheme() {
    const appTheme = localStorage.getItem('appTheme');

    switch (appTheme) {
      case 'AvaniLightTheme': {
        return 'AvaniDarkTheme';
      }
      case 'AvaniDarkTheme': {
        return 'AvaniLightTheme';
      }
      default: {
        return 'AvaniDarkTheme';
      }
    }
  }

  const handleChange = (): void => {
    const theme = getTheme();
    localStorage.setItem('appTheme', theme);
    themeContext(theme);
    setElement(theme === 'AvaniLightTheme' ? <LightModeTwoToneIcon /> : <DarkModeTwoToneIcon />);
  };

  return (
    <>
      <Tooltip arrow title="Theme mode">
        <IconButton color="primary" onClick={handleChange}>
          {element}
        </IconButton>
      </Tooltip>
    </>
  );
}

export default HeaderThemes;
