import { createContext, FC, useState } from 'react';
import { ClientEstateReportData } from '../../models/types/reports.types';
import { ClientModel } from '../../models/types/clients.types';

interface IReportsContext {
  loading: boolean;
  setLoading: (val: boolean) => void;
  dataUpdate: boolean;
  toggleUpdate: () => void;
  data: ClientEstateReportData[];
  setData: (val: ClientEstateReportData[]) => void;
  page: number;
  setPage: (val: number) => void;
  limit: number;
  setLimit: (val: number) => void;
  count: number;
  setCount: (val: number) => void;
  client: ClientModel | null;
  setClient: (val: ClientModel | null) => void;
}

export const ClientEstateContext = createContext<IReportsContext>({} as IReportsContext);

export const ClientEstateProvider: FC = ({ children }) => {
  const [dataUpdate, setDataUpdate] = useState(false);
  const [data, setData] = useState<ClientEstateReportData[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState<ClientModel | null>(null);

  const toggleUpdate = (): void => setDataUpdate(!dataUpdate);

  return (
    <ClientEstateContext.Provider
      value={{
        data,
        setData,
        page,
        setPage,
        limit,
        setLimit,
        count,
        setCount,
        loading,
        setLoading,
        dataUpdate,
        toggleUpdate,
        client,
        setClient
      }}
    >
      {children}
    </ClientEstateContext.Provider>
  );
};
