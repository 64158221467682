import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import BaseLayout from 'src/layouts/BaseLayout';
import { Loader } from './loader.routes';
import { lazy } from 'react';
import { createUsersRoutes } from './users.routes';
import SidebarLayout from 'src/layouts/SidebarLayout';
import RequireAuth from 'src/content/routes/Components/RequireAuth';
import Status404 from 'src/content/pages/Status/Status404';
import { createBrewersRoutes } from './brewers.routes';
import { createClientsRoutes } from './clients.routes';
import { createVenuesRoutes } from './venues.routes';
import { createDashboardRoutes } from './dashboard.routes';
import awsconfig from 'src/aws-exports';
import { createProductsRoutes } from './products.routes';
import { createBarsRoutes } from './bars.routes';
import { createVisitsRoutes } from './visits.routes';
import { createTspRoutes } from './technical-support-providers.routes';
import { createLegalsRoutes } from './legals.routes';
import { createRefMaterialsRoutes } from './ref-materials.routes';
import { createAmsPartsRoutes } from './ams-parts.routes';
import { createReportRoutes } from './reports.routes';

// TODO: Since we have this in App.tsx, do we also need it here?
Amplify.configure(awsconfig);

function MainRoutes() {
  const SignIn = Loader(lazy(() => import('src/content/pages/Auth/SignIn')));

  const ForceNewPassword = Loader(lazy(() => import('src/content/pages/Auth/ForceNewPassword')));
  const ForgotPassword = Loader(lazy(() => import('src/content/pages/Auth/ForgotPassword')));

  const usersRoutes = createUsersRoutes();
  const brewersRoutes = createBrewersRoutes();
  const amsPartsRoutes = createAmsPartsRoutes();
  const clientsRoutes = createClientsRoutes();
  const venuesRoutes = createVenuesRoutes();
  const dashboardRoutes = createDashboardRoutes();
  const productsRoutes = createProductsRoutes();
  const barsRoutes = createBarsRoutes();
  const visitsRoutes = createVisitsRoutes();
  const tspRoutes = createTspRoutes();
  const legalRoutes = createLegalsRoutes();
  const refMaterialsRoutes = createRefMaterialsRoutes();
  const reportRoutes = createReportRoutes();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/signin"
          element={
            <BaseLayout>
              <SignIn />
            </BaseLayout>
          }
        />
        <Route
          path="/force-password"
          element={
            <BaseLayout>
              <ForceNewPassword />
            </BaseLayout>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <BaseLayout>
              <ForgotPassword />
            </BaseLayout>
          }
        />
        <Route
          path="/"
          element={
            <RequireAuth>
              <Navigate to="/visits/list" />
            </RequireAuth>
          }
        />
        <Route
          path="*"
          element={
            <RequireAuth>
              <Status404 />
            </RequireAuth>
          }
        />
        <Route path="/users" element={<SidebarLayout />}>
          {usersRoutes.children?.map((childRoute) => (
            <Route
              key={childRoute.path}
              path={`${childRoute.path}`}
              element={<RequireAuth>{childRoute.element}</RequireAuth>}
            />
          ))}
        </Route>
        <Route path="/brewers" element={<SidebarLayout />}>
          {brewersRoutes.children?.map((childRoute) => (
            <Route
              key={childRoute.path}
              path={`${childRoute.path}`}
              element={<RequireAuth>{childRoute.element}</RequireAuth>}
            />
          ))}
        </Route>
        <Route path="/ams" element={<SidebarLayout />}>
          {amsPartsRoutes.children?.map((childRoute) => (
            <Route
              key={childRoute.path}
              path={`${childRoute.path}`}
              element={<RequireAuth>{childRoute.element}</RequireAuth>}
            />
          ))}
        </Route>
        <Route path="/clients" element={<SidebarLayout />}>
          {clientsRoutes.children?.map((childRoute) => (
            <Route
              key={childRoute.path}
              path={`${childRoute.path}`}
              element={<RequireAuth>{childRoute.element}</RequireAuth>}
            />
          ))}
        </Route>
        <Route path="/venues" element={<SidebarLayout />}>
          {venuesRoutes.children?.map((childRoute) => (
            <Route
              key={childRoute.path}
              path={`${childRoute.path}`}
              element={<RequireAuth>{childRoute.element}</RequireAuth>}
            />
          ))}
        </Route>
        <Route path="/dashboard" element={<SidebarLayout />}>
          {dashboardRoutes.children?.map((childRoute) => (
            <Route
              key={childRoute.path}
              path={`${childRoute.path}`}
              element={<RequireAuth>{childRoute.element}</RequireAuth>}
            />
          ))}
        </Route>
        <Route path="/products" element={<SidebarLayout />}>
          {productsRoutes.children?.map((childRoute) => (
            <Route
              key={childRoute.path}
              path={`${childRoute.path}`}
              element={<RequireAuth>{childRoute.element}</RequireAuth>}
            />
          ))}
        </Route>
        <Route path="/ref-materials" element={<SidebarLayout />}>
          {refMaterialsRoutes.children?.map((childRoute) => (
            <Route
              key={childRoute.path}
              path={`${childRoute.path}`}
              element={<RequireAuth>{childRoute.element}</RequireAuth>}
            />
          ))}
        </Route>
        <Route path="/bars" element={<SidebarLayout />}>
          {barsRoutes.children?.map((childRoute) => (
            <Route
              key={childRoute.path}
              path={`${childRoute.path}`}
              element={<RequireAuth>{childRoute.element}</RequireAuth>}
            />
          ))}
        </Route>
        <Route path="/visits" element={<SidebarLayout />}>
          {visitsRoutes.children?.map((childRoute) => (
            <Route
              key={childRoute.path}
              path={`${childRoute.path}`}
              element={<RequireAuth>{childRoute.element}</RequireAuth>}
            />
          ))}
        </Route>
        <Route path="/technical-support-providers" element={<SidebarLayout />}>
          {tspRoutes.children?.map((childRoute) => (
            <Route
              key={childRoute.path}
              path={`${childRoute.path}`}
              element={<RequireAuth>{childRoute.element}</RequireAuth>}
            />
          ))}
        </Route>
        <Route path="/settings/legals" element={<SidebarLayout />}>
          {legalRoutes.children?.map((childRoute) => (
            <Route
              key={childRoute.path}
              path={`${childRoute.path}`}
              element={<RequireAuth>{childRoute.element}</RequireAuth>}
            />
          ))}
        </Route>
        <Route path="/reports" element={<SidebarLayout />}>
          {reportRoutes.children?.map((childRoute) => (
            <Route
              key={childRoute.path}
              path={`${childRoute.path}`}
              element={<RequireAuth>{childRoute.element}</RequireAuth>}
            />
          ))}
        </Route>
        {/* TODO: 400 */}
        {/* <Route path='400' element={<BaseLayout><Status400 /></BaseLayout >} /> */}
        <Route
          path="*"
          element={
            <BaseLayout>
              <SignIn />
            </BaseLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default MainRoutes;
