import { API } from 'aws-amplify';
import { ResponseVisitDashboardTapReportsParams } from '../types/visit-dashboard.types';
import { PaginatedResponse } from '../types/common.types';
import {
  ClientEstateReportData,
  VisitAmsPartReportData,
  VisitsClientReportData,
  VisitsGeneralReportData
} from '../types/reports.types';
import { BasicPaginationParams } from './params';

const apiName = 'avaniApiCommon';
const path = '/reports';

export const getProDashboardTapsReportsData = async (
  visitId: string,
  from: string | null,
  to: string | null
): Promise<ResponseVisitDashboardTapReportsParams> => {
  const myInit = {
    headers: {},
    queryStringParameters: {
      from: from,
      to: to
    }
  };

  return API.get(apiName, `${path}/visit-taps/${visitId}`, myInit);
};

export const getVisitsGeneralReportData = async (
  from: string,
  to: string,
  paginationParams: BasicPaginationParams = new BasicPaginationParams(),
  isExport?: boolean
): Promise<PaginatedResponse<VisitsGeneralReportData>> => {
  const myInit = {
    headers: {},
    queryStringParameters: {
      from,
      to,
      ...paginationParams,
      ...(isExport && { isExport })
    }
  };

  return API.get(apiName, `${path}/visits/general`, myInit);
};

export const getClientEstateReportData = async (
  clientId: string,
  paginationParams: BasicPaginationParams = new BasicPaginationParams(),
  isExport?: boolean
): Promise<PaginatedResponse<ClientEstateReportData>> => {
  const myInit = {
    headers: {},
    queryStringParameters: {
      ...paginationParams,
      ...(isExport && { isExport })
    }
  };

  return API.get(apiName, `${path}/client/estate/${clientId}`, myInit);
};

export const getVisitsClientReportData = async (
  from: string,
  to: string,
  clientId: string,
  paginationParams: BasicPaginationParams = new BasicPaginationParams(),
  isExport?: boolean
): Promise<PaginatedResponse<VisitsClientReportData>> => {
  const myInit = {
    headers: {},
    queryStringParameters: {
      from,
      to,
      ...paginationParams,
      ...(isExport && { isExport })
    }
  };

  return API.get(apiName, `${path}/visits/client/${clientId}`, myInit);
};

export const getAmsPartsUsedReportData = async (
  from: string,
  to: string,
  paginationParams: BasicPaginationParams = new BasicPaginationParams(),
  isExport?: boolean
): Promise<PaginatedResponse<VisitAmsPartReportData>> => {
  const myInit = {
    headers: {},
    queryStringParameters: {
      from,
      to,
      ...paginationParams,
      ...(isExport && { isExport })
    }
  };

  return API.get(apiName, `${path}/visit-ams-parts`, myInit);
};
