import * as React from 'react';

import { styled } from '@mui/material/styles';
import {
  alpha,
  Avatar,
  Button,
  darken,
  Dialog,
  Divider,
  Grid,
  List,
  ListItem,
  Popover,
  Typography,  
  useTheme,
  useMediaQuery,
  DialogContentProps,
} from '@mui/material';
import { Search, Clear, Thermostat } from '@mui/icons-material';

import { AvaniTempPieChart } from 'src/components/Charts/AvaniTempPieChart';
import { AvaniProductList } from 'src/components/DataDisplay/AvaniProductList';

import type { TemperatureFullData } from 'src/types/Dashboard';

interface AvaniTempDialogProps {
  id: number,
  temp: number | null,
  title: string,
  isOutOfRange: boolean,
  toggle: boolean,
  setClosed: () => void,
}

interface DialogContentsProps {
  temp: number | null,
  title: string,
  isOutOfRange: boolean,
  isDialog: boolean,
}

const AvaniStyledTempPopover = styled(Popover)(
  ({ theme }) => `
  .MuiPopover-paper {
    border-radius: 16px;
    padding: 16px;
    max-width: 450px;
    background-color: ${theme.colors.avani.lightTurc}
  }

  &.outOfRange .MuiPopover-paper {
    background-color: ${theme.colors.avani.lightMagenta}
  }
`);

const AvaniStyledDivider = styled(Divider)(
  ({ theme }) => `
  border-bottom: 1px dashed ${theme.colors.avani.teal};
  background: none;
  padding-top: 12px;
  margin-bottom: 12px;
`);

const ManageTempButton = styled(Button) (
  ({ theme }) => `
  &.ManageTempButton {
    border-radius: 24px;
    background-color: ${theme.colors.avani.lightTurc};
    border: 1px solid ${theme.colors.avani.turc};
    color: ${theme.colors.avani.turc};
    float: right;
    padding-left: 32px;
    padding-right: 32px;
    height: auto;
  }

  .outOfRange &.MuiButton-text.ManageTempButton {
    background-color: ${theme.colors.avani.lightMagenta};
    border: 1px solid ${theme.colors.avani.magenta};
    color: ${theme.colors.avani.magenta};
  }
`);

const DialogContents:React.FC<DialogContentsProps> = ({
  temp, isDialog, isOutOfRange, title
}) => {
  const theme = useTheme();
  const descText = ():string => {
    const allOrSome = isOutOfRange ? 'Some' : 'All';
    const match = isOutOfRange ? 'are out of range' : 'match the requirements';

    return allOrSome + ' temperatures ' + match + '.'; 
  }

  return (
    <Grid container minWidth={isDialog ? 'auto': 350}>
      <Grid item xs={12}
        sx={{
          display: 'flex',
          alignItems: 'start',
        }}
      >
        <Typography
          color={isOutOfRange ? theme.colors.avani.magenta : 'aTeal'}
          variant={'h3'}
          fontSize={24}
          sx={{
            lineHeight: 1,
            flexGrow: 1
          }}
        >
          {title}<br />
          <Typography variant={'body2'} fontSize={12}
            sx={{
              display: 'block',
              width: '100%'
            }}
          >
            {descText()}
          </Typography>
        </Typography>
        <ManageTempButton className={'ManageTempButton'}>
          View
        </ManageTempButton>
        <Thermostat fontSize={'large'} color={'aTeal'} sx={{float: 'right'}} />
      </Grid>
      <Grid item xs={12}>
        <AvaniStyledDivider sx={{
          borderColor: theme.colors.avani.teal
        }}></AvaniStyledDivider>
      </Grid>

        <Grid item xs={12}>
          <div style={{
            display: 'flex',
            width: '100%',
          }}>
            {!!temp &&
            <Typography
              variant='h3'
              fontSize={40}
              lineHeight={1}
              color={isOutOfRange ? theme.colors.avani.magenta : 'aTeal'}
              sx={{
                height: '30px'
              }}
            >
              {temp + '°C'}
            </Typography>
            }
            {/* <div style={{
              paddingTop: theme.spacing(1),
              paddingLeft: theme.spacing(1),
              flexGrow: 1,
              height: '30px'
            }}>
              <Typography variant='body2' fontSize={16} lineHeight={1}>
                {temp.desc}
              </Typography>
            </div> */}
          </div>
        </Grid>
        {/* <Grid item xs={12}>
          <AvaniStyledDivider></AvaniStyledDivider>
        </Grid>
        <Grid item xs={12}
          sx={{
            pl: isDialog ? 0 : 6
          }}
        >
          <AvaniTempPieChart
            data={temp.lines?.data}
            total={temp.lines?.total}
            units={'lines'}
            small
          />
        </Grid> */}
      {/* {temp.inGlass &&
      <Grid item xs={12}>
        <AvaniProductList units={'°C'} products={temp.products? temp.products : [
          {
            name: 'No data',
            isOor: true,
            value: 0,
          }
        ]}
        />
      </Grid>
      }
      {(!temp.inGlass && temp.errors) && 
        <>
          <Grid item xs={12}>
            <AvaniStyledDivider
              sx={{
                borderColor: theme.colors.avani.teal
              }}></AvaniStyledDivider>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <List disablePadding>
              {temp.errors.map((error, ind) => {
                return (
                  <ListItem disablePadding key={ind}>
                    <Typography
                      variant={'body2'}
                      fontWeight={'bold'}
                      color={theme.colors.avani.magenta}
                    >
                      {error.name}
                    </Typography>
                  </ListItem>
                );
              })
              }
            </List>
          </Grid>
        </>
      } */}
    </Grid>
  );
};

export const AvaniTempDialog: React.FC<AvaniTempDialogProps> = ({
  temp,
  id,
  isOutOfRange,
  title,
  toggle,
  setClosed,
}) => {
  const theme = useTheme();
  const [ anchorEl, setAnchorEl ] = React.useState<HTMLElement | null>(null);
  const [ opened, setOpened ] = React.useState(toggle);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const onResize = ():void => {
    if (isSmallScreen) {
      setOpened(toggle);
      setAnchorEl(null);
    } else {
      setAnchorEl(
        toggle ? document.getElementById(id.toString()) : null
      );
      setOpened(toggle);
    }
  };

  React.useLayoutEffect(() => {

    onResize();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    }
  }, [toggle])

  const handleClose = () => {
    if (isSmallScreen) {
      setOpened(false);
    } else {
      setAnchorEl(null);
    }

    setClosed();
  };

  const open = Boolean(anchorEl);
  const aid = open ? 'simple-popover' : undefined;

  return (
    <span>
      {!isSmallScreen &&
      <AvaniStyledTempPopover
        id={aid}
        keepMounted
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={isOutOfRange ? 'outOfRange' : ''}
      >
        <DialogContents 
          temp={temp}
          isOutOfRange={isOutOfRange}
          title={title}
          isDialog={false}
        />
      </AvaniStyledTempPopover>
      }

      {isSmallScreen &&
      <Dialog
        onClose={handleClose}
        className={isOutOfRange ? 'outOfRange' : ''}

        open={opened}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: alpha(darken('#000C57', 0.4), 0.2),
              backdropFilter: 'blur(2px)',
            }
          },
        }}
        sx={{
          [`.MuiDialog-container > .MuiPaper-root`]: {
            backgroundColor: isOutOfRange ? theme.colors.avani.lightMagenta: theme.colors.avani.lightTurc,
            margin: '16px',
            borderRadius: '16px',
            padding: '16px',
            maxWidth: '450px',
          }
        }}
      >
        <DialogContents 
          temp={temp}
          isOutOfRange={isOutOfRange}
          title={title}
          isDialog={true}
        />
      </Dialog>
      }
    </span>
  );
};
