import * as React from 'react';

import { styled } from '@mui/material/styles';
import {
  Avatar,
  Card,
  Typography,  
  useTheme,
} from '@mui/material';
import { Check, Clear } from '@mui/icons-material';

import { AvaniTempDialog } from 'src/components/Dialogs/Temperature/AvaniTempDialog';
import type { TemperatureFullData } from 'src/types/Dashboard';
interface AvaniTempCardDisplayProps {
  id: number,
  temp: number | null,
  title: string,
  desc: string,
  isOutOfRange: boolean;
  noPopover?: boolean,
  sx?: any,
  onClick?: () => void;
}

const AvaniStyledTempCard = styled(Card)(
  ({ theme }) => `
  border-radius: 16px;
  padding: 16px 0;

  &.AvaniCardSmall {
    padding: 16px 0 8px 0;
  }

  .MuiTypography-root.MuiTypography-h4 {
    margin: 0 16px;
    line-height: 1;
    font-size: 20px;
    padding-bottom: 24px;
  }

  .MuiTypography-root.MuiTypography-h4.AvaniCardTitleTemp {
    color: ${theme.colors.avani.magenta}
  }
`);

export const AvaniTempCardDisplay: React.FC<AvaniTempCardDisplayProps> = ({
  temp,
  id,
  title,
  desc,
  isOutOfRange,
  noPopover,
  onClick,
  sx,
}) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!noPopover) {
      setOpen(true);
    } else if (onClick) {
      onClick();
    }
  };

  const aid = open ? 'simple-popover' : undefined;

  const tempValue = !!temp ? (temp + '°C') : <Clear sx={{fontSize: 22}} />

  return (
    <span>
      <AvaniStyledTempCard
        raised={true}
        id={id.toString()}
        sx={{
          height: '120px',
          backgroundColor: isOutOfRange ?
            theme.colors.avani.lightMagenta : theme.colors.avani.lightTurc,
          ...sx,
        }}
        aria-describedby={aid}
        onClick={handleClick}
      >
        <Typography
          variant="h4"
          color={theme.colors.avani.teal}
          className={isOutOfRange ? 'AvaniCardTitleTemp': ''}
        >
          {title}
        </Typography>
        <div style={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <Typography variant='body2' fontSize={16} sx={{ px: 2, flexGrow: 1,}}>
            {desc}
          </Typography>
          <Avatar
            sx={{
              display: 'inline-flex',
              bgcolor: isOutOfRange ? theme.colors.avani.magenta : theme.colors.avani.teal,
              width: 42,
              height: 42,
              borderRadius: '50%',
              paddingTop: !temp ? 0 : '3px',
              float: 'right',
              marginRight: 2,
            }}
          >
            {tempValue}
          </Avatar>
        </div>
      </AvaniStyledTempCard>
      {!noPopover &&
      <AvaniTempDialog
        toggle={open}
        id={id}
        temp={temp}
        setClosed={() => setOpen(false)}
        title={title}
        isOutOfRange={isOutOfRange}      />
      }
    </span>
  );
};
