import * as React from 'react';
import { DateTime } from 'luxon';

import { styled } from '@mui/material/styles';
import { Button, useTheme } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { UseDateFieldProps } from '@mui/x-date-pickers/DateField';
import { BaseSingleInputFieldProps, DateValidationError, FieldSection } from '@mui/x-date-pickers/models';
import { DateRange } from '@mui/icons-material';

type AvaniDatePickerProps = {
  markedDates?: Date[];
  onSelectDate?: (date: DateTime | null) => void;
  selectedDate?: DateTime | null;
};

interface ButtonFieldProps
  extends UseDateFieldProps<DateTime>,
    BaseSingleInputFieldProps<DateTime | null, DateTime, FieldSection, DateValidationError> {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledButton = styled(Button)(
  ({ theme }) => `
    background-color: ${theme.colors.avani.turc};
    border-radius: 8px;
    border: none;

    &:hover {
      border: none;
      color: ${theme.colors.avani.darkTitleText};

      .MuiSvgIcon-root {
        color: ${theme.colors.avani.darkTitleText};
      }
    }
`
);

const ButtonField: React.FC<ButtonFieldProps> = ({
  setOpen,
  label,
  id,
  disabled,
  InputProps: { ref } = {},
  inputProps: { 'aria-label': ariaLabel } = {}
}) => {
  const theme = useTheme();

  return (
    <StyledButton
      variant="outlined"
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
      endIcon={<DateRange sx={{ color: 'white' }} />}
    >
      {label ? label : 'Select Date'}
    </StyledButton>
  );
};

function RenderDay(props: PickersDayProps<DateTime> & { highlightedDays?: number[] }) {
  const { highlightedDays = [], day, ...other } = props;
  const theme = useTheme();
  const isSelected = highlightedDays.indexOf(props.day.day) >= 0;

  return (
    <PickersDay
      {...other}
      day={day}
      sx={{
        border: isSelected ? `1px solid ${theme.colors.avani.teal}` : 'none'
      }}
    />
  );
}

const getHighlightedDays = (markedDates: string[], dateRef: DateTime): number[] => {
  const shouldHighlight = dateRef.toFormat('dd/mm/yyyy') !== '01/01/1970';

  const output: number[] = [];

  if (shouldHighlight) {
    markedDates.forEach((dateString, i) => {
      const dateObj = DateTime.fromISO(dateString);

      if (dateObj.month === dateRef.month && dateObj.year === dateRef.year) {
        output.push(dateObj.day);
      }
    });
  }

  return output;
};

function ButtonDatePicker(
  props: Omit<DatePickerProps<DateTime>, 'open' | 'onOpen' | 'onClose'> & {
    markedDates: Date[];
  }
) {
  const [open, setOpen] = React.useState(false);
  const currentVisitDate = DateTime.fromISO(
    !!props.markedDates
      ? props.markedDates.map((dateObj) => new Date(dateObj).toISOString())[0]
      : new Date('1970-01-01').toISOString()
  );
  const [highlightedDays, setHighlightedDays] = React.useState(
    getHighlightedDays(
      props.markedDates
        ? props.markedDates.map((dateOBj) => new Date(dateOBj).toISOString())
        : [new Date('1970-01-01').toISOString()],
      currentVisitDate
    )
  );

  const handleMonthChange = (markedDates: string[], newDate: DateTime) => {
    setHighlightedDays(getHighlightedDays(markedDates, newDate));
  };

  return (
    <DatePicker
      slots={{ ...props.slots, field: ButtonField, day: RenderDay }}
      slotProps={{ ...props.slotProps, field: { setOpen } as any, day: { highlightedDays } as any }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      onMonthChange={(e) =>
        handleMonthChange(
          props.markedDates ? props.markedDates.map((dateObj) => new Date(dateObj).toISOString()) : ['01/01/1970'],
          e
        )
      }
    />
  );
}

export const AvaniDatePicker: React.FC<AvaniDatePickerProps> = ({ markedDates, onSelectDate, selectedDate }) => {
  const [value, setValue] = React.useState<DateTime | null>(selectedDate ?? null);

  const selectDate = (newDate: DateTime | null) => {
    setValue(newDate);

    if (onSelectDate) onSelectDate(newDate);
  };

  return (
    <ButtonDatePicker
      label={value == null ? null : value.toLocaleString()}
      value={value}
      markedDates={!!markedDates ? markedDates : [new Date('1970-01-01')]}
      onChange={(newValue) => selectDate(newValue)}
    />
  );
};
