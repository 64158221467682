import { createContext, FC, useState } from 'react';
import { TechnicalSupportProviderModel } from '../models/types/technical-support-providers.types';
import { TechnicalSupportProviderSortingFields, Order } from '../models/types/common.types';

interface ITechnicalSupportProvidersContext {
  technicalSupportProvidersUpdate: boolean;
  toggleTechnicalSupportProvidersUpdate: () => void;
  technicalSupportProviders: TechnicalSupportProviderModel[];
  setTechnicalSupportProviders: (val: TechnicalSupportProviderModel[]) => void;
  page: number;
  setPage: (val: number) => void;
  limit: number;
  setLimit: (val: number) => void;
  count: number;
  setCount: (val: number) => void;
  order: Order;
  setOrder: (val: Order) => void;
  orderBy: TechnicalSupportProviderSortingFields;
  setOrderBy: (val: TechnicalSupportProviderSortingFields) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
}

export const TechnicalSupportProvidersContext = createContext<ITechnicalSupportProvidersContext>(
  {} as ITechnicalSupportProvidersContext
);

export const TechnicalSupportProvidersProvider: FC = ({ children }) => {
  const [technicalSupportProvidersUpdate, setTechnicalSupportProvidersUpdate] = useState(false);
  const [technicalSupportProviders, setTechnicalSupportProviders] = useState<TechnicalSupportProviderModel[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<TechnicalSupportProviderSortingFields>('name');
  const [loading, setLoading] = useState(false);
  const toggleTechnicalSupportProviderUpdate = (): void =>
    setTechnicalSupportProvidersUpdate(!technicalSupportProvidersUpdate);

  return (
    <TechnicalSupportProvidersContext.Provider
      value={{
        technicalSupportProvidersUpdate,
        toggleTechnicalSupportProvidersUpdate: toggleTechnicalSupportProviderUpdate,
        technicalSupportProviders,
        setTechnicalSupportProviders,
        page,
        setPage,
        limit,
        setLimit,
        count,
        setCount,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        loading,
        setLoading
      }}
    >
      {children}
    </TechnicalSupportProvidersContext.Provider>
  );
};
