import * as React from 'react';

import { styled } from '@mui/material/styles';
import { 
  Box,
  Paper,
  Slide,
  Card,
  Grid,
  Typography,
  useTheme
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SportsBar } from '@mui/icons-material';

import { AvaniNumberSpin } from 'src/components/DataDisplay/AvaniNumberSpin';

interface AvaniSavedBeerCardProps {
  title: string,
  beerSaved: number,
  total?: boolean,
  text?: string,
  sx?: any,
}

const AvaniSavedBeerStyledCard = styled(Card)(
  ({ theme }) => `
  border-radius: 16px;
  padding: 16px 0;

  .MuiTypography-root.MuiTypography-h3 {
    margin: 0 0 0 16px;
    line-height: 1;
    padding-bottom: 24px;
    display: block;
    width: 100%;
    float: left;
  }

  &.AvaniBeerCard {
    position: relative;
    min-height: 208px;

    .AvaniAnimatedBackground,
    .AvaniCardContent {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      left: auto;
      bottom: 0;
      z-index: 1;
    }

    .AvaniCardContent .MuiTypography-root.MuiTypography-h3 {
      float: left;
      width: 100%;
      margin-top: 28px;
      padding-bottom: 16px;
    }

    .AvaniCardContent .MuiTypography-root.MuiTypography-h3.SmallSize {
      margin-top: 6px;
    }

    &.AvaniTotalBeerCard .AvaniCardContent .MuiTypography-root.MuiTypography-h3 {
      margin-top: 52px;
    }

    .AvaniAnimatedBackground {
      top: auto;
      bottom: -9px;
      z-index: 0;
    }
  }
`);

export const AvaniSavedBeerCard: React.FC<AvaniSavedBeerCardProps> = ({
  title,
  beerSaved,
  total,
  text,
  sx,
}) => {
  const theme = useTheme();
  const CardEl = React.useRef<HTMLDivElement>(null);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const [ svgHeight, setSvgHeight ] = React.useState((CardEl && CardEl.current) ? (CardEl.current.clientHeight - 39) : 188);
  const [ svgWidth, setSvgWidth ] = React.useState((CardEl && CardEl.current) ? CardEl.current.clientWidth : 251);

  React.useEffect(() => {
    const onResize = () => {
      setSvgHeight((CardEl && CardEl.current) ? (CardEl.current.clientHeight - 39) : 188);
      setSvgWidth((CardEl && CardEl.current) ? CardEl.current.clientWidth : Math.round(svgHeight * 1.34));
    };

    onResize();

    window.addEventListener('resize', onResize);

    if (!CardEl.current) {
      return window.removeEventListener("resize", onResize);
    } else {
      const resizeObserver = new ResizeObserver(() => {
        onResize();
      });

      resizeObserver.observe(CardEl.current);

      return () => {
        window.removeEventListener("resize", onResize);
        resizeObserver.disconnect();
      }
    }
  }, []);

  return (
    <AvaniSavedBeerStyledCard
      raised={true}
      className={`AvaniBeerCard ${total? 'AvaniTotalBeerCard': ''}`}
      ref={CardEl}
      sx={{
        height: '100%',
        backgroundColor: theme.colors.avani.lightTurc,
        ['.MuiPieArc-root']: {stroke: 'none'},
        ['.SavedBeerSpinner']: {
          float: 'right',
          clear: 'both',
          textAlign: 'right',
          marginTop: total ? 1 : 0,

          [theme.breakpoints.down('lg')]: {
            paddingRight: '12px',
          },
        },
        ...sx,
      }}
    >
      <Box className={'AvaniAnimatedBackground'}>
        <Slide
          direction="up"
          in={true}
          mountOnEnter
          unmountOnExit
          timeout={{
            enter: 880,
            exit: 2000,
          }}
        >
          <Paper
            elevation={0}
            sx={{
              padding: 0,
              backgroundColor: 'transparent',
              height: '100%'
            }}
            component={'div'}
          >
            <svg width={svgWidth} height={svgHeight} viewBox={`0 0 ${svgWidth} ${svgHeight}`} fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d={`M0.00021413 83C0.000166991 7.99995 ${svgWidth} 84.9999 ${svgWidth} 13.9999C${svgWidth} -45.2168 ${svgWidth} 99.6879 ${svgWidth} 188.084V195H0.000222214L0.000122078 194C0.000133093 182.448 0.000225314 16.206 0.00021413 83Z`}
                fill={total? theme.colors.avani.aDarkPink : theme.colors.avani.aYellow}
              />
            </svg>
          </Paper>
        </Slide>
      </Box>
      <Box className={'AvaniCardContent'}>
        <Grid
          container
          direction={"row"}
          spacing={0}
          justifyContent={'end'}
        >
          <Grid item xs>
            <div className={'SavedBeerSpinner'}>
              <AvaniNumberSpin
                numberValue={beerSaved}
                fSize={total? 38 : 70}
              />
            </div>
            <Typography
              variant="h3"
              className={isSmallScreen ? 'SmallSize': ''}
              sx={{
                color: total? 'white' : theme.colors.avani.teal,
                textAlign: 'left'
              }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item marginTop={2} marginRight={'24px'} marginLeft={'12px'}>
            <svg width="28" height="37" viewBox="0 0 28 37" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.33315 0C1.68414 0 1.0643 0.267383 0.62677 0.737109C0.189236 1.20684 -0.044115 1.83555 0.00693063 2.47871L2.10709 31.6307C2.32586 34.6586 4.86356 37 7.92629 37H20.0751C23.1379 37 25.6756 34.6586 25.8943 31.6307L27.9945 2.47871C28.0383 1.83555 27.8195 1.20684 27.3747 0.737109C26.9298 0.267383 26.3173 0 25.6683 0H2.33315ZM5.16983 9.25L4.84168 4.625H23.1598L22.8243 9.25H5.16983Z" fill="#005665"/>
            </svg>
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          fontSize={14}
          sx={{
            padding: 2,
            paddingY: 0,
            color: total? 'white' : theme.colors.primary
          }}
        >
          {text}
        </Typography>
      </Box>
    </AvaniSavedBeerStyledCard>
  );
};
