import * as React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from '@mui/material';

import {
  ExpandMore,
} from '@mui/icons-material';

import { BestPracticeObservations } from 'src/components/DataDisplay/BestPracticeObservations';
import { MediaModel } from 'src/models/types/medias.types';

type BestPracticeComment = {
  type: string,
  subType?: string,
  customSubType?: string,
  staticContent: string | undefined,
  authorName: string,
  content: string,
  media: MediaModel[],
}

type BestPracticeBlockProps = {
  data?: BestPracticeComment,
};

export const BestPracticeBlock:React.FC<BestPracticeBlockProps> = ({
  data,
}) => {
  const theme = useTheme();

  return (
    <div style={{marginTop: data ? '36px': 0}}>
      {data &&
      <Accordion
        defaultExpanded
        sx={{
          background: 'transparent'
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore fontSize={'large'} />}
          sx={{
            padding: 0,
            borderRadius: 0,
          }}
        >
          <Typography variant={'h2'} sx={{
            color: theme.colors.avani.teal,
          }}>
            Best Practice Observations
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: 0,
          }}
        >
          <BestPracticeObservations
            title={data.subType}
            author={data.authorName}
            images={data.media}
            explanationText={data.staticContent}
            descriptionText={data.content}
          />
        </AccordionDetails>
      </Accordion>
      }
    </div>
  )
};
