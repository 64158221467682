import { Loader } from './loader.routes';
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';
import SidebarLayout from 'src/layouts/SidebarLayout';
import ProtectedRoute from './Components/ProtectedRoute';
import { TechnicalSupportProvidersProvider } from '../../contexts/TechnicalSupportProvidersContext';

const TechnicalSupportProviderList = Loader(
  lazy(() => import('src/content/pages/Users/TechnicalSupportProvidersList'))
);

const TechnicalSupportProviderCreate = Loader(
  lazy(() => import('src/content/pages/Users/TechnicalSupportProviderCreate'))
);

export const createTspRoutes = () => ({
  path: 'technical-support-providers',
  element: <SidebarLayout />,
  children: [
    {
      path: '',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <Navigate to="/technical-support-providers/create" replace />
        </ProtectedRoute>
      )
    },
    {
      path: 'create',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['systemSuperAdmin', 'avaniAdmin']}>
          <TechnicalSupportProvidersProvider>
            <TechnicalSupportProviderCreate />
          </TechnicalSupportProvidersProvider>
        </ProtectedRoute>
      )
    },
    {
      path: 'list',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <TechnicalSupportProvidersProvider>
            <TechnicalSupportProviderList />
          </TechnicalSupportProvidersProvider>
        </ProtectedRoute>
      )
    }
  ]
});
