import * as React from 'react';
import { lazy } from 'react';

import { Navigate, useLocation, useParams } from 'react-router-dom';
import { Loader } from '../loader.routes';
import { useAuthContext } from 'src/contexts/AuthContext';
import SuspenseLoader from 'src/components/LoadingIndicators/SuspenseLoader';
import { getVisitInfo } from 'src/models/api-calls/visits.api';
import { ProCleanPlusDashboard } from 'src/content/pages/Dashboards/ProCleanPLUSDashboard';
import { VisitModel } from 'src/models/types/visits.types';

const FaultsDashboard = Loader(lazy(() => import('src/content/pages/Dashboards/FaultsDashboard')));
/* const SOS1Dashboard = Loader(lazy(() => import('src/content/pages/Dashboards/SOS1Dashboard')));
const SOS2Dashboard = Loader(lazy(() => import('src/content/pages/Dashboards/SOS2Dashboard')));
const SOS3Dashboard = Loader(lazy(() => import('src/content/pages/Dashboards/SOS3Dashboard')));

const WinterisationDashboard = Loader(lazy(() => import('src/content/pages/Dashboards/WinterisationDashboard')));
const DewinterisationDashboard = Loader(lazy(() => import('src/content/pages/Dashboards/DewinterisationDashboard')));

const ChillPourDashboard = Loader(lazy(() => import('src/content/pages/Dashboards/ChillPourDashboard')));
const ProSpecDashboard = Loader(lazy(() => import('src/content/pages/Dashboards/ProSpecDashboard')));
 */
export const DashboardRoute = (): JSX.Element => {
  const [visit, setVisit] = React.useState<VisitModel>();
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();
  const { auth, awsAuth } = useAuthContext();

  if (!awsAuth) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }
  if (!auth) {
    return <SuspenseLoader />;
  }

  const { visitId } = useParams<{ visitId: string }>();

  React.useEffect(() => {
    if (!visitId) return;

    (async () => {
      setLoading(true);
      const visitData = await getVisitInfo(visitId);
      setVisit(visitData);
      setLoading(false);
    })();
  }, [visitId]);
  return (
    <>
      {(!visit || loading) && <SuspenseLoader />}
      {!!visit && visit.serviceName === 'ProClean PLUS' && <ProCleanPlusDashboard visitType={visit.serviceName} />}
      {!!visit && visit.serviceName === 'ProClean' && <ProCleanPlusDashboard visitType={visit.serviceName} />}
    </>
  );
};
