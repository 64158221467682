import * as React from 'react';
import { createContext, FC, useState } from 'react';
import { VisitsClientReportData } from '../../models/types/reports.types';
import { DateTime } from 'luxon';
import { ClientModel } from '../../models/types/clients.types';

interface IReportsContext {
  loading: boolean;
  setLoading: (val: boolean) => void;
  dataUpdate: boolean;
  toggleUpdate: () => void;
  data: VisitsClientReportData[];
  setData: (val: VisitsClientReportData[]) => void;
  page: number;
  setPage: (val: number) => void;
  limit: number;
  setLimit: (val: number) => void;
  count: number;
  setCount: (val: number) => void;
  fromDate: DateTime | null;
  setFromDate: (val: DateTime | null) => void;
  toDate: DateTime | null;
  setToDate: (val: DateTime | null) => void;
  client: ClientModel | null;
  setClient: (val: ClientModel | null) => void;
}

export const VisitsClientContext = createContext<IReportsContext>({} as IReportsContext);

export const VisitsClientProvider: FC = ({ children }) => {
  const [dataUpdate, setDataUpdate] = useState(false);
  const [data, setData] = useState<VisitsClientReportData[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = React.useState<DateTime | null>(null);
  const [toDate, setToDate] = React.useState<DateTime | null>(null);
  const [client, setClient] = useState<ClientModel | null>(null);

  const toggleUpdate = (): void => setDataUpdate(!dataUpdate);

  return (
    <VisitsClientContext.Provider
      value={{
        data,
        setData,
        page,
        setPage,
        limit,
        setLimit,
        count,
        setCount,
        loading,
        setLoading,
        dataUpdate,
        toggleUpdate,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        client,
        setClient
      }}
    >
      {children}
    </VisitsClientContext.Provider>
  );
};
