import { createContext, FC, useState } from 'react';
import { VenueFiltersProps, VenueModel } from '../models/types/venues.types';
import { ServiceModel } from 'src/models/types/services.types';
import { SubscriptionModel } from 'src/models/types/subscriptions.types';
import { CellarModel } from '../models/types/cellars.types';
import { BarModel } from '../models/types/bars.types';
import { RemoteCoolerModel } from '../models/types/remote-coolers.types';

interface IVenuesContext {
  remotes: RemoteCoolerModel[];
  setRemotes: (val: RemoteCoolerModel[]) => void;
  cellars: CellarModel[];
  setCellars: (val: CellarModel[]) => void;
  bars: BarModel[];
  setBars: (val: BarModel[]) => void;
  venues: VenueModel[];
  setVenues: (val: VenueModel[]) => void;
  defaultSubscriptions: SubscriptionModel[];
  setDefaultSubscriptions: (val: SubscriptionModel[]) => void;
  subscriptions: SubscriptionModel[];
  setSubscriptions: (val: SubscriptionModel[]) => void;
  services: ServiceModel[];
  setServices: (val: ServiceModel[]) => void;
  refreshData: boolean;
  toggleUpdate: () => void;
  setRefreshData: (val: boolean) => void;
  filters: VenueFiltersProps;
  setFilters: (val: VenueFiltersProps) => void;
  resetFilters: () => void;
}

export const VenuesContext = createContext<IVenuesContext>({} as IVenuesContext);

export const VenuesProvider: FC = ({ children }) => {
  const [venues, setVenues] = useState<VenueModel[]>([]);
  const [defaultSubscriptions, setDefaultSubscriptions] = useState<SubscriptionModel[]>([]);
  const [subscriptions, setSubscriptions] = useState<SubscriptionModel[]>([]);
  const [services, setServices] = useState<ServiceModel[]>([]);
  const [cellars, setCellars] = useState<CellarModel[]>([]);
  const [bars, setBars] = useState<BarModel[]>([]);
  const [remotes, setRemotes] = useState<RemoteCoolerModel[]>([]);
  const [refreshData, setRefreshData] = useState(true);
  const toggleUpdate = (): void => setRefreshData(!refreshData);
  const emptyFilters = {
    clientName: undefined,
    town: undefined,
    county: undefined,
    venueType: undefined,
    capacity: undefined
  };
  const [filters, setFilters] = useState<VenueFiltersProps>(emptyFilters);
  const resetFilters = (): void => {
    setFilters(emptyFilters);
  };

  return (
    <VenuesContext.Provider
      value={{
        filters,
        setFilters,
        resetFilters,
        refreshData,
        toggleUpdate,
        setRefreshData,
        cellars,
        setCellars,
        bars,
        setBars,
        remotes,
        setRemotes,
        venues,
        setVenues,
        services,
        setDefaultSubscriptions,
        setServices,
        setSubscriptions,
        subscriptions,
        defaultSubscriptions
      }}
    >
      {children}
    </VenuesContext.Provider>
  );
};
