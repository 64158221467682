import { FC, ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import SuspenseLoader from 'src/components/LoadingIndicators/SuspenseLoader';
import { useAuthContext } from 'src/contexts/AuthContext';
import { RoleModel } from '../../../models/types/roles.types';

export type GroupType = 'avani' | 'clients' | 'brewers';

interface ProtectedRouteProps {
  children: ReactElement;
  groupRequired: GroupType[] | null;
  roleRequired: string[] | null;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, groupRequired, roleRequired }) => {
  const location = useLocation();
  const { auth, awsAuth } = useAuthContext();
  if (!awsAuth) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }
  if (!auth) {
    return <SuspenseLoader />;
  }
  const isGroupAllowed = groupRequired === null || groupRequired.some((group) => group === auth.significantGroup);
  if (!isGroupAllowed) {
    return <Navigate to="/404" state={{ from: location }} replace />;
  }

  if (!isRoleAllowed(roleRequired, auth.roles)) {
    return <Navigate to="/404" state={{ from: location }} replace />;
  }
  return children;
};

export function isRoleAllowed(roleRequired: string[] | null, roles: RoleModel[]) {
  if (!roleRequired || (roleRequired.length == 1 && roleRequired[0] === '*')) return true;
  return roles.some((authRole) => roleRequired.includes(authRole.name));
}

export default ProtectedRoute;
