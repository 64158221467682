export const GENERAL_BLOCK = {
  currentVisit: {
    startedAt: '2024-05-30',
    serviceName: 'ProClean PLUS',
    technicianNames: [
      "Tom Griffiths", "Tom Griffiths"
    ]
  },
  preCleanResults: {
    data: [
      { value: 10, label: 'A' },
      { value: 5, label: 'B' },
      { value: 5, label: 'C' },
      { value: 1, label: 'D' },
    ],
    total: 21,
  },
  faults: {
    unresolved: 2,
    new: 1,
  },
  ranking: {
    ranking: 141,
    lastVisit: 214,
    change: '+23',
  },
  temperature: {
    highTempWarning: false,
  },
  savedBeer: 47,
  perfectPintImages: [
    'https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg',
  ],
  safetyCheckImages: [
    'https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg',
    'https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg',
    'https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg',
  ],
}

export const GENERAL_BLOCK_NEW = {
  currentVisit: {
    startedAt: '2024-05-30',
    serviceName: 'ProClean PLUS',
    technicianNames: [
      "Tom Griffiths", "Tom Griffiths"
    ]
  },
  preCleanResults: {
    data: [
      { value: 10, label: 'A' },
      { value: 5, label: 'B' },
      { value: 5, label: 'C' },
      { value: 1, label: 'D' },
    ],
    total: 21,
  },
  faults: {
    unresolvedFaults: 2,
    newFaults: 1,
  },
  ranking: {
    ranking: 141,
    lastVisit: 214,
    change: '+23',
  },
  temperature: {
    outOfRangeWarning: false,
  },
  savedBeer: 47,
  perfectPintImages: [
    {
      fileType: 'video/...',
      contentUrl: 'https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg',
    },
  ],
  safetyCheckImages: [
    'https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg',
    'https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg',
    'https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg',
  ],
}

export const PRE_CLEAN_BLOCK = {
  visits: {
    dates: [ '2024-05-30', '2024-04-24', '2024-03-26' ,'2023-12-24' ],
    data: [
      {
        data: [
          75, 80, 90, 86
        ],
        id: 'a',
        label: 'a'
      },
      {
        data: [
          70, 78, 94, 67
        ],
        id: 'b',
        label: 'b'
      },
      {
        data: [
          58, 74, 98, 46
        ],
        id: 'c',
        label: 'c',
      },
      {
        data: [
          65, 77, 92, 61
        ],
        id: 'd',
        label: 'd'
      },
    ]
  },
  bars: {
    dates: ['Lobby Bar', 'Rooftop Bar', 'Restaurant', 'Garden Bar'],
    data: [
      {
        data: [
          75, 80, 90, 86
        ],
        stack: 'total',
        label: 'a'
      },
      {
        data: [
          70, 78, 94, 67
        ],
        stack: 'total',
        label: 'b'
      },
      {
        data: [
          58, 74, 98, 46
        ],
        stack: 'total',
        label: 'c'
      },
      {
        data: [
          65, 77, 92, 61
        ],
        stack: 'total',
        label: 'd'
      },
    ]
  },
  outOfTrendProducts: [
    {
      name: 'Weihenstephaner Weihenstephaner Weihenstephaner Weihenstephaner',
    },
    {
      name: 'Tennents',
    },
    {
      name: 'Pravha',
    },
    {
      name: 'Weihenstephaner',
    },
    {
      name: 'Tennents',
    },
    {
      name: 'Pravha',
    }
  ]
}

export const PRE_CLEAN_BLOCK_NEW = {
  visits: {
    visitDates: [ '2024-05-30', '2024-04-24', '2024-03-26' ,'2023-12-24' ],
    data: [
      {
        sumOfRatingByDate: [
          75, 80, 90, 86
        ],
        rating: 'a'
      },
      {
        data: [
          70, 78, 94, 67
        ],
        id: 'b',
        label: 'b'
      },
      {
        data: [
          58, 74, 98, 46
        ],
        id: 'c',
        label: 'c',
      },
      {
        data: [
          65, 77, 92, 61
        ],
        id: 'd',
        label: 'd'
      },
    ]
  },
  bars: {
    bars: ['Lobby Bar', 'Rooftop Bar', 'Restaurant', 'Garden Bar'],
    data: [
      {
        data: [
          75, 80, 90, 86
        ],
        label: 'a'
      },
      {
        data: [
          70, 78, 94, 67
        ],
        label: 'b'
      },
      {
        data: [
          58, 74, 98, 46
        ],
        label: 'c'
      },
      {
        data: [
          65, 77, 92, 61
        ],
        label: 'd'
      },
    ]
  },
  outOfTrendProducts: [
    {
      name: 'Weihenstephaner Weihenstephaner Weihenstephaner Weihenstephaner',
    },
    {
      name: 'Tennents',
    },
    {
      name: 'Pravha',
    },
    {
      name: 'Weihenstephaner',
    },
    {
      name: 'Tennents',
    },
    {
      name: 'Pravha',
    }
  ]
}

export const TEMPERATURE_BLOCK_NEW = {
  dates: PRE_CLEAN_BLOCK.visits.dates,
  bars: [
    {
      temp: {
        title: 'Ground Cellar',
        desc: 'Normal',
        date: '29/05/2023',
        value: 3,
        isOor: false,
        inGlass: false,
        lines: {
          data: [
            { value: 1, label: 'A' },
            { value: 2, label: 'B' },
            { value: 2, label: 'C' },
            { value: 0, label: 'D' },
          ],
          total: 5,
        }
      },
    },
    {
      temp: {
        title: 'Basement Cellar',
        desc: 'Normal',
        date: '29/05/2023',
        value: 4,
        isOor: false,
        inGlass: false,
        lines: {
          data: [
            { value: 2, label: 'A' },
            { value: 1, label: 'B' },
            { value: 0, label: 'C' },
            { value: 0, label: 'D' },
          ],
          total: 3,
        }
      },
    },
    {
      temp: {
        title: 'Main Remote',
        desc: 'Out of Range',
        value: 8,
        date: '29/05/2023',
        isOor: true,
        inGlass: false,
        lines: {
          data: [
            { value: 10, label: 'A' },
            { value: 5, label: 'B' },
            { value: 5, label: 'C' },
            { value: 1, label: 'D' },
          ],
          total: 21,
        },
        errors: [
          {
            name: 'Ice Bank',
            desc: 'fail',
          },
        ]
      },
    },
    {
      temp: {
        title: 'Lobby Bar',
        desc: 'Normal',
        date: '29/05/2023',
        value: true,
        isOor: false,
        inGlass: true,
        products: [
          {
            name: 'Heineken',
            value: 2,
          },
          {
            name: 'Tennents',
            value: 3,
          }
        ],
      },
    },
    {
      temp: {
        title: 'Sky Bar',
        desc: 'Out of Range',
        date: '29/05/2023',
        value: false,
        isOor: true,
        inGlass: true,
        products: [
          {
            name: 'Bernard',
            value: 5,
          },
          {
            name: 'Amstel',
            value: 7,
            isOor: true,
          }
        ],
      },
    },
    {
      temp: {
        title: 'Ground Cellar',
        desc: 'Normal',
        date: '29/05/2023',
        value: 3,
        isOor: false,
        inGlass: false,
        lines: {
          data: [
            { value: 1, label: 'A' },
            { value: 2, label: 'B' },
            { value: 2, label: 'C' },
            { value: 0, label: 'D' },
          ],
          total: 5,
        }
      },
    },
    {
      temp: {
        title: 'Basement Cellar',
        desc: 'Normal',
        date: '29/05/2023',
        value: 4,
        isOor: false,
        inGlass: false,
        lines: {
          data: [
            { value: 2, label: 'A' },
            { value: 1, label: 'B' },
            { value: 0, label: 'C' },
            { value: 0, label: 'D' },
          ],
          total: 3,
        }
      },
    },
    {
      temp: {
        title: 'Main Remote',
        desc: 'Out of Range',
        value: 8,
        date: '29/05/2023',
        isOor: true,
        inGlass: false,
        lines: {
          data: [
            { value: 10, label: 'A' },
            { value: 5, label: 'B' },
            { value: 5, label: 'C' },
            { value: 1, label: 'D' },
          ],
          total: 21,
        },
        errors: [
          {
            name: 'Ice Bank',
            desc: 'fail',
          },
        ]
      },
    },
    {
      temp: {
        title: 'Lobby Bar',
        desc: 'Normal',
        date: '29/05/2023',
        value: true,
        isOor: false,
        inGlass: true,
        products: [
          {
            name: 'Heineken',
            value: 2,
          },
          {
            name: 'Tennents',
            value: 3,
          }
        ],
      },
    },
    {
      temp: {
        title: 'Sky Bar',
        desc: 'Out of Range',
        date: '29/05/2023',
        value: false,
        isOor: true,
        inGlass: true,
        products: [
          {
            name: 'Bernard',
            value: 5,
          },
          {
            name: 'Amstel',
            value: 7,
            isOor: true,
          }
        ],
      },
    }
  ],
  devices: [
    {
      name: 'Main Remote',
      type: 'Remote Cooler',
      data: [
        6, -1, -3, 0
      ]
    },
    {
      name: 'Main Remote 2',
      type: 'Remote Cooler',
      data: [
        2, 1, 6, 3
      ]
    },
    {
      name: 'Side Remote',
      type: 'Remote Cooler lancer',
      data: [
        3, -4, 1, -2
      ]
    },
    {
      name: 'Basement Cellar',
      type: 'Cellar',
      data: [
        11, 8, 16, 12.5
      ]
    },
    {
      name: 'Ground Cellar',
      type: 'Cellar',
      data: [
        13, 11, 12.5, 11
      ],
    },
  ],
  tempRanges: [
    {
      ref: 'Cellar',
      min: 10,
      max: 14,
      name: 'Recommended Cellar Range'
    },
    {
      ref: 'Remote Cooler',
      min: -2,
      max: 2,
      name: 'Recommended Remote Range'
    },
    {
      ref: 'Remote Cooler lancer',
      min: -1,
      max: 3,
      name: 'Recommended Remote Range'
    },
  ],
  dispenseTempsData: [
    {
      name: 'Dispense',
      data: [8, 12, 17.5, 11],
    }
  ],
  lastUpdated: {
    type: 'Nqkf Enum',
    authorName: 'Tom Griffits',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris.',
  },
};

export const TEMPERATURE_BLOCK = {
  dates: PRE_CLEAN_BLOCK.visits.dates,
  bars: [
    {
      temp: {
        title: 'Ground Cellar',
        desc: 'Normal',
        date: '29/05/2023',
        value: 3,
        isOor: false,
        inGlass: false,
        lines: {
          data: [
            { value: 1, label: 'A' },
            { value: 2, label: 'B' },
            { value: 2, label: 'C' },
            { value: 0, label: 'D' },
          ],
          total: 5,
        }
      },
    },
    {
      temp: {
        title: 'Basement Cellar',
        desc: 'Normal',
        date: '29/05/2023',
        value: 4,
        isOor: false,
        inGlass: false,
        lines: {
          data: [
            { value: 2, label: 'A' },
            { value: 1, label: 'B' },
            { value: 0, label: 'C' },
            { value: 0, label: 'D' },
          ],
          total: 3,
        }
      },
    },
    {
      temp: {
        title: 'Main Remote',
        desc: 'Out of Range',
        value: 8,
        date: '29/05/2023',
        isOor: true,
        inGlass: false,
        lines: {
          data: [
            { value: 10, label: 'A' },
            { value: 5, label: 'B' },
            { value: 5, label: 'C' },
            { value: 1, label: 'D' },
          ],
          total: 21,
        },
        errors: [
          {
            name: 'Ice Bank',
            desc: 'fail',
          },
        ]
      },
    },
    {
      temp: {
        title: 'Lobby Bar',
        desc: 'Normal',
        date: '29/05/2023',
        value: true,
        isOor: false,
        inGlass: true,
        products: [
          {
            name: 'Heineken',
            value: 2,
          },
          {
            name: 'Tennents',
            value: 3,
          }
        ],
      },
    },
    {
      temp: {
        title: 'Sky Bar',
        desc: 'Out of Range',
        date: '29/05/2023',
        value: false,
        isOor: true,
        inGlass: true,
        products: [
          {
            name: 'Bernard',
            value: 5,
          },
          {
            name: 'Amstel',
            value: 7,
            isOor: true,
          }
        ],
      },
    },
    {
      temp: {
        title: 'Ground Cellar',
        desc: 'Normal',
        date: '29/05/2023',
        value: 3,
        isOor: false,
        inGlass: false,
        lines: {
          data: [
            { value: 1, label: 'A' },
            { value: 2, label: 'B' },
            { value: 2, label: 'C' },
            { value: 0, label: 'D' },
          ],
          total: 5,
        }
      },
    },
    {
      temp: {
        title: 'Basement Cellar',
        desc: 'Normal',
        date: '29/05/2023',
        value: 4,
        isOor: false,
        inGlass: false,
        lines: {
          data: [
            { value: 2, label: 'A' },
            { value: 1, label: 'B' },
            { value: 0, label: 'C' },
            { value: 0, label: 'D' },
          ],
          total: 3,
        }
      },
    },
    {
      temp: {
        title: 'Main Remote',
        desc: 'Out of Range',
        value: 8,
        date: '29/05/2023',
        isOor: true,
        inGlass: false,
        lines: {
          data: [
            { value: 10, label: 'A' },
            { value: 5, label: 'B' },
            { value: 5, label: 'C' },
            { value: 1, label: 'D' },
          ],
          total: 21,
        },
        errors: [
          {
            name: 'Ice Bank',
            desc: 'fail',
          },
        ]
      },
    },
    {
      temp: {
        title: 'Lobby Bar',
        desc: 'Normal',
        date: '29/05/2023',
        value: true,
        isOor: false,
        inGlass: true,
        products: [
          {
            name: 'Heineken',
            value: 2,
          },
          {
            name: 'Tennents',
            value: 3,
          }
        ],
      },
    },
    {
      temp: {
        title: 'Sky Bar',
        desc: 'Out of Range',
        date: '29/05/2023',
        value: false,
        isOor: true,
        inGlass: true,
        products: [
          {
            name: 'Bernard',
            value: 5,
          },
          {
            name: 'Amstel',
            value: 7,
            isOor: true,
          }
        ],
      },
    }
  ],
  devices: [
    {
      name: 'Main Remote',
      type: 'Remote Cooler',
      data: [
        6, -1, -3, 0
      ]
    },
    {
      name: 'Main Remote 2',
      type: 'Remote Cooler',
      data: [
        2, 1, 6, 3
      ]
    },
    {
      name: 'Side Remote',
      type: 'Remote Cooler lancer',
      data: [
        3, -4, 1, -2
      ]
    },
    {
      name: 'Basement Cellar',
      type: 'Cellar',
      data: [
        11, 8, 16, 12.5
      ]
    },
    {
      name: 'Ground Cellar',
      type: 'Cellar',
      data: [
        13, 11, 12.5, 11
      ],
    },
  ],
  tempRanges: [
    {
      ref: 'Cellar',
      min: 10,
      max: 14,
      name: 'Recommended Cellar Range'
    },
    {
      ref: 'Remote Cooler',
      min: -2,
      max: 2,
      name: 'Recommended Remote Range'
    },
    {
      ref: 'Remote Cooler lancer',
      min: -1,
      max: 3,
      name: 'Recommended Remote Range'
    },
  ],
  dispenseTempsData: [
    {
      name: 'Dispense',
      data: [8, 12, 17.5, 11],
    }
  ],
  lastUpdated: {
    author: 'Tom Griffits',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris.',
  },
};

export const WASTAGE_BLOCK = {
  efficiencyScore: {
    total: 30,
    saved: 14,
    percent: 45,
    lines: {
      empty: 3,
      full: 5,
      partial: 2,
    },
  },
  saved: {
    total: 386,
    proClean: 231,
    aleaGlasgow: 159,
  },
  byProduct: [
    {
      name: 'Weihenstephaner',
      value: 15,
    },
    {
      name: 'Tennents',
      value: 4
    },
    {
      name: 'Pravha',
      value: 11.5,
    },
    {
      name: 'Weihenstephaner',
      value: 15,
    },
    {
      name: 'Tennents',
      value: 4
    },
    {
      name: 'Pravha',
      value: 11.5,
    },
    {
      name: 'Weihenstephaner',
      value: 15,
    },
    {
      name: 'Tennents',
      value: 4
    },
    {
      name: 'Pravha',
      value: 11.5,
    },
  ],
  perVisit: [
    {
      date: 'Current Visit',
      available: 17,
      total: 27,
    },
    {
      date: '24/04/2024',
      available: 39,
      total: 45,
    },
    {
      date: '26/03/2024',
      available: 49,
      total: 75,
    },
    {
      date: '24/02/2024',
      available: 59,
      total: 89,
    },
  ],
  lastUpdated: {
    author: 'Tom Griffits',
    date: '30/05/2024',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris.',
  },
};

export const HYGIENE_BLOCK = {
  nozzles: {
    isOor: true,
    bars: [
      {
        name: 'Lobby bar',
        isOor: false,
      },
      {
        name: 'Ground Cellar',
        isOor: true,
      },
      {
        name: 'Main Cellar',
        isOor: false,
      },
      {
        name: null,
      },
    ],
    trend: [
      {
        date: '12/04',
        isOor: true,
      },
      {
        date: '05/03',
      },
      {
        date: '16/02',
        isOor: true,
      },
      {
        date: '20/01',
      },
      {
        date: '02/12',
      },
    ],
    photos: [
      'https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg',
    ],
  },
  glassWare: {
    isOor: false,
    bars: [
      {
        name: 'Lobby bar',
        isOor: false,
      },
      {
        name: 'Ground Cellar',
        isOor: true,
      },
      {
        name: 'Main Cellar',
        isOor: false,
      }
    ],
    trend: [
      {
        date: '12/04',
        isOor: true,
      },
      {
        date: '05/03',
      },
      {
        date: '16/02',
        isOor: true,
      },
      {
        date: '20/01',
      },
      {
        date: '02/12',
      },
    ],
  },
  glassWashers: {
    isOor: false,
    glassWashers: [
      {
        name: 'Lobby bar',
        isOor: false,
      },
      {
        name: 'Ground Cellar',
        isOor: false,
      },
      {
        name: 'Main Cellar',
        isOor: false,
      }
    ],
    trend: [
      {
        date: '02/12',
      },
      {
        date: '20/01',
      },
      {
        date: '16/02',
        isOor: true,
      },
      {
        date: '05/03',
      },
      {
        date: '12/04',
        isOor: true,
      },
    ],
  },
}

export const BEST_PRACTICE_OBSERVATIONS_BLOCK = {
  author: 'Lewis Snowling',
  title: 'Nozzle Hygiene',
  images: [
    'https://www.chowhound.com/img/gallery/the-role-of-your-beer-glass-is-more-important-than-you-think/intro-1697137116.webp',
    'https://learnkegs.wpenginepowered.com/wp-content/uploads/2016/01/different-beer-glasses.jpg',
    'https://www.bottleneckmgmt.com/wp-content/uploads/2016/02/900x600-Types-of-Beer-Glasses.png'
  ],
  explanationText: 'This text explains why this category of best practice is recommended. Lorem ipsum dolor sit amet, consectetur d ligula venenatis, bibendum velit in.',
  descriptionText: 'This is the text written by the technician explaining what they`ve observed. Etiam non facilisis urna, eu eleifend est. Maecenas vitae leo vitae quam placerat vulputate sit amet non leo.',
};

export const FAULTS_BLOCK = {
  faults: [
    {
      title: 'Nozzle',
      priority: 'Regular',
      status: {
        type: 'Resolved'
      },
      dates: [
        '2024-07-29',
        '2024-06-28',
        '2024-05-14',
      ],
      type: 'Technical Fault',
      technician: 'Lewis Snowling',
      asignee: 'Tom Griffits',
      description: '<a href="#">Maecenas finibus justo lectus, id cursus libero lobortis pharetra. Duis sit amet magna condimentum <br /> dictum vel eu risus.',
      recommendation: 'In sodales venenatis venenatis. Duis sit amet neque enim. Fusce nec venenatis tellus, sit amet blandit purus. Donec quis nulla dictum, finibus l, In sodales venenatis venenatis. Duis sit amet neque enim. Fusce nec venenatis tellus, sit amet blandit purus. Donec quis nulla dictum, finibus l ,In sodales venenatis venenatis. Duis sit amet neque enim. Fusce nec venenatis tellus, sit amet blandit purus. Donec quis nulla dictum, finibus l,', 
    },
    {
      title: 'Remote Cooler 5 Water Bath',
      priority: 'Critical',
      status: {
        type: 'Unresolved',
        duration: '56 days'
      },
      dates: null,
      type: 'Operations Fault',
      technician: 'Lewis Snowling',
      asignee: 'Tom Griffits',
      description: 'Maecenas finibus justo. ',
      recommendation: 'In sodales venenatis venenatis. Duis sit amet neque enim. Fusce nec venenatis tellus, sit amet blandit purus. Donec quis nulla dictum, finibus l', 
    },
    {
      title: 'Beer Pump',
      priority: 'Regular',
      status: {
        type: 'Resolved',
      },
      dates: [
        '2024-07-29',
        '2024-06-28',
        '2024-05-14',
      ],
      type: 'Technical Fault',
      technician: 'Lewis Snowling',
      asignee: 'Tom Griffits',
      description: 'Maecenas finibus justo lectus, id cursus libero lobortis pharetra. Duis sit amet magna condimentum tortor facilisis dictum vel eu risus. Maecenas finibus justo lectus, id cursus libero lobortis pharetra. Duis sit amet magna condimentum tortor facilisis dictum vel eu risus. Maecenas finibus justo lectus, id cursus libero lobortis pharetra. Duis sit amet magna condimentum tortor facilisis dictum vel eu risus. Maecenas finibus justo lectus, id cursus libero lobortis pharetra. Duis sit amet magna condimentum tortor facilisis dictum vel eu risus. Maecenas finibus justo lectus, id cursus libero lobortis pharetra. Duis sit amet magna condimentum tortor facilisis dictum vel eu risus. ',
      recommendation: 'In sodales venenatis venenatis.', 
    },
    {
      title: 'Nozzle',
      priority: 'Regular',
      status: {
        type: 'Resolved'
      },
      dates: [
        '2024-06-14',
        '2024-05-05',
      ],
      type: 'Technical Fault',
      technician: 'Lewis Snowling',
      asignee: 'Tom Griffits',
      description: 'Maecenas finibus justo lectus, id cursus libero lobortis pharetra. Duis sit amet magna condimentum tortor facilisis dictum vel eu risus. ',
      recommendation: 'In sodales venenatis venenatis. Duis sit amet neque enim. Fusce nec venenatis tellus, sit amet blandit purus. Donec quis nulla dictum, finibus l', 
    },
    {
      title: 'Remote Cooler 5 Water Bath',
      priority: 'Critical',
      status: {
        type: 'Unresolved',
        duration: '56 days'
      },
      dates: [
        '2024-07-29',
      ],
      type: 'Operations Fault',
      technician: 'Lewis Snowling',
      asignee: 'Tom Griffits',
      description: 'Maecenas finibus justo lectus, id cursus libero lobortis pharetra. Duis sit amet magna condimentum tortor facilisis dictum vel eu risus. ',
      recommendation: 'In sodales venenatis venenatis. Duis sit amet neque enim. Fusce nec venenatis tellus, sit amet blandit purus. Donec quis nulla dictum, finibus l', 
    },
    {
      title: 'Beer Pump',
      priority: 'Regular',
      status: {
        type: 'Resolved',
      },
      dates: [
        '2024-07-29',
        '2024-06-28',
        '2024-05-14',
        '2024-04-29',
        '2024-03-28',
        '2024-02-14',
        '2024-01-30',
      ],
      type: 'Technical Fault',
      technician: 'Lewis Snowling',
      asignee: 'Tom Griffits',
      description: 'Maecenas finibus justo lectus, id cursus libero lobortis pharetra. Duis sit amet magna condimentum tortor facilisis dictum vel eu risus. ',
      recommendation: 'In sodales venenatis venenatis. Duis sit amet neque enim. Fusce nec venenatis tellus, sit amet blandit purus. Donec quis nulla dictum, finibus l', 
    },
  ]
};

//SOS MOCKS

export const SOSHYGIENE_BLOCK = {
  nozzles: {
    isOor: true,
    bars: [
      {
        name: 'Lobby bar',
        isOor: false,
      },
      {
        name: 'Ground Cellar',
        isOor: true,
      },
      {
        name: 'Main Cellar',
        isOor: false,
      }
    ],
    photos: [
      'https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg',
    ],
  },
  glassWare: {
    isOor: false,
    bars: [
      {
        name: 'Lobby bar',
        isOor: false,
      },
      {
        name: 'Ground Cellar',
        isOor: true,
      },
      {
        name: 'Main Cellar',
        isOor: false,
      }
    ],
  },
  glassWashers: {
    isOor: false,
    glassWashers: [
      {
        name: 'Lobby bar',
        isOor: false,
      },
      {
        name: 'Ground Cellar',
        isOor: false,
      },
      {
        name: 'Main Cellar',
        isOor: false,
      }
    ],
  },
  fobs: {
    isOor: true,
    photos: [
      'https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg',
      'https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg','https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg',
    ],
    bars: [
      {
        name: 'Lobby bar',
        isOor: false,
      },
      {
        name: 'Ground Cellar',
        isOor: true,
      },
      {
        name: 'Main Cellar',
        isOor: false,
      }
    ],
  },
  sockets: {
    isOor: true,
    photos: [
      'https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg',
      'https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg','https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg',
    ],
    bars: [
      {
        name: 'Lobby bar',
        isOor: false,
      },
      {
        name: 'Ground Cellar',
        isOor: true,
      },
      {
        name: 'Main Cellar',
        isOor: false,
      }
    ],
  },
  couplers: {
    isOor: true,
    percent: 39,
    photos: [
      'https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg',
      'https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg','https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg',
    ],
  },
  tapSeals: {
    isOor: false,
    percent: 28,
    photos: [
      'https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg',
      'https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg','https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg',
    ],
  },
  flushWaterClean: {
    isOor: false,
    percent: 100,
    description: 'Maecenas finibus justo lectus, id cursus libero lobortis pharetra. Duis sit amet magna condimentum tortor facilisis dictum vel eu risus. Maecenas finibus justo lectus, id cursus libero lobortis pharetra. Duis sit amet magna condimentum tortor facilisis dictum vel eu risus. Maecenas finibus justo lectus, id cursus libero lobortis pharetra. Duis sit amet magna condimentum tortor facilisis dictum vel eu risus. Maecenas finibus justo lectus, id cursus libero lobortis pharetra. Duis sit amet magna condimentum tortor facilisis dictum vel eu risus. Maecenas finibus justo lectus, id cursus libero lobortis pharetra. Duis sit amet magna condimentum tortor facilisis dictum vel eu risus. ',
    photos: [
      'https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg',
      'https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg','https://www.mbswholesale.co.uk/media/catalog/product/p/e/perfect_pint_2.jpg',
    ],
  },
}

export const TAINT = {
  total:{
    no_taint: 24,
    tainted: 13,
  },
  perTap: [
    {
      bar: 'Some Bar',
      tap: 'Tap 3',
      product: 'Heineken',
      taint_type_before: 'Chlorophenol',
      taint_results_after: 'Not Present (cleaned)',
    },
    {
      bar: 'Other Bar',
      tap: 'Tap 1',
      product: 'SomeGermanWeisseBier',
      taint_type_before: 'Chlorophenol',
      taint_results_after: 'Not Present (cleaned)',
    },
  ],
};

// ProSpec MOCKS

export const PS_VISIT_INFORMATION = {
  unresolvedFaults: 99,
  totals: {
    taps: 68,
    fobs: 95,
  },
};

export const PS_PRODUCT_SPECIFICATION = {
  equilibriumPressure: {
    outOfRangeCount: 38,
    totalCount: 53,
  },
  dispenseSpeed: {
    outOfRangeCount: 38,
    totalCount: 53,
  },
  tapSealType: {
    outOfRangeCount: 38,
    totalCount: 53,
    desc: 'Incorrect',
  },
  tapHygiene: {
    outOfRangeCount: 38,
    totalCount: 53,
    desc: 'Biofilm present',
  },
};

export const PS_CHECKS = {
  cellars: [
    {
      fob: 1,
      product: 'Strongbow Original',
      taps: 1,
      spec: '2.3 Vol @ 11.6°C, CO2 Gas = 19.5psi',
      length: '4pt',
      lift: '0 flr',
      before: {
        value: '21',
        isOor: true,
      },
      after: {
        value: '19.5',
        isOor: false,
      }, 
    },
    {
      fob: 1,
      product: 'Strongbow Original',
      taps: 1,
      spec: '2.3 Vol @ 11.6°C, CO2 Gas = 19.5psi',
      length: '4pt',
      lift: '0 flr',
      before: {
        value: '21',
        isOor: true,
      },
      after: {
        value: '19.5',
        isOor: false,
      }, 
    },
    {
      fob: 1,
      product: 'Strongbow Original',
      taps: 1,
      spec: '2.3 Vol @ 11.6°C, CO2 Gas = 19.5psi',
      length: '4pt',
      lift: '0 flr',
      before: {
        value: '21',
        isOor: true,
      },
      after: {
        value: '19.5',
        isOor: false,
      }, 
    },
    {
      fob: 1,
      product: 'Strongbow Original',
      taps: 1,
      spec: '2.3 Vol @ 11.6°C, CO2 Gas = 19.5psi',
      length: '4pt',
      lift: '0 flr',
      before: {
        value: '21',
        isOor: true,
      },
      after: {
        value: '19.5',
        isOor: false,
      }, 
    },
    {
      fob: 1,
      product: 'Strongbow Original',
      taps: 1,
      spec: '2.3 Vol @ 11.6°C, CO2 Gas = 19.5psi',
      length: '4pt',
      lift: '0 flr',
      before: {
        value: '21',
        isOor: true,
      },
      after: {
        value: '19.5',
        isOor: false,
      }, 
    },
  ],
  taps: [
    {
      tap: 1,
      tBar: 4,
      product: 'Carlsberg Pilsner',
      specRange: 'Min: 14 > Max: 18 sec/pt',
      before: {
        value: '16sec',
        isOor: true,
      },
      after: {
        value: '28sec',
        isOor: false,
      },
      typeBefore: {
        value: 'Full pour',
        isOor: true,
      },
      typeAfter: {
        value: 'Full pour',
        isOor: false,
      },
      hygiene: false,
    },
    {
      tap: 1,
      tBar: 4,
      product: 'Kopparberg Strawberry & Lime',
      specRange: 'Min: 14 > Max: 18 sec/pt',
      before: {
        value: '15sec',
        isOor: true,
      },
      after: {
        value: '12sec',
        isOor: false,
      },
      typeBefore: {
        value: 'Creamer',
        isOor: false,
      },
      typeAfter: {
        value: 'Creamer',
        isOor: true,
      },
      hygiene: true,
    },
  ],
};
