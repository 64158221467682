import * as React from 'react';

import { styled } from '@mui/material/styles';
import {
  Box,
  Card,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme
} from '@mui/material';

import { AvaniNumberSpin } from 'src/components/DataDisplay/AvaniNumberSpin';

interface AvaniRankingCardProps {
  title: string,
  rankingValue?: number,
  lastVisitValue?: number | string,
  changeValue?: number | string,
}

const AvaniRankingStyledCard = styled(Card)(
  ({ theme }) => `
  border-radius: 16px;
  padding: 16px 0 8px 0;

  .MuiTypography-root.MuiTypography-h3 {
    margin: 0 0 0 16px;
    line-height: 1;
    padding-bottom: 0;
  }

  .animated-container {
    margin-top: -11px;
  }
`);

export const AvaniRankingCard: React.FC<AvaniRankingCardProps> = ({
  title,
  rankingValue,
  lastVisitValue,
  changeValue,
}) => {
  const theme = useTheme();

  return (
    <AvaniRankingStyledCard
      raised={true}
      sx={{
        height: '100%',
        backgroundColor: theme.colors.avani.lightTurc,
        ['.MuiPieArc-root']: {stroke: 'none'},
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingRight: '11px',
          }}
        >
          <Typography
            variant="h3"
            color={theme.colors.avani.teal}
            sx={{
              flexGrow: 1,
            }}
          >
            {title}
          </Typography>
          {/* <AvaniNumberSpin fSize={30} numberValue={rankingValue} /> */}
        </Box>
        {/* <List
          dense
          sx={{
            padding: '0 0 0 18px',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <ListItem
            sx={{p: 0}}
            secondaryAction={
            <Typography variant='body2' fontSize={16}>
              {lastVisitValue}
            </Typography>
          }>
            <ListItemText>
              <Typography variant='body2' fontSize={16}>
                Last Visit
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            sx={{p: 0}}
            secondaryAction={
              <Typography fontSize={16} variant='body2'
                sx={{
                  color: theme.colors.avani.magenta
                }}
              >
                {changeValue}
              </Typography>
            }
          >
            <ListItemText>
              <Typography fontSize={16} variant='body2'
                sx={{
                  color: theme.colors.avani.magenta
                }}
              >
                Change
              </Typography>
            </ListItemText>
          </ListItem>
        </List> */}
        <Typography
          variant={'h3'}
          sx={{
            paddingTop: 8,
            textAlign: 'center'
          }}
        >
          Coming Soon
        </Typography>
      </Box>
    </AvaniRankingStyledCard>
  );
};
