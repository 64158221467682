import * as React from 'react';

import {
  Avatar,
  Grid,
  List,
  ListItem,
  Typography,
  useTheme,
} from '@mui/material';

import { AvaniCard } from 'src/components/Cards/AvaniCard';
import { ImageViewer } from '../Dialogs/ImageViewer/ImageViewer';
import { MediaModel } from 'src/models/types/medias.types';
type BestPracticeObservationsProps = {
  author: string,
  title: string | undefined,
  images: MediaModel[],
  explanationText: string | undefined,
  descriptionText: string | undefined,
};

export const BestPracticeObservations: React.FC<BestPracticeObservationsProps> = ({
  author,
  title,
  images,
  explanationText,
  descriptionText,
}) => {
  const theme = useTheme();

  return (
    <AvaniCard grey padded>
      <Grid container spacing={1} columns={3}>
        <Grid item xs={3} lg={1}>
          <Typography
            variant={'body2'}
            fontWeight={'bold'}
            fontSize={18}
          >
            {author}
          </Typography>
          <Typography
            variant={'h5'}
            fontSize={24}
            color={theme.colors.avani.teal}
            sx={{
              pb: '12px'
            }}
          >
            {title}
          </Typography>
          <List disablePadding>
            {images.map((imageData, i) => {
              return (
                <ListItem
                  disablePadding
                  key={imageData.fileName + i}
                  sx={{
                    display: 'inline-block',
                    width: '80px',
                    height: '80px',
                    marginRight: '8px',
                  }}
                >
                  <ImageViewer
                    index={i}
                    imageSrc={images.map((image) => (image.contentUrl ? image.contentUrl : ''))}
                    avatarWidth={80}
                    avatarHeight={80}
                  />
                </ListItem>
              );
            })
            }
          </List>
        </Grid>
        <Grid item xs={3} lg={1}>
          <Typography
            variant={'body2'}
            fontWeight={'bold'}
            fontSize={18}
            color={theme.colors.avani.teal}
            sx={{
              pb: '12px'
            }}
          >
            Explanation
          </Typography>
          <Typography
            variant={'body2'}
            fontSize={16}
          >
            {explanationText}
          </Typography>
        </Grid>
        <Grid item xs={3} lg={1}>
          <Typography
            variant={'body2'}
            fontWeight={'bold'}
            fontSize={18}
            color={theme.colors.avani.teal}
            sx={{
              pb: '12px'
            }}
          >
            Description
          </Typography>
          <Typography
            variant={'body2'}
            fontSize={16}
          >
            {descriptionText}
          </Typography>
        </Grid>
      </Grid>
    </AvaniCard>
  );
};