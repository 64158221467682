import * as React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import {
  ExpandMore,
  Person,
  CalendarToday,
} from '@mui/icons-material';

import { AvaniScrollable } from 'src/components/Utils/AvaniScrollable';
import { AvaniGlassWashers } from 'src/components/DataDisplay/AvaniGlassWashers';
import { ImageViewer } from 'src/components/Dialogs/ImageViewer/ImageViewer';

import type {
  Glasswasher,
  LastUpdated,
} from 'src/types/Dashboard';

import { GlassWasherData } from 'src/models/types/DashboardBlock/glass-washers.types';

type GlassWashersBlockProps = {
  images?: string[],
  comment?: {
    author?: string,
    text?: string,
    date?: string,
  },
  lastUpdated?: LastUpdated,
  glassWashers?: GlassWasherData[],
};

export const GlassWashersBlock:React.FC<GlassWashersBlockProps> = ({
  images,
  comment,
  lastUpdated,
  glassWashers,
}) => {
  const theme = useTheme();
  const frame = React.useRef<HTMLElement | null>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [availableWidth, setAvailableWidth ] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (frame && frame.current) {
      setAvailableWidth(frame.current.clientWidth);
    }
  }, [frame]);

  return (
    <div style={{marginTop: glassWashers ? '36px': 0}}>
      {glassWashers &&
      <Accordion
        defaultExpanded
        sx={{
          background: 'transparent'
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore fontSize={'large'} />}
          sx={{
            padding: 0,
            borderRadius: 0,
          }}
        >
          <Typography variant={'h2'} sx={{
            color: theme.colors.avani.teal,
          }}>
            Glass-washers
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: 0,
          }}
        >
          <Grid container spacing={1} columns={5}>
            {/* <Grid item
              xs={5}
              lg={1}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.colors.avani.aGrey,
                borderRadius: '16px',
                mt: 1
              }}
            >
              {images &&
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 1,
                  mt: '24px'
                }}
              >
                {images.map((imageUrl, ind)=>{
                  return (
                    <ImageViewer
                      key={ind + imageUrl}
                      imageSrc={imageUrl}
                      avatarWidth={40}
                      avatarHeight={40}
                    />
                  );
                })
                }
              </Box>
              }
              {comment &&
              <Box
                sx={{
                  flexGrow: 1,
                  textAlign: 'center',
                  borderRadius: '16px',
                  padding: '16px',
                  marginTop: '27px',
                }}
              >
                {comment.author &&
                <Typography variant='body2' lineHeight={1} fontWeight={'bold'} sx={{display: 'inline-block', marginRight: 1, marginBottom: 1}}>
                  <Person color={'aTurc'} style={{float: 'left', marginTop: -5}} fontSize={'small'} /> {comment.author}
                </Typography>
                }
                {comment.date &&
                <Typography variant='body2' fontWeight={'bold'} sx={{display: 'inline-block'}}>
                  <CalendarToday color={'aTurc'} fontSize={'small'} style={{float: 'left', marginTop: -3, marginRight: 3}} /> {comment.date}
                </Typography>
                }
                {comment.text &&
                <Typography variant='body2' sx={{
                  textAlign: 'left',
                  textIndent: '27px'
                }}>
                  {comment.text}
                </Typography>
                }
              </Box>
              }
            </Grid> */}
            <Grid item
              xs={5}
            >
              <Box ref={frame}>
              {!!availableWidth &&
                <AvaniScrollable
                  width={availableWidth}
                  height={isMobile ? 490: 506}
                  key={'glasswashers'}
                  horizontal
                >
                  <AvaniGlassWashers
                    data={glassWashers}
                    gwWidth={isMobile ? availableWidth : 390}
                  />
                </AvaniScrollable>
                }
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      }
    </div>
  )
};
