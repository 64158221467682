import { API } from 'aws-amplify';
import { ResponseListClientsParams } from '../types/dashboard.types';
import { ResponseVisitDashboardParams } from '../types/visit-dashboard.types';
import { FaultsDashboardResponseParams } from '../types/faults-dashboard.types';

const apiName = 'avaniApiCommon';
const path = '/dashboards';

export const getClientDashboardData = async (clientId: string): Promise<ResponseListClientsParams> => {
  const myInit = {
    headers: {}
  };

  return API.get(apiName, `${path}/client/${clientId}`, myInit);
};

export const getProCleanVisitDashboardData = async (
  visitId: string,
  visitType: string
): Promise<ResponseVisitDashboardParams> => {
  const visitTypeStr = visitType === 'ProClean PLUS' ? 'pro-clean-plus' : 'pro-clean';
  const myInit = {
    headers: {}
  };

  return API.get(apiName, `${path}/${visitTypeStr}/${visitId}`, myInit);
};

export const getFaultsDashboardData = async (visitFaultId: string): Promise<FaultsDashboardResponseParams> => {
  const myInit = {
    headers: {}
  };

  return API.get(apiName, `${path}/faults/${visitFaultId}`, myInit);
};
