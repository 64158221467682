import { Loader } from './loader.routes';
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';
import SidebarLayout from 'src/layouts/SidebarLayout';
import ProtectedRoute from './Components/ProtectedRoute';
import { RefMaterialsProvider } from '../../contexts/RefMaterialsContext';

const RefMaterialsList = Loader(lazy(() => import('src/content/pages/RefMaterials/RefMaterialsList')));

export const createRefMaterialsRoutes = () => ({
  path: 'ref-materials',
  element: <SidebarLayout />,
  children: [
    {
      path: '',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <Navigate to="/ref-materials/list" replace />
        </ProtectedRoute>
      )
    },
    {
      path: 'list',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <RefMaterialsProvider>
            <RefMaterialsList />
          </RefMaterialsProvider>
        </ProtectedRoute>
      )
    }
  ]
});
