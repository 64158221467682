import { createContext, FC, useState } from 'react';
import { RoleModel } from '../models/types/roles.types';
import { RoleSortingFields, Order } from '../models/types/common.types';

interface IRolesContext {
  rolesUpdate: boolean;
  toggleRolesUpdate: () => void;
  roles: RoleModel[];
  setRoles: (val: RoleModel[]) => void;
  page: number;
  setPage: (val: number) => void;
  limit: number;
  setLimit: (val: number) => void;
  count: number;
  setCount: (val: number) => void;
  order: Order;
  setOrder: (val: Order) => void;
  orderBy: RoleSortingFields;
  setOrderBy: (val: RoleSortingFields) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
}

export const RolesContext = createContext<IRolesContext>({} as IRolesContext);

export const RolesProvider: FC = ({ children }) => {
  const [rolesUpdate, setRolesUpdate] = useState(false);
  const [roles, setRoles] = useState<RoleModel[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<RoleSortingFields>('displayName');
  const [loading, setLoading] = useState(false);
  const toggleUpdate = (): void => setRolesUpdate(!rolesUpdate);

  return (
    <RolesContext.Provider
      value={{
        rolesUpdate,
        toggleRolesUpdate: toggleUpdate,
        roles,
        setRoles,
        page,
        setPage,
        limit,
        setLimit,
        count,
        setCount,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        loading,
        setLoading
      }}
    >
      {children}
    </RolesContext.Provider>
  );
};
