import { Loader } from './loader.routes';
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';
import SidebarLayout from 'src/layouts/SidebarLayout';
import { BarsProvider } from 'src/contexts/BarsContext';
import ProtectedRoute from './Components/ProtectedRoute';

const BarsList = Loader(lazy(() => import('src/content/pages/Bars/BarsList')));

const BarsDetail = Loader(lazy(() => import('src/content/pages/Bars/BarsDetail')));

const CreateBar = Loader(lazy(() => import('src/content/pages/Bars/BarCreate')));

const BarUpdate = Loader(lazy(() => import('src/content/pages/Bars/BarUpdate')));

export const createBarsRoutes = () => ({
  path: 'bars',
  element: <SidebarLayout />,
  children: [
    {
      path: '',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <Navigate to="/bars/list" replace />
        </ProtectedRoute>
      )
    },
    {
      path: 'list',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <BarsProvider>
            <BarsList />
          </BarsProvider>
        </ProtectedRoute>
      )
    },
    {
      path: ':barId/',
      element: (
        <ProtectedRoute groupRequired={['avani', 'clients']} roleRequired={['*']}>
          <BarsProvider>
            <BarsDetail />
          </BarsProvider>
        </ProtectedRoute>
      )
    },
    {
      path: `:barId/update`,
      element: (
        <ProtectedRoute
          groupRequired={['avani']}
          roleRequired={['systemSuperAdmin', 'avaniAdmin', 'avaniRegionalOpsManager']}
        >
          <BarsProvider>
            <BarUpdate />
          </BarsProvider>
        </ProtectedRoute>
      )
    },
    {
      path: 'create',
      element: (
        <ProtectedRoute
          groupRequired={['avani']}
          roleRequired={['systemSuperAdmin', 'avaniAdmin', 'avaniRegionalOpsManager']}
        >
          <BarsProvider>
            <CreateBar />
          </BarsProvider>
        </ProtectedRoute>
      )
    }
  ]
});
