import * as React from 'react';

import { styled } from '@mui/material/styles';
import { Card, Typography, useTheme } from '@mui/material';
import { SportsBar } from '@mui/icons-material';

import { AvaniNumberSpin } from 'src/components/DataDisplay/AvaniNumberSpin';

interface AvaniSavedPintsCardProps {
  title: string, 
  savedPintsValue: number,
  text?: string,
  small?: boolean,
  sx?: any,
}

const AvaniSavedPintsStyledCard = styled(Card)(
  () => `
  border-radius: 16px;
  padding: 16px;

  .MuiTypography-root.MuiTypography-h3 {
    margin: 0;
    line-height: 1;
    padding-bottom: 12px;
    display: block;
    width: 100%;
    float: left;
  }

  .MuiTypography-root.MuiTypography-h3.noText {
    padding-bottom: 0;
  }

  &.AvaniBeerCard {
    .AvaniCardContent .MuiTypography-root.MuiTypography-h3 {
      float: left;
      width: 100%;
      margin-top: 28px;
      padding-bottom: 16px;
    }
  }

  .animated-container {
    margin-top: -20px;
  }
`);

export const AvaniSavedPintsCard: React.FC<AvaniSavedPintsCardProps> = ({
  title,
  savedPintsValue,
  text,
  small,
  sx,
}) => {
  const theme = useTheme();

  return (
    <AvaniSavedPintsStyledCard
      raised={true}
      sx={{
        height: '100%',
        backgroundColor: theme.colors.avani.lightTurc,
        ['.SavedBeerSpinner']: {
          textAlign: 'right',
          marginTop: 0,
        },
        ...sx,
      }}
    >
     
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <AvaniNumberSpin
          numberValue={savedPintsValue}
          fSize={small ? 40 : 70}
          sx={{
            marginTop: '12px',
            marginRight: '12px',
          }}
        />
        <svg width="28" height="37" viewBox="0 0 28 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.33315 0C1.68414 0 1.0643 0.267383 0.62677 0.737109C0.189236 1.20684 -0.044115 1.83555 0.00693063 2.47871L2.10709 31.6307C2.32586 34.6586 4.86356 37 7.92629 37H20.0751C23.1379 37 25.6756 34.6586 25.8943 31.6307L27.9945 2.47871C28.0383 1.83555 27.8195 1.20684 27.3747 0.737109C26.9298 0.267383 26.3173 0 25.6683 0H2.33315ZM5.16983 9.25L4.84168 4.625H23.1598L22.8243 9.25H5.16983Z" fill="#005665"/>
        </svg>
      </div>
      <Typography
        variant="h3"
        className={!text ? 'noText': ''}
        color={theme.colors.avani.teal}
      >
        {title}
      </Typography>
      {text &&
      <Typography
        variant='body2'
        fontSize={14}
        lineHeight={1}
      >
        {text}
      </Typography>
      }
    </AvaniSavedPintsStyledCard>
  );
};
