import { useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import { Auth, Storage } from 'aws-amplify';
import { useAuthContext } from 'src/contexts/AuthContext';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import { haveGroupAccessToComponent } from 'src/components/Functions/haveGroupAccessToComponent';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  const nav = useNavigate();
  const { auth, awsAuth, setAuth, setAwsAuth, profilePicture, setProfilePicture } = useAuthContext();

  useEffect(() => {
    (async () => {
      if (auth && auth.avatar !== null && profilePicture === null) {
        const customPrefix = { public: 'public/media/' };
        const imageLink = await Storage.get(`avatars/${auth.id}/${auth.avatar.id}.${auth.avatar.extension}`, {
          contentType: `image/${auth.avatar.fileType}`,
          customPrefix
        });
        setProfilePicture({
          fileType: auth.avatar.fileType,
          fileName: auth.avatar.fileName,
          extension: auth.avatar.extension,
          id: auth.avatar.id,
          contentUrl: imageLink,
          user: auth
        });
      }
    })();
  }, []);

  const handleSignOut = (): void => {
    Auth.signOut()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setAwsAuth(undefined);
        setAuth(undefined);
        nav('/');
      });
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar
          variant="rounded"
          alt={`${awsAuth?.['custom:firstName']} ${awsAuth?.['custom:lastName']}`}
          src={profilePicture !== null ? profilePicture.contentUrl : ''}
        />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {awsAuth ? `${awsAuth?.['custom:firstName']} ${awsAuth?.['custom:lastName']}` : ''}
            </UserBoxLabel>
            <UserBoxDescription variant="body2">{`${awsAuth?.email}`}</UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar
            variant="rounded"
            alt={`${awsAuth?.['custom:firstName']} ${awsAuth?.['custom:lastName']}`}
            src={profilePicture !== null ? profilePicture.contentUrl : ''}
          />
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {awsAuth ? `${awsAuth?.['custom:firstName']} ${awsAuth?.['custom:lastName']}` : ''}
            </UserBoxLabel>
            <UserBoxDescription variant="body2">{`${awsAuth?.email}`}</UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 0 }} component="nav">
          <ListItem button to={`/users/${auth?.id}`} component={NavLink} onClick={() => setOpen(!isOpen)}>
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary="My profile" />
          </ListItem>
        </List>
        {auth && auth.significantGroup && haveGroupAccessToComponent(['avani'], auth.significantGroup) && (
        <List sx={{ p: 0 }} component="nav">
          <ListItem button to={`/users/skin-monitoring`} component={NavLink}>
            <AccessibilityIcon fontSize="small" />
            <ListItemText primary="Skin Monitoring" />
          </ListItem>
        </List>
        )}
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={handleSignOut}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
