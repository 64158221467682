export const GLASSWASHERS_BLOCK = {
  images: [
    'https://www.chowhound.com/img/gallery/the-role-of-your-beer-glass-is-more-important-than-you-think/intro-1697137116.webp',
    'https://learnkegs.wpenginepowered.com/wp-content/uploads/2016/01/different-beer-glasses.jpg',
    'https://www.bottleneckmgmt.com/wp-content/uploads/2016/02/900x600-Types-of-Beer-Glasses.png',
  ],
  comment: {
    author: 'Tom Griffits',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris.',
  },
  glasswashers: [
    {
      name: 'Lobby Bar 1',
      hygiene: true,
      before: 'https://www.bottleneckmgmt.com/wp-content/uploads/2016/02/900x600-Types-of-Beer-Glasses.png',
      after: 'https://www.bottleneckmgmt.com/wp-content/uploads/2016/02/900x600-Types-of-Beer-Glasses.png',
      trend: [
        {
          date: '2024-02-12',
          isOor: false
        },
        {
          date: '2023-12-10',
          isOor: false
        },
        {
          date: '2023-11-16',
          isOor: true
        },
        {
          date: '2023-10-12',
          isOor: false
        },
      ],
      chem: {
        detergent: {
          found: 12.4,
          left: 10.5,
        },
        rinse: {
          found: 13.8,
          left: 27.9,
        },
        consumption: 8
      }
    },
    {
      name: 'Roof Bar',
      hygiene: false,
      before: '',
      after: '',
      trend: [
        {
          date: '2024-02-12',
          isOor: false
        },
        {
          date: '2023-12-10',
          isOor: false
        },
        {
          date: '2023-11-16',
          isOor: true
        },
        {
          date: '2023-10-12',
          isOor: false
        },
      ],
      chem: {
        detergent: {
          found: 11.8,
          left: 10.3,
        },
        rinse: {
          found: 14.6,
          left: 28.5,
        },
        consumption: 3.2
      }
    },
    {
      name: 'Garden Bar',
      hygiene: true,
      before: '',
      after: '',
      trend: [
        {
          date: '2024-02-12',
          isOor: false
        },
        {
          date: '2023-12-10',
          isOor: false
        },
        {
          date: '2023-11-16',
          isOor: true
        },
        {
          date: '2023-10-12',
          isOor: false
        },
      ],
      chem: {
        detergent: {
          found: 5.4,
          left: 3.5,
        },
        rinse: {
          found: 6.8,
          left: 3.9,
        },
        consumption: 4.8
      }
    },
  ],
};
