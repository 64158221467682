import * as React from 'react';
import { useTheme } from '@mui/material';
import { HighlightItemData, HighlightScope } from '@mui/x-charts/context';
import { BarChart } from '@mui/x-charts';

import { DateTime } from 'luxon';

interface AvaniBarChartProps {
  data: any[],
  dates: any[],
  timeData?: boolean,
}

export const AvaniBarChart: React.FC<AvaniBarChartProps> = ({
  data,
  dates,
  timeData,
}) => {
  const theme = useTheme();

  const chartColors = [
    theme.palette.aDarkPink.main,
    theme.palette.aPurple.main,
    theme.palette.aGreen.main,
    theme.palette.aYellow.main,
  ];

  const formattedDates = (dateStrings: string[]): string[] => {
    const output = dateStrings.map((dateString, i) => {
      if (i === 0) {
        return 'Current Visit';
      } else {
        const dateObj = DateTime.fromISO(dateString);
        return [dateObj.day, dateObj.month ].join('/');
      }
    });

    return output;
  }

  const [highlightedItem, setHighLightedItem] =
    React.useState<HighlightItemData | null>({
      seriesId: 'a',
      dataIndex: 0,
    });

  return (
    <BarChart
      xAxis={[{
        scaleType: 'band',
        data: timeData ? formattedDates(dates) : dates,
      }]}
      highlightedItem={highlightedItem}
      colors={chartColors}
      series={data.map((series) => ({
        ...series,
        highlightScope: {
          highlight: 'item',
          faded: 'global',
        } as HighlightScope,
      }))}
      height={400}
      grid={{horizontal: true}}
      onHighlightChange={setHighLightedItem}
      sx={{
        ['.MuiBarElement-series-a,.MuiBarElement-series-b,.MuiBarElement-series-c,.MuiBarElement-series-d']: {
          opacity: 1
        },
        ['.MuiBarElement-series-a ~ .MuiBarElement-series-a , .MuiBarElement-series-b ~ .MuiBarElement-series-b, .MuiBarElement-series-c ~ .MuiBarElement-series-c, .MuiBarElement-series-d ~ .MuiBarElement-series-d' ]: {
          opacity: .6
        },
        ['.MuiBarElement-series-a ~ .MuiBarElement-series-a:hover, .MuiBarElement-series-b ~ .MuiBarElement-series-b:hover, .MuiBarElement-series-c ~ .MuiBarElement-series-c:hover, .MuiBarElement-series-d ~ .MuiBarElement-series-d:hover' ]: {
          opacity: 1
        }
      }}
    />
  );
}
