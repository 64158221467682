import { API } from 'aws-amplify';
import PaginationParams from './params';
import {
  ChangeUserPasswordParamsWithId,
  DeleteUserParams,
  ListAutoCompleteUsersResponse,
  ListUsersResponse,
  UpdateUserParamsWithId,
  UserFiltersProps,
  UserModel,
  UserParams
} from '../types/users.types';
import { AxiosResponse } from 'axios';

const apiName = 'avaniApiCommon';
const path = '/users';

interface MyObject {
  [key: string]: any;
}

export const listUsersAutoComplete = async (
  search: string,
  filters?: Record<string, string | number>
): Promise<ListAutoCompleteUsersResponse> => {
  const myInit = {
    headers: {},
    queryStringParameters: { search, ...filters }
  };
  return API.get(apiName, `${path}/autocomplete`, myInit);
};

export const listUsers = async (
  filters: UserFiltersProps,
  params: PaginationParams = new PaginationParams()
): Promise<ListUsersResponse> => {
  const validFilters = Object.entries(filters).reduce<MyObject>((acc, [key, value]) => {
    if (typeof value === 'string' && value.trim() !== '') {
      acc[key] = value;
    } else if (Array.isArray(value) && value.length > 0) {
      acc[key] = value.join(',');
    }
    return acc;
  }, {});
  const myInit = {
    headers: {},
    queryStringParameters: { ...params, ...validFilters }
  };

  return API.get(apiName, path, myInit);
};

export const getUserInfo = async (userId: string): Promise<UserModel> => {
  const myInit = {
    headers: {}
  };

  return API.get(apiName, `${path}/${userId}`, myInit);
};

export const createUser = async (body: UserParams): Promise<AxiosResponse<UserModel>> => {
  const myInit = {
    response: true,
    headers: {},
    body
  };

  return API.post(apiName, path, myInit);
};

export const changeUserPassword = async (params: ChangeUserPasswordParamsWithId): Promise<{ message: string }> => {
  const { userId, userPassword } = params;
  const myInit = {
    headers: {},
    body: {
      userPassword
    }
  };

  return API.put(apiName, `${path}/${userId}/change-password`, myInit);
};

export const updateUser = async (params: UpdateUserParamsWithId): Promise<UserModel> => {
  const { userId } = params;
  const myInit = {
    headers: {},
    body: params
  };

  return API.put(apiName, `${path}/${userId}/`, myInit);
};

export const deleteUser = async (params: DeleteUserParams): Promise<UserModel> => {
  const myInit = {
    headers: {}
  };

  return API.del(apiName, `${path}/${params.userId}`, myInit);
};
