import { Loader } from './loader.routes';
import { Navigate, RouteObject } from 'react-router-dom';
import { lazy } from 'react';
import SidebarLayout from 'src/layouts/SidebarLayout';
import { UsersProvider } from 'src/contexts/UsersContext';
import ProtectedRoute from './Components/ProtectedRoute';
import { RolesProvider } from 'src/contexts/RolesContext';
import { PermissionsProvider } from 'src/contexts/PermissionsContext';
import RoleGuard from './Components/RoleGuard';

const UsersList = Loader(lazy(() => import('src/content/pages/Users/UsersList')));

const RolesList = Loader(lazy(() => import('src/content/pages/Roles/RolesList')));

const PermissionsList = Loader(lazy(() => import('src/content/pages/Permissions/PermissionList')));

const UserDetails = Loader(lazy(() => import('src/content/pages/Users/UserDetails')));

const UserUpdate = Loader(lazy(() => import('src/content/pages/Users/UserUpdate')));

const CreateUser = Loader(lazy(() => import('src/content/pages/Users/UserCreate')));

const CreateSkinMonitoring = Loader(lazy(() => import('src/content/pages/Users/SkinMonitoringCreate')));

export type CustomRouteObject = RouteObject & {
  children?: CustomRouteObject[];
};

export const createUsersRoutes = (): CustomRouteObject => ({
  path: 'users',
  element: <SidebarLayout />,
  children: [
    {
      path: '',
      element: (
        <ProtectedRoute groupRequired={['clients', 'avani']} roleRequired={['*']}>
          <Navigate to="/users/list" replace />
        </ProtectedRoute>
      )
    },
    {
      path: 'list',
      element: (
        <ProtectedRoute groupRequired={['clients', 'avani']} roleRequired={['*']}>
          <UsersProvider>
            <UsersList />
          </UsersProvider>
        </ProtectedRoute>
      )
    },
    {
      path: 'roles',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <RolesProvider>
            <RolesList />
          </RolesProvider>
        </ProtectedRoute>
      )
    },
    {
      path: 'permissions',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <PermissionsProvider>
            <PermissionsList />
          </PermissionsProvider>
        </ProtectedRoute>
      )
    },
    {
      path: `:userId/`,
      element: (
        <ProtectedRoute groupRequired={['avani', 'clients']} roleRequired={['*']}>
          <UsersProvider>
            <UserDetails />
          </UsersProvider>
        </ProtectedRoute>
      )
    },
    {
      path: `:userId/update`,
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <RoleGuard allowedRoles={['systemSuperAdmin', 'avaniAdmin']} userId={':userId'} should404>
            <UsersProvider>
              <UserUpdate />
            </UsersProvider>
          </RoleGuard>
        </ProtectedRoute>
      )
    },
    {
      path: 'create',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <RoleGuard allowedRoles={['systemSuperAdmin', 'avaniAdmin']} userId={':userId'} should404>
            <UsersProvider>
              <CreateUser />
            </UsersProvider>
          </RoleGuard>
        </ProtectedRoute>
      )
    },
    {
      path: 'skin-monitoring',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <CreateSkinMonitoring />
        </ProtectedRoute>
      )
    }
  ]
});
