import * as React from 'react';
import Lightbox from 'yet-another-react-lightbox';
import Video from 'yet-another-react-lightbox/plugins/video';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Download from 'yet-another-react-lightbox/plugins/download';
import 'yet-another-react-lightbox/styles.css';

import {
  Avatar,
  useTheme,
} from '@mui/material';

type ImageViewerProps = {
  imageSrc: string[],
  index: number,
  avatarWidth?: number,
  avatarHeight?: number,
  avatarSx?: any,
  isVideo?: boolean,
};

export const ImageViewer: React.FC<ImageViewerProps> = ({
  imageSrc,
  index,
  avatarWidth = 100,
  avatarHeight = 100,
  avatarSx,
  isVideo = false,
}) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <span>
      <Avatar
        src={imageSrc[index]}
        sx={{
          cursor: 'pointer',
          width: avatarWidth + 'px',
          height: avatarHeight + 'px',
          ...avatarSx,
        }}
        onClick={handleClickOpen}
      />

      <Lightbox
        open={open}
        index={index}
        plugins={[ Zoom, Download, Video ]}
        close={() => handleClose()}
        zoom={{
          maxZoomPixelRatio: 10,
        }}
        slides={!!isVideo ?
          [ {
            type: "video",
            width: 1280,
            height: 720,
            poster: "/public/poster-image.jpg",
            sources: [
              {
                src: imageSrc[index],
                type: "video/mp4",
              },
            ],
          }] : imageSrc.map((is) => {
            return {
              src: is,
            };
          })
        }
        styles={{
          button: {
            color: theme.colors.avani.teal,
            filter: 'none',
          },
          container: {
            backgroundColor: 'white',
          },
          navigationNext: {
            backgroundColor: 'rgba(255, 255, 255, .6)',
            borderRadius: '80px',
            padding: '9px',
            marginRight: '18px',
          },
          navigationPrev: {
            backgroundColor: 'rgba(255, 255, 255, .6)',
            borderRadius: '80px',
            padding: '9px',
            marginLeft: '18px',
          },
        }}
      />
    </span>
  );
};
