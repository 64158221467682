import { Loader } from './loader.routes';
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';
import SidebarLayout from 'src/layouts/SidebarLayout';
import { VenuesProvider } from 'src/contexts/VenuesContext';
import ProtectedRoute from './Components/ProtectedRoute';

const VenuesList = Loader(lazy(() => import('src/content/pages/Venues/VenuesList')));

const CreateVenue = Loader(lazy(() => import('src/content/pages/Venues/VenueCreate')));

const VenueUpdate = Loader(lazy(() => import('src/content/pages/Venues/VenueUpdate')));

const VenueDetails = Loader(lazy(() => import('src/content/pages/Venues/VenueDetails')));

export const createVenuesRoutes = (groupRequired?: null | string, roleRequired?: null | string[]) => ({
  path: 'venues',
  element: <SidebarLayout />,
  children: [
    {
      path: '',
      element: (
        <ProtectedRoute groupRequired={['avani', 'clients']} roleRequired={['*']}>
          <Navigate to="/venues/list" replace />
        </ProtectedRoute>
      )
    },
    {
      path: `:venueId/`,
      element: (
        <ProtectedRoute
          groupRequired={['avani', 'clients']}
          roleRequired={['systemSuperAdmin', 'avaniAdmin', 'avaniClientManager', 'clientAdmin', 'clientOpsManager']}
        >
          <VenuesProvider>
            <VenueDetails />
          </VenuesProvider>
        </ProtectedRoute>
      )
    },
    {
      path: 'list',
      element: (
        <ProtectedRoute groupRequired={['avani', 'clients']} roleRequired={['*']}>
          <VenuesProvider>
            <VenuesList />
          </VenuesProvider>
        </ProtectedRoute>
      )
    },
    {
      path: `:venueId/update`,
      element: (
        <ProtectedRoute
          groupRequired={['avani', 'clients']}
          roleRequired={['systemSuperAdmin', 'avaniAdmin', 'avaniClientManager', 'clientAdmin', 'clientOpsManager']}
        >
          <VenuesProvider>
            <VenueUpdate />
          </VenuesProvider>
        </ProtectedRoute>
      )
    },
    {
      path: 'create',
      element: (
        <ProtectedRoute
          groupRequired={['avani', 'clients']}
          roleRequired={['systemSuperAdmin', 'avaniAdmin', 'avaniClientManager', 'clientAdmin', 'clientOpsManager']}
        >
          <VenuesProvider>
            <CreateVenue />
          </VenuesProvider>
        </ProtectedRoute>
      )
    }
  ]
});
