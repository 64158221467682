import * as React from 'react';

import { Box } from '@mui/material';

type AvaniScrollableProps = {
  children: React.ReactNode,
  height: number,

  width?: number,
  sx?: any,
  horizontal?: boolean
}

export const AvaniScrollable : React.FC<AvaniScrollableProps> = ({
  children,
  width,
  height,
  sx,
  horizontal,
}) => {
  return (
    <Box
      width={'100%'}
      height={height}
      sx={{
        overflow: 'auto',
        paddingRight: 1,
        paddingBottom: 1,
        ...sx,
      }}
    >
      {horizontal &&
        <Box
          sx={{
            width: width
          }}
        >
          {children}
        </Box>
      }
      {!horizontal && children}
    </Box>
  );
};
