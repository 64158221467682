import * as React from 'react';

import { Box, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { PieChart } from '@mui/x-charts';
import { useDrawingArea } from '@mui/x-charts/hooks';

interface AvaniWastagePieChartProps {
  data: any[],
  total: number,
}

const StyledTitle = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 24,
}));

function PieCenterLabel({ total }: { total: number }) {
  const { width, height, left, top } = useDrawingArea();

  return (
    <g>
      <StyledTitle x={left + width / 2} y={(top + height / 2) + 3}>
        {total}%
      </StyledTitle>
    </g>
  );
};

export const AvaniWastagePieChart: React.FC<AvaniWastagePieChartProps> = ({
  data,
  total
}) => {
  const theme = useTheme();

  const chartColors = [
    theme.palette.aTurc.main,
    theme.palette.aTeal.main,
  ];

  const chartData:{
    value: number,
    label: string,
  }[] = data ?
    data : [
      {
        value: 0,
        label: 'no data',
      }
    ];
  const chartTotal = total ? total : 0;
  const chartLeftOffset = '-47px';

  return (
    <Box
      sx={{
        padding: 0,
        width: '125px',
        overflow: 'hidden',
        margin: '0 auto',
      }}
    >
      <Box
        sx={{
          padding: 0,
          margin: 0,
          overflow: 'hidden',
          marginLeft: chartLeftOffset
        }}
      >
        <PieChart
          series={[{ data: chartData, innerRadius: 40 }]}
          height={120}
          width={310}
          margin={{
            top: 0,
            left: 0,
          }}
          colors={chartColors}
          tooltip={{trigger: 'none'}}
          sx={{
            ['.MuiPieArc-root']: {stroke: 'none'},
          }}
          slotProps={{
            legend: { hidden: true },
          }}
        >
          <PieCenterLabel total={chartTotal}></PieCenterLabel>
        </PieChart>
      </Box>
    </Box>
  );
}
