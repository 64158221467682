import { useContext } from 'react';

import { NavLink as RouterLink } from 'react-router-dom';

import { styled, Box, Button, Card, List, ListItem } from '@mui/material';

import HandymanIcon from '@mui/icons-material/Handyman';
import { SidebarContext } from 'src/contexts/SidebarContext';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import PlumbingIcon from '@mui/icons-material/Plumbing';

const StyledSubMenu = styled(Card)(
  ({ theme }) => `
  position: absolute;
  top: 160px;
  left: 80px;
  width: 250px;
  background-color: ${theme.colors.secondary.light};

  & .MuiButtonBase-root {
    color: ${theme.colors.alpha.white[100]}; 
    width: 100%;
    justify-content: left;

    &:hover {
      color: ${theme.colors.primary.main}
    }
  }
  `
);

function CollapsedBrewersTSPsSubMenu() {
  const { closeSidebar, changeBrewersTSPsSubMenuToggle } = useContext(SidebarContext);

  return (
    <>
      <Box
        onClick={() => changeBrewersTSPsSubMenuToggle()}
        sx={{
          position: 'absolute',
          zIndex: 7,
          width: '100%',
          height: '100%'
        }}
      >
        <StyledSubMenu>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/brewers"
                startIcon={<GasMeterIcon />}
              >
                Brewers
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/technical-support-providers/list"
                startIcon={<HandymanIcon />}
              >
                TSPs
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/ams/list"
                startIcon={<PlumbingIcon />}
              >
                AMS Parts
              </Button>
            </ListItem>
          </List>
        </StyledSubMenu>
      </Box>
    </>
  );
}

export default CollapsedBrewersTSPsSubMenu;
