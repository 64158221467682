import { createContext, FC, useState } from 'react';
import { ProductModel, ProductsFiltersProps } from '../models/types/products.types';
import { Order, ProductSortingFields } from '../models/types/common.types';

interface IProductsContext {
  productsUpdate: boolean;
  toggleProductsUpdate: () => void;
  products: ProductModel[];
  setProducts: (val: ProductModel[]) => void;
  page: number;
  setPage: (val: number) => void;
  limit: number;
  setLimit: (val: number) => void;
  count: number;
  setCount: (val: number) => void;
  order: Order;
  setOrder: (val: Order) => void;
  orderBy: ProductSortingFields;
  setOrderBy: (val: ProductSortingFields) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
  filters: ProductsFiltersProps;
  setFilters: (val: ProductsFiltersProps) => void;
  resetFilters: () => void;
}

export const ProductsContext = createContext<IProductsContext>({} as IProductsContext);

export const ProductsProvider: FC = ({ children }) => {
  const [productsUpdate, setProductsUpdate] = useState(false);
  const [products, setProducts] = useState<ProductModel[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<ProductSortingFields>('name');
  const [loading, setLoading] = useState(false);
  const emptyFilters = {
    brewerName: undefined,
    style: undefined,
    containerType: undefined,
    nozzleType: undefined,
    tapSealType: undefined,
    couplerType: undefined,
    alcoholFree: undefined,
    vegan: undefined,
    glutenFree: undefined,
    status: undefined
  };
  const [filters, setFilters] = useState<ProductsFiltersProps>(emptyFilters);
  const resetFilters = (): void => {
    setFilters(emptyFilters);
    setPage(0);
    setLimit(20);
  }
  const toggleUpdate = (): void => setProductsUpdate(!productsUpdate);

  return (
    <ProductsContext.Provider
      value={{
        filters,
        setFilters,
        resetFilters,
        productsUpdate,
        toggleProductsUpdate: toggleUpdate,
        products,
        setProducts,
        page,
        setPage,
        limit,
        setLimit,
        count,
        setCount,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        loading,
        setLoading
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};
