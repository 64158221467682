import { Loader } from './loader.routes';
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';
import SidebarLayout from 'src/layouts/SidebarLayout';
import { ProductsProvider } from 'src/contexts/ProductsContext';
import ProtectedRoute from './Components/ProtectedRoute';

const ProductsList = Loader(lazy(() => import('src/content/pages/Products/ProductsList')));

const CreateProduct = Loader(lazy(() => import('src/content/pages/Products/ProductCreate')));

const ProductUpdate = Loader(lazy(() => import('src/content/pages/Products/ProductUpdate')));

export const createProductsRoutes = () => ({
  path: 'products',
  element: <SidebarLayout />,
  children: [
    {
      path: '',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <Navigate to="/products/list" replace />
        </ProtectedRoute>
      )
    },
    {
      path: 'list',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <ProductsProvider>
            <ProductsList />
          </ProductsProvider>
        </ProtectedRoute>
      )
    },
    {
      path: `:productId/update`,
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['systemSuperAdmin', 'avaniAdmin']}>
          <ProductsProvider>
            <ProductUpdate />
          </ProductsProvider>
        </ProtectedRoute>
      )
    },
    {
      path: 'create',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['systemSuperAdmin', 'avaniAdmin']}>
          <ProductsProvider>
            <CreateProduct />
          </ProductsProvider>
        </ProtectedRoute>
      )
    }
  ]
});
