import * as React from 'react';

import Lightbox from 'yet-another-react-lightbox';
import Video from 'yet-another-react-lightbox/plugins/video';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Download from 'yet-another-react-lightbox/plugins/download';
import 'yet-another-react-lightbox/styles.css';

import {
  Avatar,
  Box,
  Typography,
  useTheme,
} from '@mui/material';

import { Check } from '@mui/icons-material';

import { AvaniCard } from 'src/components/Cards/AvaniCard';
import { ImageViewer } from 'src/components/Dialogs/ImageViewer/ImageViewer';
import { MediaModel } from 'src/models/types/medias.types';

type AvaniPerfectPintCardProps = {
  images: MediaModel[],
  safetyCheck?: boolean,
}

const calculateAvatarDimm = (numberOfItems: number) => {
  switch (numberOfItems) {
    case 1:
      return 148;
    case 2:
      return 100;
    case 3:
      return 75;
    case 4:
      return 75;
    default:
      return 100;
  }
};

export const AvaniPerfectPintCard:React.FC<AvaniPerfectPintCardProps> = ({
  images,
  safetyCheck,
}) => {
  const theme = useTheme();
  const [ toggle, setToggle ] = React.useState(false);

  const avatarDimm = (images.length > 1 || safetyCheck) ? calculateAvatarDimm(images.length) : 0;

  const handleClose = () => {
    setToggle(false);
  }

  return (
    <>
    <AvaniCard
      title={safetyCheck ? 'Safety Check' : 'Perfect Pint'}
      sx={{
        backgroundImage: (images.length === 1 && !safetyCheck) ?
          `url(${images[0].contentUrl})` : 'none',
        backgroundSize: '100%',
        backgroundPosition: 'center',
        height: '100%',
        minHeight: '230px',
        cursor: (images.length === 1 && !safetyCheck) ? 'pointer': 'default',

        [ `.MuiTypography-root.MuiTypography-h3` ]: {
          color: (images.length === 1 && !safetyCheck) ? 'white' : theme.colors.avani.teal,
        }
      }}
      onClick={() => !safetyCheck ? setToggle(true) : undefined}
    >
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          alignItems: 'center',
          height: 'calc(100% - 50px)',
        }}
      >
      {(images.length > 1 || safetyCheck) && images.map((mediaObject, ind) => {
        return (
          <ImageViewer
            key={mediaObject.fileName + ind}
            index={ind}
            imageSrc={images.map((media) => (!!media.contentUrl ? media.contentUrl : ''))}
            avatarWidth={avatarDimm}
            avatarHeight={avatarDimm}
          />
        );
      })
      }
      {(!images.length && !safetyCheck) &&
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Avatar
          sx={{
            width: '100px',
            height: '100px',
            backgroundColor: theme.colors.avani.aGreen,
            marginBottom: '24px',
          }}
        >
          <Check sx={{color: 'white', fontSize: 60,}} />
        </Avatar>
        <Typography
          variant={'h4'}
          color={'aTeal'}
        >
          System beer ready
        </Typography>
      </Box>
      }
      </div>
    </AvaniCard>
    {(images.length === 1 && !safetyCheck) &&
    <Lightbox
      open={toggle}
      plugins={[ Zoom, Download, Video ]}
      close={() => handleClose}
      slides={[
        {
          src: images[0].contentUrl ? images[0].contentUrl : '',
          download: images[0]+'?download'
        },
      ]}
      styles={{
        button: {
          color: theme.colors.avani.teal,
          filter: 'none',
        },
        container: {
          backgroundColor: 'white',
        },
        navigationNext: {
          backgroundColor: 'rgba(255, 255, 255, .6)',
          borderRadius: '80px',
          padding: '9px',
          marginRight: '18px',
          color: 'white!important',
        },
        navigationPrev: {
          backgroundColor: 'rgba(255, 255, 255, .6)',
          borderRadius: '80px',
          padding: '9px',
          marginLeft: '18px',
          color: 'white!important',
        },
      }}
    />
    }
    </>
  );
}
