import * as React from 'react';

import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';

import { AvaniCard } from 'src/components/Cards/AvaniCard';

import type { FaultsData } from 'src/types/Dashboard';
import { FaultTypesData } from 'src/models/types/DashboardBlock/general.types';

type AvaniFaultsCardProps = {
  faults: FaultTypesData,
}

export const AvaniFaultsCard: React.FC<AvaniFaultsCardProps> = ({
  faults,
}) => {
  const theme = useTheme();

  return (
    <AvaniCard title="Faults" padded sx={{height: '100%'}}>
        <div style={{
          display: 'flex',
          marginTop: '24px',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Avatar
              sx={{
                bgcolor: theme.colors.avani.magenta,
                width: 64,
                height: 64,
                paddingTop: '3px',
                fontSize: '24px'
              }}
            >
              {faults.unresolvedFaults}
            </Avatar>
            <Typography
              variant={'body2'}
              component={'span'}
              paddingTop={1}
            >
              Unresolved
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Avatar
              sx={{
                bgcolor: theme.colors.avani.aYellow,
                width: 64,
                height: 64,
                paddingTop: '3px',
                fontSize: '24px',
              }}
            >
              {faults.newFaults}
            </Avatar>
            <Typography
              variant={'body2'}
              component={'span'}
              paddingTop={1}
            >
              New
            </Typography>
          </Box>
        </div>
      </AvaniCard>
  );
};
