import * as React from 'react';

import { Box, List, ListItem, ListItemText, Paper, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';

interface AvaniTempPieChartProps {
  data: {
    value: number,
    label: string,
  }[] | undefined,
  total: number | undefined,
  units: string,
  small?: boolean,
}

const StyledTitle = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 24,
}));

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 18,
  fontWeight: 'light',
  fontStyle: 'normal'
}));

const Item = styled(Paper)(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  color: 'white',
  textAlign: 'center',
  float: 'left',
  paddingTop: '4px',
  lineHeight: 1,
  display: 'inline-block',
  fontWeight: 'normal',
  fontSize: 10,
  marginTop: '2px',
}));

const ItemValue = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  display: 'inline-block',
  marginLeft: 10,
}));

function PieCenterLabel({ total, units }: { total: number, units: string }) {
  const { width, height, left, top } = useDrawingArea();

  return (
    <g>
      <StyledTitle x={left + width / 2} y={(top + height / 2) - 8}>
        {total}
      </StyledTitle>
      <StyledText x={left + width / 2} y={(top + height / 2) + 10}>
        {units}
      </StyledText>
    </g>
  );
};

export const AvaniTempPieChart: React.FC<AvaniTempPieChartProps> = ({
  data,
  total,
  units,
  small,
}) => {
  const theme = useTheme();

  const chartColors = [
    theme.palette.aDarkPink.main,
    theme.palette.aPurple.main,
    theme.palette.aGreen.main,
    theme.palette.aYellow.main
  ];

  const chartData:{
    value: number,
    label: string,
  }[] = data ? data : [{
    value: 0,
    label: 'no data',
  }];
  const chartTotal = total ? total : 0;

  return (
    <Box
      position={'relative'}
      sx={{
        maxWidth: '240px',
        margin: '0 auto',
        overflow: 'hidden'
      }}
    >
      <List style={{position: 'absolute'}}>
        {chartData.map((item, ind) => {
          return (
            <ListItem key={ind} sx={{py: 0}}>
              <ListItemText>
                <Item sx={{
                  backgroundColor: chartColors[ind],
                 }}>
                  {item.label}
                </Item>
                <ItemValue elevation={0}>{item.value}</ItemValue>
              </ListItemText>
            </ListItem>
          )
        })}
      </List>
      <PieChart
        series={[{ data: chartData, innerRadius: 40 }]}
        height={120}
        width={400}
        colors={chartColors}
        tooltip={{trigger: 'none'}}
        sx={{
          ['.MuiPieArc-root']: {stroke: 'none'}
        }}
        slotProps={{
          legend: { hidden: true },
        }}
      >
        <PieCenterLabel total={chartTotal} units={units}></PieCenterLabel>
      </PieChart>
    </Box>
  );
}
