import { API } from 'aws-amplify';
import PaginationParams from './params';
import {
  CreateVisitParams,
  DeleteVisitFaultsParams,
  DeleteVisitParams,
  ListVisitsResponse,
  UpdateVisitBarsParams,
  UpdateVisitCellarsParams,
  UpdateVisitCommentsParams,
  UpdateVisitFaultsParams,
  UpdateVisitParams,
  VisitEnums,
  VisitModel
} from 'src/models/types/visits.types';

const apiName = 'avaniApiCommon';
const path = '/visits';

export const listVisits = async (params: PaginationParams = new PaginationParams()): Promise<ListVisitsResponse> => {
  const myInit = {
    headers: {},
    queryStringParameters: params
  };

  return API.get(apiName, path, myInit);
};

export const createVisit = async (body: CreateVisitParams): Promise<VisitModel> => {
  const myInit = {
    response: true,
    headers: {},
    body
  };

  return API.post(apiName, path, myInit);
};

export const getVisitInfo = async (visitId: string): Promise<VisitModel> => {
  const myInit = {
    headers: {}
  };

  return API.get(apiName, `${path}/${visitId}`, myInit);
};

export const deleteVisit = async (params: DeleteVisitParams): Promise<VisitModel> => {
  const myInit = {
    headers: {}
  };

  return API.del(apiName, `${path}/${params.visitId}`, myInit);
};

export const updateVisit = async (params: UpdateVisitParams): Promise<VisitModel> => {
  const { visitId, ...body } = params;
  const myInit = {
    headers: {},
    body
  };

  return API.put(apiName, `${path}/${visitId}`, myInit);
};

export const updateVisitFaults = async (params: UpdateVisitFaultsParams): Promise<VisitModel> => {
  const { visitId, ...body } = params;
  const myInit = {
    headers: {},
    body
  };

  return API.put(apiName, `${path}/${visitId}/faults`, myInit);
};

export const deleteVisitFaults = async (params: DeleteVisitFaultsParams): Promise<void> => {
  const { visitId, visitFaultId } = params;
  const myInit = {
    headers: {}
  };

  return API.del(apiName, `${path}/${visitId}/faults/${visitFaultId}`, myInit);
};

export const updateVisitComments = async (params: UpdateVisitCommentsParams): Promise<VisitModel> => {
  const { visitId, ...body } = params;
  const myInit = {
    headers: {},
    body
  };

  return API.put(apiName, `${path}/${visitId}/comments`, myInit);
};

export const updateVisitCellars = async (params: UpdateVisitCellarsParams): Promise<VisitModel> => {
  const { visitId, ...body } = params;
  const myInit = {
    headers: {},
    body
  };

  return API.put(apiName, `${path}/${visitId}/cellars`, myInit);
};

export const updateVisitBars = async (params: UpdateVisitBarsParams): Promise<VisitModel> => {
  const { visitId, ...body } = params;
  const myInit = {
    headers: {},
    body
  };

  return API.put(apiName, `${path}/${visitId}/bars`, myInit);
};

export const getVisitEnums = async (): Promise<VisitEnums> => {
  return API.get(apiName, `${path}/enums`, {});
};
