import * as React from 'react';
import * as XLSX from 'xlsx';
import { DateTime } from 'luxon';

import { styled } from '@mui/material/styles';
import { Avatar, Card, Dialog, List, ListItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Download } from '@mui/icons-material';

import { AvaniDatePicker } from 'src/components/Buttons/AvaniDatePicker';
import { getProDashboardTapsReportsData } from 'src/models/api-calls/reports.api';
import SuspenseLoader from '../LoadingIndicators/SuspenseLoader';

interface AvaniExportCardProps {
  visitId: string;
  sx?: any;
  markedDates?: Date[];
}

const AvaniExportStyledCard = styled(Card)(
  ({ theme }) => `
  border-radius: 16px;
  padding: 0;
  width: auto;
  flex: 1;

  .MuiTypography-root.MuiTypography-h3 {
    margin: 0;
    line-height: 1;
    padding-bottom: 2px;
    display: block;
    width: 100%;
    float: left;
  }
`
);

export const AvaniExportCard: React.FC<AvaniExportCardProps> = ({ markedDates, visitId, sx }) => {
  const [inProgress, setInProgress] = React.useState<boolean>(false);
  const [fromDate, setFromDate] = React.useState<DateTime | null>(DateTime.now().setZone('Europe/London'));
  const [toDate, setToDate] = React.useState<DateTime | null>(DateTime.now().setZone('Europe/London'));
  const theme = useTheme();

  const exportData = async (startDate: DateTime | null, endDate: DateTime | null): Promise<void> => {
    setInProgress(true);

    try {
      const today = DateTime.now().setZone('Europe/London');
      const startDateSelected = startDate ? today.toUnixInteger() > startDate.toUnixInteger() : false;

      if (startDateSelected && !!startDate && !!endDate) {
        // Make export request
        const data = await getProDashboardTapsReportsData(
          visitId,
          !!startDate.toISO() ? startDate.toISO() : null,
          !!endDate.toISO() ? endDate.toISO() : null
        );
        const worksheet = XLSX.utils.json_to_sheet(data.response);
        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${startDate.toFormat('dd-MM')}_${endDate.toFormat('dd-MM')}.xlsx`);
      } else {
        // Cancel
        <Dialog open>
          <Typography
            variant={'body1'}
            sx={{
              color: theme.colors.avani.magenta,
              padding: 5
            }}
          >
            Please select start date
          </Typography>
        </Dialog>;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setInProgress(false);
    }
  };

  if (inProgress) return <SuspenseLoader />;

  return (
    <AvaniExportStyledCard
      raised={true}
      sx={{
        ...sx,
        height: '100%',
        minHeight: useMediaQuery(theme.breakpoints.down('sm')) ? '208px' : '140px',
        backgroundColor: theme.colors.avani.lightTurc
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '16px',
          height: 'calc(100% - 32px)'
        }}
      >
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            variant={'h3'}
            color={theme.colors.avani.teal}
            sx={{
              display: 'block'
            }}
          >
            Export
          </Typography>
          <List
            disablePadding
            sx={{
              float: 'left',
              paddingTop: '12px'
            }}
          >
            <ListItem
              disablePadding
              sx={{
                display: 'inline-block',
                width: 'auto',
                marginRight: '20px',
                float: 'left'
              }}
            >
              <Typography
                variant={'body2'}
                sx={{
                  display: 'block',
                  marginRight: 0,
                  paddingTop: 0
                }}
              >
                From
              </Typography>
              <AvaniDatePicker onSelectDate={(date: DateTime | null) => setFromDate(date)} markedDates={markedDates} />
            </ListItem>
            <ListItem
              disablePadding
              sx={{
                display: 'inline-block',
                width: 'auto',
                float: 'left'
              }}
            >
              <Typography
                variant={'body2'}
                sx={{
                  display: 'block',
                  marginRight: 0,
                  marginLeft: 0,
                  mt: useMediaQuery(theme.breakpoints.down('sm')) ? 1 : 0
                }}
              >
                To
              </Typography>
              <AvaniDatePicker onSelectDate={(date: DateTime | null) => setToDate(date)} markedDates={markedDates} />
            </ListItem>
          </List>
        </div>
        <Avatar
          sx={{
            backgroundColor: theme.colors.avani.teal,
            cursor: 'pointer',
            borderRadius: '8px',
            marginTop: '4px',
            marginRight: '4px'
          }}
          onClick={() => exportData(fromDate, toDate)}
        >
          <Download sx={{ fill: 'white' }} />
        </Avatar>
      </div>
    </AvaniExportStyledCard>
  );
};
