import { ReactElement, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import SuspenseLoader from '../../../components/LoadingIndicators/SuspenseLoader';
import { isRoleAllowed } from './ProtectedRoute';

interface RoleGuardProps {
  children: ReactNode;
  allowedRoles: string[];
  userId?: string;
  should404?: boolean;
}

export default function RoleGuard({ children, allowedRoles, userId, should404 }: RoleGuardProps): ReactElement | null {
  const { auth, awsAuth } = useAuthContext();
  if (!awsAuth) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }
  if (!auth) {
    return <SuspenseLoader />;
  }

  if (isRoleAllowed(allowedRoles, auth.roles) || auth.id === userId) {
    return <>{children}</>;
  }
  return should404 ? <Navigate to="/404" replace /> : null;
}
