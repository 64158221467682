import { Loader } from './loader.routes';
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';
import SidebarLayout from 'src/layouts/SidebarLayout';
import { VisitsProvider } from 'src/contexts/VisitsContext';
import ProtectedRoute from './Components/ProtectedRoute';

const VisitList = Loader(lazy(() => import('src/content/pages/Visits/VisitList')));
const VisitDetails = Loader(lazy(() => import('src/content/pages/Visits/VisitDetails')));

export const createVisitsRoutes = () => ({
  path: 'visits',
  element: <SidebarLayout />,
  children: [
    {
      path: '',
      element: (
        <ProtectedRoute
          groupRequired={['avani', 'clients']}
          roleRequired={[
            'systemSuperAdmin',
            'avaniAdmin',
            'avaniClientManager',
            'avaniRegionalOpsManager',
            'avaniSeniorTechnician',
            'avaniTechnician',
            'avaniFinancialManager',
            'clientAdmin'
          ]}
        >
          <Navigate to="/visits/list" replace />
        </ProtectedRoute>
      )
    },
    {
      path: 'list',
      element: (
        <ProtectedRoute
          groupRequired={['avani', 'clients']}
          roleRequired={[
            'systemSuperAdmin',
            'avaniAdmin',
            'avaniClientManager',
            'avaniRegionalOpsManager',
            'avaniSeniorTechnician',
            'avaniTechnician',
            'avaniFinancialManager',
            'clientAdmin'
          ]}
        >
          <VisitsProvider>
            <VisitList />
          </VisitsProvider>
        </ProtectedRoute>
      )
    },
    {
      path: `:visitId/`,
      element: (
        <ProtectedRoute
          groupRequired={['avani', 'clients']}
          roleRequired={[
            'systemSuperAdmin',
            'avaniAdmin',
            'avaniClientManager',
            'avaniRegionalOpsManager',
            'avaniSeniorTechnician',
            'avaniTechnician',
            'avaniFinancialManager',
            'clientAdmin'
          ]}
        >
          <VisitsProvider>
            <VisitDetails />
          </VisitsProvider>
        </ProtectedRoute>
      )
    }
  ]
});
