import * as React from 'react';
import { DateTime } from 'luxon';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';

import {
  CalendarToday,
  Inventory,
  PendingActions,
  Person,
} from '@mui/icons-material';

import { AvaniCard } from 'src/components/Cards/AvaniCard';

import { VisitData } from 'src/models/types/DashboardBlock/general.types';

type AvaniCurrentVisitCardProps = {
  data: VisitData
}

export const AvaniCurrentVisitCard:React.FC<AvaniCurrentVisitCardProps> = ({
  data
}) => {
  const theme = useTheme();
  const dateObj = DateTime.fromISO(data.startedAt);
  const readableDate = [dateObj.day, dateObj.month, dateObj.year].join('/');

  return (
    <AvaniCard
      title="Current Visit"
      icon={<PendingActions fontSize={'large'} color={'aTeal'} />}
    >
      <div style={{
        margin: '12px auto auto auto',
        maxWidth: '200px',
      }}>
        <List disablePadding>
          <ListItem sx={{py: 0}}>
            <ListItemIcon sx={{minWidth: 'auto', marginRight: 1}}>
              <CalendarToday color="aTurc" />
            </ListItemIcon>
            <ListItemText color={theme.colors.avani.darkTitleText}>
              { readableDate }
            </ListItemText>
          </ListItem>

          <ListItem sx={{py: 0}}>
            <ListItemIcon sx={{minWidth: 'auto', marginRight: 1}}>
              <Inventory color="aTurc" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body1" style={{color: theme.colors.avani.turc}}>{ data.serviceName }</Typography>
            </ListItemText>
          </ListItem>

          {data.technicianNames.map((eng, i) => {
            return <ListItem key={i} sx={{py: 0}}>
              <ListItemIcon sx={{minWidth: 'auto', marginRight: 1}}>
                <Person color="aTurc" />
              </ListItemIcon>
              <ListItemText>
                { eng }
              </ListItemText>
            </ListItem>
          })
          }
        </List>
      </div>
    </AvaniCard>
  );
}
