import { createContext, FC, useState } from 'react';
import { ClientStatusType, ClientModel, ClientSortingFields, ClientsFiltersProps } from '../models/types/clients.types';
import { Order } from '../models/types/common.types';

interface IClientsContext {
  clientsUpdate: boolean;
  toggleClientsUpdate: () => void;
  clients: ClientModel[];
  setClients: (val: ClientModel[]) => void;
  page: number;
  setPage: (val: number) => void;
  limit: number;
  setLimit: (val: number) => void;
  count: number;
  setCount: (val: number) => void;
  order: Order;
  setOrder: (val: Order) => void;
  orderBy: ClientSortingFields;
  setOrderBy: (val: ClientSortingFields) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
  filters: ClientsFiltersProps;
  setFilters: (val: ClientsFiltersProps) => void;
  resetFilters: () => void;
}

export const ClientsContext = createContext<IClientsContext>({} as IClientsContext);

export const ClientsProvider: FC = ({ children }) => {
  const [clientsUpdate, setClientsUpdate] = useState(false);
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<ClientSortingFields>('name');
  const [loading, setLoading] = useState(false);
  const emptyFilters = {
    leadBrewerId: undefined,
    status: undefined,
    segment: undefined
  };
  const [filters, setFilters] = useState<ClientsFiltersProps>(emptyFilters);
  const resetFilters = (): void => {
    setFilters(emptyFilters);
    setPage(0);
    setLimit(20);
  }
  const toggleUpdate = (): void => setClientsUpdate(!clientsUpdate);

  return (
    <ClientsContext.Provider
      value={{
        filters,
        setFilters,
        resetFilters,
        clientsUpdate,
        toggleClientsUpdate: toggleUpdate,
        clients,
        setClients,
        page,
        setPage,
        limit,
        setLimit,
        count,
        setCount,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        loading,
        setLoading
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
};
