import { createContext, FC, useState } from 'react';
import { PermissionModel } from '../models/types/permissions.types';
import { PermissionSortingFields, Order } from '../models/types/common.types';

interface IPermissionsContext {
  permissionsUpdate: boolean;
  togglePermissionsUpdate: () => void;
  permissions: PermissionModel[];
  setPermissions: (val: PermissionModel[]) => void;
  page: number;
  setPage: (val: number) => void;
  limit: number;
  setLimit: (val: number) => void;
  count: number;
  setCount: (val: number) => void;
  order: Order;
  setOrder: (val: Order) => void;
  orderBy: PermissionSortingFields;
  setOrderBy: (val: PermissionSortingFields) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
}

export const PermissionsContext = createContext<IPermissionsContext>({} as IPermissionsContext);

export const PermissionsProvider: FC = ({ children }) => {
  const [permissionsUpdate, setPermissionsUpdate] = useState(false);
  const [permissions, setPermissions] = useState<PermissionModel[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<PermissionSortingFields>('displayName');
  const [loading, setLoading] = useState(false);
  const toggleUpdate = (): void => setPermissionsUpdate(!permissionsUpdate);

  return (
    <PermissionsContext.Provider
      value={{
        permissionsUpdate,
        togglePermissionsUpdate: toggleUpdate,
        permissions,
        setPermissions,
        page,
        setPage,
        limit,
        setLimit,
        count,
        setCount,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        loading,
        setLoading
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};
