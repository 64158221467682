import * as React from 'react';

import { styled } from '@mui/material/styles';
import {
  Card,
  Typography,
  useTheme
} from '@mui/material';

interface AvaniCardProps { 
  children: React.ReactNode,

  title?: string,
  icon?: React.ReactNode,
  temp?: boolean,
  grey?: boolean,
  short?: boolean,
  green?: boolean,
  isHighTemp?: boolean,
  small?: boolean,
  padded?: boolean,
  sx?: any,
  onClick?: () => void,
}

const AvaniStyledCard = styled(Card)(
  ({ theme }) => `
  border-radius: 16px;
  padding: 16px 0;

  &.AvaniCardSmall {
    padding: 16px 0 8px 0;
  }

  &.AvaniCardPadded {
    padding: 16px;
  }

  .MuiTypography-root.MuiTypography-h3 {
    margin: 0 0 0 16px;
    line-height: 1;
    padding-bottom: 24px;
  }

  &.AvaniCardShort .MuiTypography-root.MuiTypography-h3 {
    padding-bottom: 0;
  }

  &.AvaniCardPadded .MuiTypography-root.MuiTypography-h3 {
    margin: 0;
    padding-bottom: 12px;
  }

  .MuiTypography-root.MuiTypography-h3.AvaniCardTitleTemp {
    padding-top: 90px;
    flex-grow: 1;
  }

  .MuiTypography-root.MuiTypography-h3.AvaniSmallCardTitle {
    padding-bottom: 20px;
  }

  .MuiTypography-root.MuiTypography-h3.AvaniDarkCardTitle {
    color: ${theme.colors.avani.teal};
    padding-bottom: 0px;
  }
`);

export const AvaniCard: React.FC<AvaniCardProps> = ({
  children,
  title,
  icon,
  temp,
  isHighTemp,
  small,
  padded,
  grey,
  green,
  short,
  sx,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <AvaniStyledCard
      raised={true}
      sx={{
        height: '100%',
        backgroundColor: isHighTemp ?
          theme.colors.avani.lightMagenta : grey ?
            theme.colors.avani.aGrey : green ? theme.colors.avani.turc : theme.colors.avani.lightTurc,
        ['.MuiPieArc-root']: {stroke: 'none'},
        ...sx,
      }}
      onClick={onClick ? () => onClick() : undefined}
      className={small ? 'AvaniCardSmall' : padded ? 'AvaniCardPadded' : short ? 'AvaniCardShort' : ''}
    >
      <div style={{
        display: title ? 'flex': 'none',
      }}>
        {title &&
          <Typography
            variant="h3"
            color={green?
              'white' : theme.colors.avani.teal
            }
            className={temp ?
              'AvaniCardTitleTemp' : small?
                'AvaniSmallCardTitle' : grey?
                  'AvaniDarkCardTitle' : ''
            }
            sx={{
              flexGrow: 1,
              width: 'calc(100% - 56px)',
            }}
          >
            {title}
          </Typography>
        }
        {icon &&
          <span style={{
            paddingRight: padded ? 0 : '16px',
          }}>{icon}</span>
        }
      </div>
      {children}
    </AvaniStyledCard>
  );
};
