import { useContext } from 'react';

import { NavLink as RouterLink } from 'react-router-dom';

import { Box, Button, Card, List, ListItem, styled } from '@mui/material';

import PeopleIcon from '@mui/icons-material/People';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import SecurityIcon from '@mui/icons-material/Security';

import { SidebarContext } from 'src/contexts/SidebarContext';
import { haveGroupAccessToComponent } from '../../../../components/Functions/haveGroupAccessToComponent';
import { useAuthContext } from '../../../../contexts/AuthContext';

const StyledSubMenu = styled(Card)(
  ({ theme }) => `
  position: absolute;
  top: 160px;
  left: 80px;
  width: 250px;
  background-color: ${theme.colors.secondary.light};

  & .MuiButtonBase-root {
    color: ${theme.colors.alpha.white[100]}; 
    width: 100%;
    justify-content: left;

    &:hover {
      color: ${theme.colors.primary.main}
    }
  }
  `
);

function CollapsedUsersSubMenu() {
  const { closeSidebar, changeUsersSubMenuToggle } = useContext(SidebarContext);
  const { auth } = useAuthContext();

  return (
    <>
      <Box
        onClick={() => changeUsersSubMenuToggle()}
        sx={{
          position: 'absolute',
          zIndex: 7,
          width: '100%',
          height: '100%'
        }}
      >
        <StyledSubMenu>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/users/list"
                startIcon={<PeopleIcon />}
              >
                List
              </Button>
            </ListItem>
            {auth && auth.significantGroup && haveGroupAccessToComponent(['avani'], auth.significantGroup) && (
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/users/roles"
                  startIcon={<SecurityIcon />}
                >
                  Roles
                </Button>
              </ListItem>
            )}
            {auth && auth.significantGroup && haveGroupAccessToComponent(['avani'], auth.significantGroup) && (
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/users/permissions"
                  startIcon={<VerifiedUserIcon />}
                >
                  Permissions
                </Button>
              </ListItem>
            )}
          </List>
        </StyledSubMenu>
      </Box>
    </>
  );
}

export default CollapsedUsersSubMenu;
